@font-face {
    font-family: Vazirmatn;
    src: url('../../../public/fonts/Vazirmatn-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: 100;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-Thin.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-Thin.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: 200;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-UltraLight.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-UltraLight.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: 300;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-Light.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-Light.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: 500;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-Medium.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-Medium.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: 600;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-DemiBold.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-DemiBold.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: 800;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-ExtraBold.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-ExtraBold.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: 900;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-Black.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-Black.woff2') format('woff2');
}
@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: 950;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-ExtraBlack.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-ExtraBlack.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: 1000;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-Heavy.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-Heavy.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: bold;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-Bold.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-Bold.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanX;
    font-style: normal;
    font-weight: normal;
    src: url('../../../public/fonts/IRANYekan/woff/IRANYekanX-Regular.woff') format('woff'),
    url('../../../public/fonts/IRANYekan/woff2/IRANYekanX-Regular.woff2') format('woff2');
}
@font-face {
    font-family: yadakpars;
    src: url(../../../public/fonts/yadakpars.ttf?6xeri2) format("truetype"), url(../../../public/fonts/yadakpars.svg?6xeri2#yadakpars) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block
}
@font-face {
    font-family: Virgoul;
    src: url('../../../public/fonts/Virgoul.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: AkharinKhabar;
    src: url('../../../public/fonts/AkharinKhabar.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: shomal;
    src: url('../../../public/fonts/shomal.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: khalijfars;
    src: url('../../../public/fonts/khalijfars.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: Vazirmatn;
    src: url('../../../public/fonts/Vazirmatn-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Vazirmatn FD;
    src: url('../../../public/fonts/Vazirmatn-FD-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Vazirmatn FD;
    src: url('../../../public/fonts/Vazirmatn-FD-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Vazirmatn;
    src: url('../../../public/fonts/Vazirmatn-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Vazirmatn FD;
    src: url('../../../public/fonts/Vazirmatn-FD-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Vazirmatn FD;
    src: url('../../../public/fonts/Vazirmatn-FD-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: Vazirmatn;
    src: url('../../../public/fonts/Vazirmatn-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Vazirmatn;
    src: url('../../../public/fonts/Vazirmatn-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Vazirmatn;
    src: url('../../../public/fonts/Vazirmatn-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Vazirmatn;
    src: url('../../../public/fonts/Vazirmatn-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Vazirmatn;
    src: url('../../../public/fonts/Vazirmatn-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Vazirmatn;
    src: url('../../../public/fonts/Vazirmatn-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'icomoon';
    src: url(../../../public/fonts/icomoon.eot?zgbx8b);
    src: url(../../../public/fonts/icomoon.eot?zgbx8b#iefix) format("embedded-opentype"), url(../../../public/fonts/icomoon.ttf?zgbx8b) format("truetype"), url(../../../public/fonts/icomoon.woff?zgbx8b) format("woff"), url(../../../public/fonts/icomoon.svg?zgbx8b#icomoon) format("svg");
}
@font-face {
    font-family: 'Yekan';
    src: url('../../../public/fonts/Yekan.ttf') format('truetype');
}
@font-face {
    font-family: "Yekan-B";
    src: url("../../../public/fonts/yekan/7296ae79f6faca68798b00fbbbee4c22.eot"); /* IE9*/
    src: url("../../../public/fonts/yekan/7296ae79f6faca68798b00fbbbee4c22.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("../../../public/fonts/yekan/7296ae79f6faca68798b00fbbbee4c22.woff2") format("woff2"), /* chrome、firefox */ url("../../../public/fonts/yekan/7296ae79f6faca68798b00fbbbee4c22.woff") format("woff"), /* chrome、firefox */ url("../../../public/fonts/yekan/7296ae79f6faca68798b00fbbbee4c22.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url("../../../public/fonts/yekan/7296ae79f6faca68798b00fbbbee4c22.svg#YekanBakh-Regular") format("svg"); /* iOS 4.1- */
}
@font-face {
    font-family: Kalame;
    src: url('../../../public/fonts/kalameh/Kalameh_Regular.ttf') format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: Kalame;
    src: url('../../../public/fonts/kalameh/Kalameh_Bold.ttf') format('truetype');
    font-weight: bold;
}
@font-face {
    font-family: Kalame;
    src: url('../../../public/fonts/kalameh/Kalameh_Black.ttf') format('truetype');
    font-weight: 900;
}
@font-face {
    font-family: MooshakFont;
    src: url('../../../public/fonts/MooshakFont.ttf') format('truetype');
    font-weight: 900;
}
@font-face {
    font-family: 'VIP-HALA';
    src: url('../../../public/fonts/Font-VIP-Hala-Bold[DownloadAzad.iR].otf') format('opentype');
}
@font-face {
    font-family: 'sefid';
    font-weight: normal;
    src: url('../../../public/fonts/SefidUI-700-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'sefid';
    font-weight: bold;
    src: url('../../../public/fonts/SefidUI-800-ExtraBold.woff2') format('woff2');
}
@font-face {
    font-family: 'sefid';
    font-weight: 900;
    src: url('../../../public/fonts/SefidUI-900-Black.woff2') format('woff2');
}
@font-face {
    font-family: 'Cera Round Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Cera Round Pro Regular'), url('../../../public/fonts/TypeMates  CeraRoundProRegular.woff') format('woff');
}

* {
    font-family: Vazirmatn;
    direction: rtl;
}

html {
    height: 100%;
    background: #fefefe;
    overflow-x: hidden;
}

body {
    margin: 0 auto;
    overflow-x: hidden;
}
.relative {
    position: relative;
}
.top-head-layer {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    transform: rotateY(180deg);
    opacity: .6;
}
.archive .head-archive {
    background: linear-gradient(90deg, #665d9b 0, #a46499 62%);
    padding: 20px 10% 35px;
    color: #fff;
}
.archive .head-archive h2 {
    font-size: 19px;
}
.archive .recommend {
    width: 80% !important;
    margin: 0 auto;
}
.archive .head-archive .breadcrumb a {
    font-size: 12px;
    vertical-align: text-top;
    color: #fff;
}
.archive .head-archive .breadcrumb i {
    margin: 0 9px;
    font-size: 11px;
}
.new-item {
    display: flex !important;
    align-items: center;
    flex-direction: row;
    margin-left: 20px;
    cursor: pointer;
}
.new-item > div {
    padding: 10px 40px 10px 10px;
    position: relative;
}
.new-item > div .desc {
    font-weight: 200;
    font-size: 11px;
    color: #8d8d8d;
}
.new-item > div .title {
    font-family: 'IRANYekanX';
    font-weight: bold;
    font-size: 13px;
}
.new-item > div:before {
    content: "";
    height: 37px;
    right: -10px;
    position: absolute;
    top: 8px;
    width: 37px;
    background: url(https://static.locofy.ai/web/icon_code.png);
    background-size: auto;
    background-size: cover !important;
}
.top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 15px 0 10px;
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    z-index: 2;
    margin-top: 10px
}
.content-lists li .ft .video-time {
    color: #6f6e6e;
}
.content-lists li .ft .teacher-content {
    display: flex;
}
.content-lists li .ft .teacher-content img {
    width: 39px;
    border-radius: 100%;
}
.content-lists li .ft .teacher-content > div {
    margin-right: 5px;
}
.content-lists li .ft .teacher-content > div a {
    font-size: 11px;
    font-weight: 300;
    margin-bottom: -4px;
}
.content-lists li .ft .teacher-content > div div a {
    font-size: 10px;
    font-weight: 200;
    justify-content: flex-start;
}
.content-lists li .ft .video-time i {
    font-size: 13px;
    vertical-align: middle;
    margin-left: 3px;
}
.footer-box > div .free {
    background: #00C853;
    color: #fff !important;
    font-weight: bold;
    font-size: 10px !important;
    width: 35px;
    border-radius: 4px;
    margin-bottom: 2px;
    text-align: center;
}
.top-header.o {
    padding: 15px 0 10px;
}
.top-header .back {
    background: linear-gradient(0deg, #f3f3f3, #e3e3e300 43%);
    border-radius: 175px;
    height: 702px;
    left: 45%;
    position: absolute;
    top: -234px;
    width: 54%;
    z-index: -1;
}
.c-b {
    transform: rotate(232deg);
    width: 152%;
    filter: grayscale(1);
    opacity: .15;
    top: -81%;
    right: -55%;
    z-index: -1;
    position: absolute;
    height: auto;
}
.head-course-info {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 22px;
    margin-top: 0;
    padding: 0;
}
.offers .character {
    filter: grayscale(1);
}
.head-course-info li {
    width: max-content;
    margin-bottom: 10px;
    margin-left: 15px;
}
.header-back {
    position: absolute;
    filter: grayscale(1);
    opacity: .08;
    z-index: -1;
}
.head-course-info li img {
    width: 29px;
    filter: grayscale(1);
    transition: all .5s;
    vertical-align: middle;
}
.head-course-info li:hover img {
    filter: grayscale(0);
}
.head-course-info li span {
    font-family: 'IRANYEKANX';
    font-weight: 300;
    font-size: 13px;
    margin-right: 8px;
    color: #aaa;
}
.footer-main .back {
    position: absolute;
    width: 85%;
    height: 118%;
    border-radius: 100px 100px 0 0;
    z-index: -1;
    bottom: -41px;
    box-shadow: inset 0 5px 84px 0 #ecececb8;
    margin: 0 auto;
    left: 0;
    right: 0;
}
.top-header.fixed {
    position: fixed;
    top: 0;
    right: 0;
    box-shadow: 1px 2px 50px #00000021;
    z-index: 9999;
    background: #fff;
}
.top-header > div {
    display: flex;
    align-items: center;
}
.top-header .logo {
    width: 130px;
}


.top-header .user-box {
    display: flex;
    align-items: center;
    margin-right: 18px;
    cursor: pointer;
    color: #000;
    position: relative;
}
.top-header .user-box img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-left: 10px;
    vertical-align: middle;
}
.top-header .user-box i {

}
.top-header .user-box > div {
    font-size: 12px;
}
.top-header .user-box > div div {
    font-weight: 200;
    color: #676767;
}
.top-header .user-box > div div.username {
    font-family: 'Kalame';
    font-weight: bold;
    font-size: 19px;
    color: #000;
}
[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-telegram::before {
    content: "\e911";
}
.icon-whatsapp::before {
    content: "\e914";
}
.icon-gmail::before {
    content: "\e908";
}
.icon-twitter::before {
    content: "\e912";
}
.icon-instagram::before {
    content: "\e90a";
}
.icon-linkedin::before {
    content: "\e90b";
}
.icon-facebook::before {
    content: "\e907";
}
.icon-certificate::before {
    content: "\e905";
}
.site-footer__permissions {
    border: 1px solid #e7e7e7;
    border-radius: 6px;
    padding: 35px 22px;
    transition: .3s;
    margin-left: 7px;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.site-footer__permissions i {
    font-size: 37px;
    color: #727272;
}
.site-footer__permissions .site-footer__permissions-txt {
    font-size: 13px;
    margin-top: 10px;
    color: #727272;
}
.site-footer__Honors a {
    font-size: 12px;
    color: #727272;
    line-height: 30px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    display: block;
    text-align: center;
    height: 50%;
    padding: 12px;
}
.site-footer__Honors a:first-child {
    margin-bottom: 7px;
    transition: .3s;
}
.assets-box {
    background-color: #d1d1d154;
    width: 101%;
    height: 58%;
    position: absolute;
    top: 51%;
    right: -13px;
    left: 0;
    -webkit-transform: skewY(-4deg);
    -moz-transform: skewY(-4deg);
    transform: skewY(0deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 30px;
    opacity: 0.7;
    z-index: -1;
    margin: 0 auto;
}
.top-header form {
    background-color: rgb(242 242 242);
    color: rgb(166, 176, 195);
    border-radius: 4px;
    padding: 8px 8px 8px 13px;
    display: flex;
    flex-direction: row;
    -moz-box-align: center;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
}
.top-header form > i {
    margin: 0 7px;
}
.top-header form input {
    border: none;
    background: transparent;
    font-size: 11px;
    margin-right: 6px;
    font-weight: 200;
}
.top-header form .tooltip {
    height: 18px;
    width: 18px;
    border-radius: 4px;
    background-color: rgb(166, 176, 195);
    color: rgb(255, 255, 255);
    line-height: 20px;
    text-align: center;
}
.nav-lists {
    margin: 0;
    display: flex;
    align-items: center;
}
.nav-lists.mobile {
    display: none;
}
.nav-lists li {
    display: inline-block;
    margin-left: 15px;
    position: relative;
    padding: 7px;
}
.nav-lists li.has-sub-menu a {
    z-index: 9;
    position: relative;
}
.nav-lists li.has-sub-menu:hover {
    filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, .10));
    z-index: 9;
    padding: 7px;
    border-radius: 10px 10px 0 0;
}
.nav-lists li a {
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
    color: #7b7b7b;
    font-family: 'IRANYekanX';
    padding: 10px 5px;
    position: relative;
    z-index: 1;
}
.sub-menu {
    display: none;
    position: absolute;
    background: #fff;
    z-index: 1;
    width: 278px;
    border-radius: 23px;
    padding: 12px 15px 10px;
    right: 0;
    top: 38px;
}
.nav-lists li.has-sub-menu:hover .sub-menu {
    display: block;
}
.sub-menu ul {
    padding: 0;
    width: 100%;
}
.sub-menu ul li {
    width: 50%;
    margin: 0;
}
.nav-lists li.has-sub-menu ul li a {
    font-family: 'Vazirmatn';
    font-weight: 200;
}
.nav-lists li.has-sub-menu ul li {
    padding: 5px;
}
.top-header .login {
    font-size: 12px;
    padding: 8px 11px;
    border-radius: 6px;
    color: #3c3c3c;
    background: #e0e0e0;
    font-weight: 700;
    margin: 0 15px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 2px 3px 17px #ebebeb;
}
.top-header .login .fa-user-graduate {
    vertical-align: middle;
    font-size: 21px;
    margin-left: 10px;
}
.top-header .login .fa-angle-down {
    vertical-align: middle;
    margin-right: 7px;
}
.top-header .login div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.top-header .login div i {
    vertical-align: sub;
    font-size: 13px;
    line-height: 1;
    margin-left: 2px;
}
.top-header .login span.txt {
    position: relative;
    z-index: 10;
}
span.effect::before {
    content: '';
    position: absolute;
    width: 70%;
    height: 100%;
    top: 0;
    left: 0;
    border-left: 0;
    background: #c0c0c0;
    clip-path: polygon(0 0, 100% 0, 81% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 81% 100%, 0% 100%);
}
.search {
    display: flex;
    align-items: center;
    position: relative;
    width: 80%;
    margin: -35px auto 0;
    z-index: 1;
}
.search.search-box {
    flex-direction: column;
    width: 100%;
}
.search h2 {
    color: #bfbfbf;
    font-family: IRANYEKANX;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.7;
    margin-bottom: 36px;
    margin-top: 12px;
    position: relative;
    text-align: left;
    width: 100%;
}
.search.search-box h2 {
    text-align: center;
    margin-top: 0;
    font-size: 14px;
    font-weight: 700;
    color: #c4c4c4;
    margin-bottom: 9px;
    font-family: 'IRANYekanX';
}
.search.search-box p {
    margin-top: 0;
    font-family: 'IRANYEKANX';
    font-size: 12px;
    font-weight: 300;
    color: #7b7b7b;
    margin-bottom: 18px;
}
.search.search-box p i {
    margin: 0 10px;
    font-size: 24px;
    vertical-align: middle;
}
.search h2.mobile {
    display: none;
}
.search h2 span {
    font-family: 'IRANYEKANX';
    font-size: 32px;
    color: #fff;
    margin-left: 9px;
    text-align: right;
    margin-bottom: 8px;
    display: block;
    font-weight: 1000;
    text-shadow: -2px 0 #676767, 0 2px #676767, 6px 0 #676767, 0 -8px #676767;
}
.search h3 {
    font-size: 14px;
    text-align: right;
    color: #9a9a9a;
    font-weight: 300;
    margin-top: 15px;
    margin-bottom: 10px;
}
.search form {
    text-align: center;
    background: #fff;
    padding: 9px 23px 8px 15px;
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: -25px 1px 30px #ebebeb;
    border-radius: 9px;
    justify-content: space-between;
    position: relative;
}
.search-ex {
    font-size: 11px;
    margin-top: 9px;
    font-weight: 200;
    margin-right: 29px;
    color: #505050;
    align-self: baseline;
}
.why-amt-back {
    position: absolute;
    left: 201px;
    top: -13px;
    width: 221px;
    z-index: -1;
    opacity: .6;
}
.search-ex a {
    margin: 0 2px;
}
.search form label {
    width: 69%;
    display: flex;
    align-items: center;
}
.search form label i {
    line-height: 1;
    color: darkgrey;
}
.search-icon i {
    color: #919191;
    line-height: 1;
    margin-top: 6px;
    display: inline-block;
    font-size: 22px;
}
.home-back {
    position: absolute;
    right: -550px;
    z-index: -1;
    opacity: .3;
    top: 102px;
    filter: grayscale(1);
}
.lasts-back {
    position: absolute;
    right: -348px;
    z-index: -1;
    opacity: .1;
    top: 23px;
    filter: grayscale(1);
}
.search-header {
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    width: 300px;
    height: 37px;
    border-radius: 7px;
    position: relative;
    margin-left: 4px;
}
.search-header i {
    vertical-align: middle;
    margin-left: 11px;
    color: #d5d5d5;
    line-height: 0;
}
.translator {
    width: 600px;
    margin: 130px auto 0;
    color: #a1a1a1;
}
.translator h3 {
    padding-right: 70px;
    margin-bottom: 0;
    font-family: 'Virgoul';
    font-size: 15px;
}
.translator h2 {
    padding-right: 70px;
    font-family: 'Virgoul';
    font-size: 35px;
    margin-top: 0;
    margin-bottom: 15px;
}
.translator .back {
    box-shadow: inset 0 5px 18px 0 #cfcfcf78;
    border-radius: 29px;
    position: relative;
    display: flex;
    height: 190px;
    align-items: center;
}
.translator .back .r {
    width: 23px;
    right: -42px;
    top: -26px;
    transform: rotate(341deg);
    opacity: .1;
}
.translator .back .l {
    width: 23px;
    left: -42px;
    top: -26px;
    transform: rotate(14deg);
    opacity: .1;
}
.translator .back div {
    margin-right: 10%;
}
.translator .back div.character {
    position: absolute;
    left: 30px;
}
.translator .back p {
    color: #d1d1d1;
    font-size: 21px;
    max-width: 254px;
    font-family: 'AkharinKhabar';
    font-weight: 900;
    text-align: justify;
    margin-bottom: 5px;
    margin-top: 0;
}
.back-top {
    position: absolute;
    height: 736px;
    top: -569px;
    z-index: -1;
    transform: rotate(29deg);
    right: -72px;
}
.translator .back a {
    color: #fff;
    background: #0385fe;
    padding: 6px 12px;
    border-radius: 7px;
    width: 93px;
    box-shadow: 1px 2px 3px #a8bfff;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    display: inline-block;
    font-family: 'Virgoul';
}
.translator .back img {
    position: absolute;
    left: 60px;
    bottom: 15px;
    width: 251px;
}
.amoozesh-info .wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin-bottom: 65px;
    padding: 0;
}
.amoozesh-info .wrapper .count {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    height: 118px;
    margin-right: 20px;
    top: -15px;
}
.amoozesh-info .wrapper .count__number {
    z-index: 2;
    line-height: 1;
    font-weight: 700;
    font-size: 124px;
    position: absolute;
}
.amoozesh-info .wrapper .count__circle {
    position: relative;
    z-index: 1;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background: #f0f0f3;
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
}
.amoozesh-info .wrapper .sub-text {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgba(42, 31, 63, .58);
}
.amoozesh-info .wrapper .reason__title h2 {
    font-size: 17px;
    margin-bottom: 18px;
    margin-top: 0;
    font-family: 'Virgoul';
    color: #323232;
}
.amoozesh-info .wrapper .reason {
    margin-right: 17px;
}
.amoozesh-info .wrapper .reason__points {
    text-align: left;
    list-style: initial;
    margin: 20px 0 0 20px;
}
.amoozesh-info .wrapper .reason__points li {
    padding-bottom: 12px;
    text-align: right;
}
.amoozesh-info .wrapper .search-header i {
    position: absolute;
    top: 58%;
    transform: translateY(-50%);
    left: 10px;
    color: #9f9f9f;
}
.amoozesh-info .wrapper .c-headline {
    font-family: poppins, sans-serif;
    color: #2a1f3f;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 20px;
}
.amoozesh-info .wrapper .c-product-box {
    max-width: 320px;
    background: #fff;
    border-radius: 4px;
    width: 185px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1;
    -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);
}
.amoozesh-info .wrapper .c-product-box .u-ratio--3-2 {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}
.amoozesh-info .wrapper .c-product-box__title {
    overflow: hidden;
    padding: 0 20px 0 0;
}
.amoozesh-info .wrapper .c-product-box__title .c-headline {
    white-space: nowrap;
    margin-bottom: 0;
    letter-spacing: .3px;
}
.amoozesh-info .wrapper .c-product-box__inner-content {
    padding: 5px 10px;
    text-align: left;
    position: relative;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.amoozesh-info .wrapper .c-product-box__image-frame {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    border-bottom: 1px solid #e3e1e9;
}
.amoozesh-info .wrapper .c-product-box__meta {
    position: relative;
    margin: 8px 0 7px;
}
.amoozesh-info .wrapper .ll-image {
    position: relative;
    padding-bottom: 66.5492958%;
    background: linear-gradient(#f2f2f2 66%, rgba(242, 242, 242, 0));
    height: 100%;
    overflow: hidden;
}
.amoozesh-info .wrapper .ll-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.amoozesh-info .wrapper .alternating-row__visual {
    width: 100%;
    position: relative;
}
@media (min-width: 768px) {
    .amoozesh-info .wrapper .alternating-row__visual {
        width: 50%;
    }
}
.amoozesh-info .wrapper .u-ratio--3-2 {
    display: block;
    max-height: 185px;
    height: 100%;
}
.amoozesh-info .wrapper .alternating-row__visual {
    text-align: center;
}
.amoozesh-info .wrapper .alternating-row__visual img {
    margin: auto;
}
.amoozesh-info .wrapper .reasons-why .alternating-row:nth-child(odd) .alternating-row__visual {
    order: 2;
}
.amoozesh-info .wrapper .visual-stacking {
    height: 300px;
}
@media (min-width: 576px) {
    .amoozesh-info .wrapper .visual-stacking {
        height: 480px;
    }
}
@media (min-width: 768px) {
    .amoozesh-info .wrapper .visual-stacking {
        height: 450px;
    }
}
.amoozesh-info .wrapper .visual-stacking--bg {
    background: url(https://www.creativefabrica.com/wp-content/themes/creativefabricanew/v2.0/images/abstract-shape-1.svg) no-repeat center;
    background-size: 100%;
}
.amoozesh-info .wrapper .visual-stacking .c-product-box {
    width: 265px;
    position: absolute;
    max-width: none;
    width: 42%;
    overflow: hidden;
}
.amoozesh-info .wrapper .visual-stacking .c-product-box h3 {
    font-size: 95%;
}
@media (min-width: 768px) {
    .amoozesh-info .wrapper .visual-stacking .c-product-box h3 {
        font-size: 14px;
    }
}
.amoozesh-info .wrapper .visual-stacking .c-product-box p {
    font-size: 80%;
    margin-bottom: 0;
}
@media (min-width: 768px) {
    .amoozesh-info .wrapper .visual-stacking .c-product-box p {
        font-size: 12px;
    }
}
.amoozesh-info .wrapper .animation-stacking .c-product-box {
    -webkit-animation: stack-items .4s cubic-bezier(.25, .46, .45, .94) both;
    animation: stack-items .4s cubic-bezier(.25, .46, .45, .94) both;
}
.amoozesh-info .wrapper .animation-stacking .c-product-box:nth-child(1) {
    top: 0;
    right: 10%;
    z-index: 1;
}
.amoozesh-info .wrapper .animation-stacking .c-product-box:nth-child(2) {
    top: 17%;
    left: 12%;
    animation-duration: 1s;
}
.amoozesh-info .wrapper .animation-stacking .c-product-box:nth-child(3) {
    top: 40%;
    left: 19%;
    z-index: 3;
    animation-duration: 1.5s;
}
.amoozesh-info .wrapper .animation-stacking .c-product-box:nth-child(4) {
    top: 34%;
    right: 14%;
    z-index: 4;
    animation-duration: 2s;
}
.amoozesh-info .wrapper .animation-stacking .c-product-box:nth-child(5) {
    top: 10%;
    left: 28%;
    z-index: 5;
    animation-duration: 2.4s;
}


@-webkit-keyframes stack-items {
    0% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes stack-items {
    0% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.amoozesh-info .wrapper > div {
    display: flex;
}
.amoozesh-info {
    width: 80%;
    margin: 0 auto;
}
.amoozesh-info > h2 {
    text-align: center;
    font-family: 'Virgoul';
    margin-bottom: 55px;
    font-size: 30px;
    margin-top: 65px;
}
.amoozesh-info .c-card--shadow {
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%);
}
.amoozesh-info .visual-popular .c-card {
    padding: 20px 0;
    margin: 10px 0;
    width: 60%;
    align-self: center;
}
.visual-popular {
    background: url('https://www.creativefabrica.com/wp-content/themes/creativefabricanew/v2.0/images/abstract-shape-2.svg') no-repeat center;
    background-size: 90%;
    height: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.amoozesh-info .visual-popular .c-card {
    margin: 0 20px 0 0;
    width: 240px;
    background: #fff;
}
.amoozesh-info .visual-popular .c-card h1, .amoozesh-info .visual-popular .c-card h3 {
    margin: 0;
}

.amoozesh-info > h2 span:first-of-type {
    font-family: 'Kalame';
    font-size: 40px;
    vertical-align: middle;
    line-height: 0;
    margin-left: 10px;
}
.amoozesh-info > h2 span:last-of-type {
    color: #fbbf21;
    font-family: 'Virgoul';
    margin: 0 8px;
}
.search-header input {
    border: none;
    height: 35px;
    font-size: 12px;
    width: 100%;
    border-radius: 10px;
    padding: 7px 16px;
    font-family: Yekan-B;
}
.search-header input::placeholder {
    color: #999999;
}
.free .content-lists li .ft > div span.price {
    font-family: 'Vazirmatn';
    font-size: 11px;
    background: #00C853;
    color: #fff;
    padding: 1px 10px;
    border-radius: 4px;
    font-weight: 900;
}
.free .actions {
    display: flex;
    width: 60px;
    justify-content: space-around;
    align-items: center;
}
.free .actions span {

}
.free .actions span i {
    color: #000;
    font-size: 14px;
}
.offers {
    width: 80%;
    margin: 80px auto 41px;
    padding: 6px 10px 6px 0;
    position: relative;
}
.offers .back {
    background: linear-gradient(266deg, #f3f3f3 0%, #e3e3e300 55%);
    position: absolute;
    width: 88%;
    height: 200px;
    border-radius: 48px;
    top: 0;
    right: 0;
}
.offers > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.offers > div > div:first-of-type {
    width: 310px;
    position: relative;
    margin-top: 22px;
}
.offers > div > div:first-of-type h5 {
    color: #0073C2;
    font-size: 17px;
    margin-top: 0;
    margin-bottom: 0;
}
.offers > div > div:first-of-type h2 {
    position: relative;
    font-family: IRANYekanX;
    font-size: 20px;
    color: #414141;
    margin-bottom: -2px;
    font-weight: 1000;
    margin-top: 0;
    background: #FFFFFF;
    /* background: linear-gradient(to left, #ffffff 0%, #ffffff 35px, #000 7%, #000000 100%); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.offers > div > div:first-of-type h2 span {
    position: absolute;
    top: -4px;
    right: -5px;
    width: 60px;
    height: 34px;
    background: #fdbe19;
    z-index: -1;
    border-radius: 4px;
    transform: skew(350deg, 10deg);
}
.teachers {
    width: 50%;
    position: relative;
}
.teachers .t-back {
    position: absolute;
    height: 324px;
    width: 432px;
    background: #eeeeee87;
    border-radius: 50%;
    right: -21%;
    top: -55px;
    z-index: -1;
    transform: rotate(189deg);
}
.row-t .tops {
    width: 45%;
    margin-right: 4%;
}
.row-t .top-lists {
    margin-top: 25px;
}
.row-t .top-lists > div {
    margin-bottom: 20px;
    box-shadow: 1px 1px 30px #e0e0e0;
    border-radius: 13px;
}
.row-t .top-lists > div:first-of-type {
    width: 90%;
}
.row-t .top-lists > div:last-of-type {
    width: 90%;
    margin-right: 10%
}
.row-t .top-lists > div p {
    font-size: 12px;
    margin-bottom: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 0;
    font-weight: 200;
    white-space: break-spaces;
    color: #6b6a6a;
}
.row-t .top-lists > div .footer-box > div span:first-of-type {
    margin-left: 4px;
    color: #000;
    font-weight: bold;
    font-size: 22px;
}
.row-t .top-lists > div .footer-box > div span.free {
    padding: 3px 6px;
    width: auto;
}
.teachers .head h1, .tops .head h1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0
}
.teachers .head h1 span, .tops .head h1 span {
    font-family: 'IRANYekanX';
    font-size: 16px;
    font-weight: 1000;
    margin-bottom: 0;
}
.teachers .head h2, .tops .head h2 {
    font-weight: 300;
    font-size: 13px;
    color: #8b8b8b;
}
.row-t {
    display: flex;
    margin: 60px auto 40px;
    width: 80%;
}
.teachers .lists {
    width: 100%;
    margin: 0 auto;
}
.teachers .lists ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 35px 0 0;
    padding: 0
}
.teachers .lists ul li {
    width: 48%;
    display: flex;
    margin-bottom: 30px;
}
.teachers .lists ul li:nth-child(1), .teachers .lists ul li:nth-child(3) {
    margin-left: 4%;
    flex-direction: row-reverse;
    text-align: left;
}
.teachers .lists ul li:nth-child(1) div:last-of-type, .teachers .lists ul li:nth-child(3) > div:last-of-type {
    margin-right: 0;
    margin-left: 10px;
}
.teachers .lists ul li > div {
    position: relative;
}
.teachers .lists ul li > div:last-of-type {
    margin-right: 10px;
}
.teachers .lists ul li img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
.teachers .lists ul li h3, .teachers .lists ul li h3 a {
    font-family: 'IRANYekanX';
    font-weight: 900;
    margin: 0;
    font-size: 15px;
    color: #000;
}
.teachers .lists ul li h4 {
    margin-top: 5px;
    font-weight: 300;
    font-family: 'IRANYekanX';
    font-size: 12px;
    color: #555454;
}
.teachers .lists ul li p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: 'IRANYekanX';
    font-weight: 200;
    line-height: 2;
    font-size: 12px;
    text-align: justify;
}
.offers > div > div:first-of-type p {
    font-weight: 100;
    font-size: 13px;
    font-family: 'IRANYEKANX';
    color: #ffffff;
    line-height: 2;
}
.offers > div > div:last-of-type {
    width: calc(100% - 420px);
    margin-top: -30px;
}
.offers > div > div:last-of-type ul {
    margin-bottom: 0 !important;
}
.offers > div > div:last-of-type .embla {
    padding: 0 !important;
}
.offers > div > div:last-of-type .content-lists {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}
.bottom-header-menu {
    display: flex;
    list-style: none;
    justify-content: center;
    box-shadow: 0 9px 27px #0000000f;
    padding: 12px 0;
    background: #fff;
    margin: 5px auto 0;
}
.bottom-header-menu li {
    margin-left: 60px;
    position: relative;
}
.bottom-header-menu li a {
    color: #8795a1;
    font-size: 13px;
}
.bottom-header-menu li span {
    position: absolute;
    background: rgb(248 178 51);
    color: #fff;
    font-weight: 900;
    font-size: 10px;
    padding: 1px 8px;
    border-radius: 4px;
    top: -5px;
    left: -42px;
    font-family: 'Virgoul';
}
.search form select {
    padding: 8px 9px;
    border: none;
    color: #a9a9a9;
    background-repeat: no-repeat;
    background: transparent;
    margin-left: 8px;
    font-family: 'IRANYEKANX';
    font-size: 12px;
}
.arrow-search {
    position: absolute;
    top: -114px;
    width: 99px;
    filter: grayscale(1);
    opacity: .2;
    left: -50px;
    right: 0;
    margin: 0 auto;
}
.search form label input {
    border: none;
    width: 100%;
    font-weight: 400;
    margin-right: 33px;
    font-family: Yekan-b;
}
.ds-dropdown-menu {
    border-radius: 14px;
    padding: 0;
    text-align: left;
    background: #fff;
    max-width: 600px;
    width: 57%;
    box-shadow: none;
    position: absolute;
    top: 138%;
    z-index: 100;
    left: 0;
    right: 0;
    border: 2px solid #183153;
    min-height: 65px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-loading svg {
    margin-right: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}
.ds-dropdown-menu > p {
    text-align: center;
    font-size: 12px;
}
.ds-dropdown-menu::before {
    display: block;
    position: absolute;
    content: "";
    width: 14px;
    height: 14px;
    background: #fff;
    z-index: 1000;
    top: -9px;
    border-top: 1px solid #d9d9d9;
    border-top-color: #183153;
    border-top-width: 2px;
    border-right: 2px solid #183153;
    border-right-color: #183153;
    border-right-width: 2px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-radius: 2px;
    right: 48px;
    left: 2em;
}
.search form label input:focus-visible, .search form label input:focus {
    border: none;
    outline: none;
}
.search form label input::placeholder {
    color: #acadb3;
}
.search form label button {
    border: none;
    background: #fbc028;
    line-height: 1;
    color: #fff;
    padding: 12px 23px;
    border-radius: 5px;
    font-weight: bold;
}
.search form label button img {
    width: 27px;
    height: auto;
    opacity: .5;
}
.lasts .lasts-head li {
    display: inline-block;
    margin-left: 12px;
}
.lasts .lasts-head li.line {
    height: 15px;
    width: 3px;
    background: red;
    vertical-align: middle;
    border-radius: 10px;
}
.lasts .lasts-head li .premium {
    color: #FF1744;
}
.lasts .lasts-head li .free {
    color: #00C853;
}
a {
    text-decoration: none;
}
.content .lasts {
    width: 80%;
    margin: 35px auto 0;
    position: relative;
}
.content .lasts > svg {
    position: absolute;
    top: -150px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: .1;
}
.content-lists {
    margin-bottom: 45px;
    display: inline-block;
    width: 100%;
    margin-top: -20px;
    text-align: center;
    padding: 0;
}
.amt-article .content-lists {
    margin-top: 0;
    margin-bottom: 0;

}
.embla {
    padding: 35px 0 45px;
    box-sizing: border-box;
}
.amt-article .embla {
    padding: 0;
}
.embla__container {
    display: flex;
}
.embla__slide {
    position: relative;
    flex: 0 0 18.5%;
    width: 18.5%;
}
.amt-article .embla__slide {
    position: relative;
    flex: 0 0 18.5%;
    width: 18.5%;
    margin: 0 10px;
    border-radius: 20px;
    box-shadow: 1px 1px 30px #e0e0e0;
}
.offers .embla__slide {
    flex: 0 0 31%;
}
.nav-btn {
    position: absolute;
    top: 25px;
    width: 24px;
    cursor: pointer;
}
.offers .nav-btn {
    position: relative;
    background: unset !important;
    margin-left: 12px;
    margin-top: 10px;
    display: inline-block;
    z-index: 10;
    top: unset;
    left: unset !important;
}
.nav-btn.next {
    left: 90px;
}
.nav-btn.prev {
    left: 54px;
}
.nav-btn > i {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    opacity: .4;
    color: #adaaaa;
    font-size: 40px;
}
.offers .nav-btn > i {
    position: unset;
}
.in-visible {
    visibility: hidden;
}
.content-lists li {
    display: inline-block;
    padding: 10px;
    box-shadow: 1px 1px 40px #d4d4d4;
    background: #fff;
    border-radius: 20px;
    min-height: 195px;
    vertical-align: text-bottom;
    margin-left: 1.5%;
    box-sizing: border-box;
}
.lasts .content-lists li {
    padding: 6px 6px 10px;
    box-shadow: 1px 1px 40px #d4d4d4;
    background: #fff;
    border-radius: 20px;
    min-height: 195px;
    vertical-align: text-bottom;
    margin-left: 1.5%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.offers .content-lists li {
    padding-top: 70px;
    margin-left: 2.3%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0px -1px 108px #212121;
}
.offers .price-wrapper {
    display: flex;
    align-items: center;
}
.content-lists li div:not(.cover) > a {
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
    color: #747373;
    border-radius: 9px;
    font-family: 'IRANYEKANX';
    font-weight: 900;
}
.teacher-menu {
    overflow: hidden;
    height: 140px;
    position: relative;
    margin-bottom: 15px;
    transition: all .5s;
}
.teacher-menu:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 22%;
    display: inline-block;
    border-radius: 4px;
    opacity: .9;
    background: white;
    z-index: 1;
}
.teacher-menu.active {
    height: max-content;
}
.teacher-menu.active:before {
    content: unset;
}
.teacher-menu .show-more {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 90%;
    text-align: center;
    z-index: 2;
    font-family: 'IRANYEKANX';
    font-size: 11px;
    cursor: pointer;
    border: 1px solid #b0aaaa;
    padding: 6px;
    border-radius: 5px;
    color: #5e5e5e;
    margin: 0 auto;
}
.teacher-menu.active .show-more {
    position: inherit;
    display: block;
}
.teacher-menu .show-more:hover {
    opacity: .7;
}
.content-lists li div > a i {
    margin-right: 9px;
    font-size: 15px;
}
.content-lists li .cover {
    width: 97%;
    overflow: hidden;
    height: auto;
    margin: 3px auto 0;
    border-radius: 20px;
}
.content-lists li .cover a {
    display: flex;
}
.offers .content-lists li .cover, .offers .swiper-slide .cover {
    width: 90%;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 20px;
    position: inherit;
    margin-top: -127px;
    left: 0;
    right: 0;
}
.content-lists li p, .swiper-slide p {
    font-size: 12px;
    margin-bottom: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 0;
    font-weight: 200;
    max-height: 38px;
}
.content-lists li h3 {
    text-align: center;
    margin: 10px 0 5px;
    font-size: 15px;
    padding: 0 10px;
}
.content-lists li h3 a, .swiper-slide h3 a {
    color: #000;
    font-family: 'IRANYekanX';
}
.content-lists li .cover img, .swiper-slide .cover img {
    width: 100%;
    min-height: 125px;
}
.content-lists li .ft, .swiper-slide .ft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    width: 100%;
}
.lasts .content-lists li .ft, .archive .content-lists li .ft {
    border-top: 1px solid #eee;
    padding-top: 10px;
    margin-bottom: 3px;
}
.content-lists li .ft > div, .swiper-slide .ft > div {
    line-height: 1;
}
.content-lists li .ft > div span {
    font-weight: 100;
    font-size: 12px;
    margin-right: 3px;
    font-family: 'Vazirmatn FD'
}
.content-lists li .ft > div span.free {
    background: #00C853;
    color: #fff;
    padding: 1px 6px;
    border-radius: 17px;
    font-family: 'IRANYEKANX';
    font-weight: 900;
}
.content-lists li .ft > div span.price {
    margin-left: 4px;
    color: #000;
    font-weight: bold;
    font-size: 20px;
}
.content-lists li .ft .time {
    color: #808080;
    text-align: left;
    margin-top: 3px;
}
.content-lists li .ft .time i {
    vertical-align: middle;
    font-size: 13px;
    margin-left: 3px
}
.content-lists li .ft > div span.price del {
    font-family: 'Vazirmatn FD';
    font-weight: 600;
    font-size: 21px;
    color: #e2e2e2;
}
.content-lists li .ft > div span.price div {
    font-family: 'VAZIRMATN FD';
    font-weight: bold;
    font-size: 23px;
}
.content-lists li .ft .price-wrapper .discount {
    background: #ef394e;
    color: #fff;
    font-weight: 900;
    padding: 1px 5px;
    border-radius: 14px;
    margin-left: 3px;
}
.section-title-wrap {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    margin-right: 25px;
}
.section-title-wrap .section-title {
    display: inline-block;
    line-height: 1.2;
    margin: 0 0 12px;
    font-size: 16px;
    color: #2d2d2d;
    font-family: 'IRANYekanX';
    position: relative;
    font-weight: 900;
}
.section-desc {
    margin: 0;
    line-height: 20px;
    font-size: 12px;
    color: #6e6e6e;
    font-weight: 200;
}
.amt-describe {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
}
.amt-describe li {
    width: 50%;
    flex: 0 0 50%;
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 17px;
}
.amt-describe li span {
    color: #5a5a5a;
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
}
.r-head {
    float: right;
}
.r-head i {
    font-size: 45px;
    margin-left: 14px;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    border-radius: 15px;
}
::-webkit-scrollbar-thumb {
    background: #BDBDBD;
    border-radius: 15px;
}
::-webkit-scrollbar-thumb:hover {
    background: #757575;
    cursor: pointer;
}
.firefox-scroll {
    scrollbar-color: #BDBDBD #fff;
    scrollbar-width: thin;
}
.switch-light span span {
    display: none;
    cursor: pointer;
}
.switch-light {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    margin: 0 3px;
    direction: ltr;
    width: 60px !important;
}
.switch-light::after {
    clear: both;
    content: "";
    display: table;
}
.switch-light *, .switch-light *:before, .switch-light *:after {
    box-sizing: border-box;
}
.switch-light a {
    display: block;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.switch-light > span {
    line-height: 2em;
    vertical-align: middle;
}
.switch-light input:focus ~ span a {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
}
.switch-light input:focus ~ span a {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
}
.switch-light input {
    position: absolute;
    opacity: 0;
    z-index: 3;
    display: none;
}
.switch-light input:checked ~ span a {
    right: 0%;
}
.switch-light > span {
    position: relative;
    overflow: hidden;
    display: block;
    min-height: 2em;
    padding: 0;
    text-align: left;
}
.switch-light span span {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    width: 50%;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.switch-light a {
    position: absolute;
    right: 50%;
    top: 0;
    z-index: 1;
    display: block;
    width: 50%;
    height: 100%;
    padding: 0;
}
.switch-ios.switch-light span span {
    color: #888b92;
}
.switch-ios.switch-light a {
    left: 0;
    right: 60%;
    top: 0;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 100%;
    border: 0.25rem solid #e2e2e2;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.switch-ios.switch-light > span {
    display: block;
    width: 100%;
    min-height: 25px;
    background-color: #e2e2e2;
    border-radius: 16px;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    cursor: pointer;
}
.switch-ios.switch-light > span span {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    opacity: 0;
    line-height: 1.875rem;
    vertical-align: middle;
    transition: all 0.2s ease-out;
    font-size: 11px !important;
    color: #fff;
    font-weight: bold;
}
.switch-ios.switch-light > span span:first-of-type {
    opacity: 1;
    padding-left: 1.275rem;
}
.switch-ios.switch-light > span span:last-of-type {
    padding-right: 1.875rem;
}
.switch-ios.switch-light input:checked ~ span a {
    left: 100%;
    border-color: #4BD865;
    margin-left: -2rem;
}
.switch-ios.switch-light input:checked ~ span {
    border-color: #4BD865;
    box-shadow: inset 0 0 0 30px #4BD865;
}
.switch-ios.switch-light input:checked ~ span span:first-of-type {
    opacity: 0;
}
.switch-ios.switch-light input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
}
.lasts .section-title-wrap .section-title {
    margin: 0 0 10px;
}
.more-update {
    font-family: 'Yekan';
    border: 1px solid #000;
    padding: 1px 6px;
    border-radius: 2px;
    font-size: 11px;
    margin-right: 7px;
    vertical-align: middle;
    font-weight: bold;
    color: #000;
    display: inline-block;
    line-height: 1.5;
}
.more-update:hover {
    opacity: .5;
}
.back-article {
    position: absolute;
    left: -400px;
    z-index: -1;
    opacity: .1;
    top: 50px;
    filter: brightness(0.7);
}
.amt-offer-discount {
    display: flex;
    width: 80%;
    margin: 50px auto 60px;
    position: relative;
}
.amt-offer-discount .offer {
    width: calc(100% - 340px);
    position: relative;
    margin-left: 15px;
}
.amt-offer-discount .offer.full {
    width: 100%;
}
.back-offer {
    position: absolute;
    z-index: -1;
    margin: 0 auto;
    top: -44px;
    left: 36px;
    right: 0;
    width: 73%;
    filter: brightness(0.9);
    opacity: .2;
    transform: rotate(6deg);
}
.amt-offer-discount .offer .content-lists {
    margin-bottom: 0;
    margin-top: -2px;
}
.amt-offer-discount .offer .content-lists li {
    width: 23%;
}
.amt-offer-discount .offer .section-title-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
}
.amt-offer-discount .offer .section-title-wrap .section-title img {
    z-index: 1;
}
.amt-offer-discount .offer .section-title-wrap .section-title span {
    z-index: 2;
    position: relative;
    font-family: 'Virgoul';
    font-size: 18px;
}
.green-color {
    font-family: 'Virgoul';
    padding: 9px 18px 4px;
    border-radius: 39px;
    font-size: 33px;
    line-height: 1;
    transform: rotate(332deg);
    display: inline-block;
    top: -31px;
    left: -61px;
    position: absolute;
}
.amt-offer-discount .discount {
    width: 300px;
    position: relative;
    z-index: 999;
}
.amt-offer-discount .discount.fixed {
    position: fixed;
    top: 12px;
    left: 202px;
}
.arrow-header {
    position: absolute;
    width: 227px;
    bottom: 34px;
    left: 0;
    right: 138px;
    margin: 0 auto;
    transform: rotate(150deg);
    opacity: .3;
}
.post__article-footer {
    padding: 0 4px 0 6px;
}
.post__article-footer .post__inner-span {
    margin-right: 10px;
}
.align-items-center {
    align-items: center !important;
}
.post__five {
    font-size: 27px;
    color: #fbbf21;
    margin-left: 8px;
    line-height: 1;
    font-family: Vazirmatn FD;
    font-weight: bold;
}
.flex-column {
    flex-direction: column !important;
}
.post__five-read-secnd span {
    font-size: 12px;
    color: #aeaeae;
    line-height: 16px;
    width: 33px;
    white-space: break-spaces;
}
.post__article-footer {
    justify-content: space-between;
}
@media (max-width: 415px) {
    .more-update {
        display: none;
    }
}

.amt-new .landing-hot-pens-container .hot-pens-title {
    flex-direction: column;
}
.amt-new {
    position: relative;
    background-color: #f2f2f2;
    margin: 15px 5px 15px 20px;
    border-radius: 30px;
    box-shadow: inset 5px 5px 15px #00000026;
    z-index: 0;
    margin-bottom: 40px;
    display: inline-block;
    width: 100%;
}
.amt-new .hot-pens-bg-icon-big {
    position: absolute;
    right: 15px;
    top: 10px;
}
.amt-new .landing-hot-pens-container {
    padding: 30px 0 15px;
}
.d-flex {
    display: flex !important;
}
.position-relative {
    position: relative !important;
}
.amt-new .sub-title {
    font-size: 12px;
    font-weight: 300;
    color: #6b6b6b;
    margin-top: 0;
}
.back-teacher {
    width: 80%;
    height: 480px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: -90%;
    top: -55px;
    opacity: .9;
}
.r-b-i {
    position: absolute;
    right: -3px;
    top: -8px;
    z-index: -1;
    width: 36px;
    height: auto;
    /* filter: grayscale(1); */
    transform: rotate(18deg);
}
.r-b-i.head {
    width: 52%;
    top: -202px;
    filter: grayscale(1);
    right: 0;
    opacity: .15;
}
.back-right {
    background: #fdbe19;
    position: absolute;
    width: 46%;
    height: 59%;
    border-radius: 40% 40% 40%;
    top: 54px;
    margin: 0 auto;
    transform: rotate(20deg);
    right: 212px;
    z-index: -1;
}
.amt-new .sa-title .sa-title-text {
    position: relative;
    z-index: 1;
    font-size: 17px;
    font-family: 'Virgoul';
}
.amt-new .content-lists li .cover {
    width: 100%;
    overflow: hidden;
    height: 120px;
    margin: 0 auto;
    border-radius: 20px 20px 0 0;
}
.amt-new .content-lists li > div:not(.cover) {
    background: #fff;
    box-shadow: 1px 1px 73px #bdbdbd;
    border-radius: 16px;
    padding: 36px 6px 7px;
    margin-top: -35px;
}
.amt-new .content-lists li {
    box-shadow: none;
    background: transparent;
}
.justify-content-center {
    justify-content: center !important;
}
.align-items-center {
    align-items: center !important;
}
.advertise {
    display: flex;
    width: 80%;
    margin: 60px auto;
    justify-content: space-between;
    align-items: center;
}
.advertise > div:first-of-type {
    width: 555px;
}
.advertise > div:last-of-type {
    width: calc(100% - 585px);
}
.advertise .routes {
    display: flex;
    padding: 0;
    flex-direction: column;
}
.advertise .routes li {
    list-style: none;
    width: 97%;
    text-align: center;
    border-radius: 19px;
    margin-bottom: 20px;
    padding: 8px 28px 20px;
    color: #fff;
    position: relative;
}
.advertise .routes li:nth-child(1) {
    background: #651FFF;
    box-shadow: 0 2px 8px -2px #651fff;
}
.advertise .routes li:nth-child(2) {
    background: #3D5AFE;
    box-shadow: 0 2px 8px -2px #3d5afe;
    margin-bottom: 0;
}
.advertise .routes li:nth-child(3) {
    background: #32a540;
    box-shadow: 0 2px 8px -2px #31a540;
}
.advertise .routes li img {
    width: auto;
    height: 110%;
    position: absolute;
    left: 10px;
    bottom: -2px;
    z-index: 99;
}
.advertise .routes li h3 {
    font-size: 16px;
    font-weight: 900;
    margin-top: 9px;
    font-family: 'IRANYekanX';
    margin-bottom: 0;
    width: 63%;
    text-align: right;
}
.advertise .routes li h3 a {
    font-family: 'IRANYekanX';
    font-size: 16px;
    font-weight: 900;
    color: #fff;
}
.advertise .routes li p {
    width: 64%;
    font-size: 12px;
    margin-bottom: 0;
    text-align: justify;
    padding: 0 9px;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.stepsWrapper {
    position: relative;
    display: flex;
    height: 273px;
    align-items: center;
}
.test-wrapper .stepsWrapper {
    height: auto;
}
.stepsWrapper .swiper-slide {
    background: #ffffff;
}
.stepsWrapper .title-tour {
    font-size: 0;
    margin-bottom: 20px;
    height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 45px;
}
.stepsWrapper .title-tour .title-block {
    color: #7c7c7c;
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    font-family: 'IRANYekanX';
}
.stepsWrapper .title-tour .desc-block {
    color: #bcc0cf;
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    direction: rtl;
}
.stepsWrapper .content-tour .text-tour {
    text-align: justify;
    color: #9197ae;
    line-height: 30px;
    font-size: 14px;
}
.stepsWrapper .footer-tour > div {
    display: flex;
    justify-content: space-between;
}
.login-wrapper {
    width: 29rem;
    margin: 48px auto;
    text-align: center;
    border-radius: 28px;
}
.login-wrapper .logo {
    width: 180px;
}
.login-wrapper .tabs {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.bottom-btn-login {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
}
.login-wrapper .tabs li {
    width: 30%;
    text-align: center;
    padding: 14px 0;
    border-radius: 9px;
    color: #cbcbcb;
}
.login-wrapper .tabs li.active {
    background: transparent;
    color: #d1d1d1;
    box-shadow: inset 0 5px 18px 0 #cfcfcf78;
}
.login-wrapper .tabs li i {
    vertical-align: middle;
    margin-left: 11px;
}
.login-wrapper .description {
    color: #525252;
    font-size: 15px;
    margin: 32px 0 5px;
}
.login-wrapper .description span {
    color: #6d6d6d;
    font-size: 13px;
}
.login-block-wrapper, .register-block-wrapper {
    display: none;
}
.login-block-wrapper.active, .register-block-wrapper.active {
    display: block;
}
.login-wrapper .description p {
    margin-bottom: 5px;
    font-family: 'IRANYekanX';
    font-weight: 900;
    font-size: 25px;
    color: #676767;
}
.login-wrapper .auth-logins {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 35px;
}
.login-wrapper .auth-logins a {
    display: inline-block;
    flex: 1 1;
    text-align: center;
    padding: 14px 0;
    box-shadow: 1px 12px 26px #ececec;
    margin: 0 7px;
    border-radius: 7px;
    font-size: 12px;
    color: #4d5c77;
    font-weight: 700;
    font-family: 'IRANYEKANX';
}
.login-wrapper .or-line {
    position: relative;
    height: 50px;
}
.login-wrapper .or-line:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background: #f0f0f0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.login-wrapper .or-line h3 {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: max-content;
    background: #fff;
    padding: 0 21px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: #b6b6b6;
    font-weight: 300;
}
.login-wrapper .fields .submit {
    color: #fff;
    background: #0385fe;
    padding: 14px 12px;
    border-radius: 7px;
    width: 80%;
    font-weight: 900;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    margin: 18px 0;
    font-family: 'IRANYekanX';
}
.payment-page {
    box-shadow: 1px 45px 89px #dfdfdf;
    overflow: hidden;
    width: 375px;
    margin: 40px auto 0;
    border-radius: 20px;
    text-align: center;
    padding: 15px 30px;
}
.payment-page .submit {
    color: #fff;
    background: #0385fe;
    padding: 10px 12px;
    border-radius: 7px;
    width: 59%;
    font-weight: 900;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    margin: 5px 0;
    font-family: 'IRANYekanX';
}
.login-wrapper .fields .bottom-btn-login a:first-of-type {
    width: 49%;
    margin-left: 2%;
}
.login-wrapper .fields .bottom-btn-login a:last-of-type {
    width: 49%;
}
.login-wrapper .fields label {
    display: block;
    text-align: right;
    font-size: 12px;
    width: 80%;
    margin: 0 auto 15px;
    color: #7d7d7d;
    font-family: 'IRANYekanX';
    position: relative;
    font-weight: 500;
}
.login-wrapper .fields label i {
    position: absolute;
    left: 15px;
    bottom: 12px;
    font-size: 17px;
    color: #c6c6c6;
    cursor: pointer;
}
.login-wrapper .fields label i:hover {
    opacity: .8;
}
.login-wrapper .fields label div {
    display: flex;
    justify-content: space-between;
}
.login-wrapper .fields label div span {
    color: #828282;
    font-size: 12px;
}
.login-wrapper .fields label div a {
    font-weight: 900;
    color: #9f9f9f;
}
.login-wrapper .fields label input {
    width: 100%;
    display: block;
    border: 1px solid #e7e6e6;
    padding: 9px 15px;
    border-radius: 6px;
    margin-top: 10px;
}
.login-wrapper .fields label input::placeholder {
    font-size: 12px;
    color: #d9d9d9;
}
.login-wrapper .auth-logins a svg {
    vertical-align: middle;
    margin-left: 10px;
}
.login-wrapper .tabs li a {
    font-weight: 1000;
    font-size: 17px;
    font-family: 'IRANYEKANX';
}
.login-wrapper .login {
    box-shadow: 1px 1px 89px #e2e2e2;
    border-radius: 20px;
    padding: 20px;
    margin-top: 30px;
}
.stepsWrapper .steps {
    padding: 0;
}
.stepsWrapper .steps li {
    list-style: none;
    opacity: 0;
    display: none;
    transition: all 1s;
}
.stepsWrapper .steps li.active {
    display: block;
    opacity: 1;
}
.stepsWrapper .steps li.active .content-tour {
    border: 1px solid #dce0ed;
    border-radius: 20px;
    padding: 0 35px;
    margin-top: 0;
    font-weight: 200;
    height: 207px;
    display: flex;
    align-items: center;
}
.test-wrapper .stepsWrapper .steps li.active .content-tour {
    border: none;
    height: auto;
}
.stepsWrapper .content-tour .text-tour {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.test-wrapper .stepsWrapper .content-tour .text-tour {
    overflow: unset;
    display: inline-block;
}
.test-wrapper .user-info {
    margin-bottom: 15px;
}
.test-wrapper .user-info span {
    font-family: 'IRANYekanX';
    font-weight: bold;
    color: #999595;
    font-size: 13px;
    margin-bottom: 4px;
    display: block;
}
.test-wrapper .user-info select, .test-wrapper .user-info input {
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid hsl(0, 0%, 80%);
    color: #8a8a8a;
}
.test-wrapper .select__multi-value__label {
    font-family: 'IRANYekanX';
    font-weight: bold;
    padding-right: 12px;
}
.test-wrapper .basic-multi-select {
    font-size: 13px;
    font-family: 'IRANYEKANX';
    color: #767676;
}
.stepsWrapper .steps li.active .footer-tour {
    margin-top: -20px;
    margin-left: 44px;
}
.test-wrapper .stepsWrapper .steps li.active .footer-tour {
    width: 84%;
    margin: 20px auto 0;
}
.stepsWrapper .footer-tour .swiper-button-disabled {
    visibility: hidden;
    opacity: 0;
}
.stepsWrapper .footer-tour .all-tour {
    color: #a8a8a8;
    font-size: 14px;
    font-weight: 700;
    position: relative;
}
.stepsWrapper .footer-tour .all-tour:hover:after {
    background: #0668e1;
}
.stepsWrapper .footer-tour .tour-func {
    height: 43px;
    line-height: 43px;
    padding: 0 18px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 7px;
    cursor: pointer;
    transition: .3s;
    font-size: 13px;
}
.stepsWrapper .footer-tour .tour-func.next {
    background-color: #0668e1;
    font-weight: 700;
    color: #ffffff;
    box-shadow: 0 8px 7px -8px #0668e1;
    transition: .3s;
    transform: translateX(0);
}
.stepsWrapper .footer-tour .tour-func.next.back {

}
.stepsWrapper .footer-tour .tour-func.next:hover {
    box-shadow: 0 3px 4px -3px #0668e1;
    background-color: #0557bc;
}
.stepsWrapper .footer-tour .tour-func.back {
    color: #b2b8ce;
    font-weight: 500;
    border: 1px solid #e4e6f0;
    margin-left: 4px;
}
.stepsWrapper .footer-tour .tour-func.back:hover {
    border-color: #bcc0cf;
    color: #8a8fa0;
}
.stepsWrapper .progress-tour {
    position: absolute;
    top: 58px;
    right: -6px;
    left: -6px;
}
.stepsWrapper .progress-tour .swiper-pagination-bullets {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.stepsWrapper .progress-tour .swiper-pagination-bullets .swiper-pagination-bullet {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    border-radius: 0;
    height: 1px;
    border-left: solid 6px #ffffff;
    border-right: solid 6px #ffffff;
    background: #0668e1;
    opacity: 1;
    position: relative;
    transition: .4s;
}
.stepsWrapper .progress-tour .swiper-pagination-bullets .swiper-pagination-bullet:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 12px;
    bottom: -1px;
    left: -10px;
    background: url("https://bazino.academy/wp-content/themes/bazino/img/base/main/location.svg") no-repeat center;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
    transform: translateY(-3px) scaleY(2.3);
    transform-origin: top;
    -webkit-animation: location 1s alternate infinite cubic-bezier(0, 1, 0, 1);
    animation: location 1s alternate infinite cubic-bezier(0, 1, 0, 1);
}
.stepsWrapper .progress-tour .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
    visibility: visible;
    opacity: 1;
}
.stepsWrapper .progress-tour .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active + .swiper-pagination-bullet + .swiper-pagination-bullet + .swiper-pagination-bullet, .stepsWrapper .progress-tour .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active + .swiper-pagination-bullet + .swiper-pagination-bullet, .stepsWrapper .progress-tour .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active + .swiper-pagination-bullet {
    background-color: #bcc0cf;
}
@media (max-width: 1024px) {
    .stepsWrapper {
        margin-bottom: 30px;
    }
}
@media (max-width: 370px) {
    .stepsWrapper .footer-tour .swiper-button-disabled {
        visibility: visible;
        opacity: 1;
    }

    .stepsWrapper .swiper-slide .title-tour .title-block {
        width: 100%;
        font-size: 16px;
    }

    .stepsWrapper .swiper-slide .title-tour .desc-block {
        display: none;
    }
}
@media (max-width: 1200px) {
    .stepsWrapper .footer-tour .tour-func {
        padding: 0 11px;
    }

    .stepsWrapper .footer-tour .all-tour {
        white-space: nowrap;
    }
}
@media (max-width: 992px) {
    .stepsWrapper .swiper-slide {
        opacity: 0;
    }

    .trailer i.fi-sr-play-alt {
        line-height: 0;
    }

    .swiper-container-fade .swiper-slide-active {
        opacity: 1;
    }

    .stepsWrapper {
        margin-bottom: 0px;
    }

    .stepsWrapper {
        padding: 28px 5px 20px;
    }
}
@media (max-width: 768px) {
    .stepsWrapper {
        margin-bottom: 20px;
    }
}

@-webkit-keyframes location {
    from {
        transform: translateY(-3px) scaleY(2.3);
    }
    to {
        transform: translateY(-11px) scaleY(1.1);
    }
}
@keyframes location {
    from {
        transform: translateY(-3px) scaleY(2.3);
    }
    to {
        transform: translateY(-11px) scaleY(1.1);
    }
}
.amt-article {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}
.amt-article_textArea {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    padding: 0;
    position: relative;
    justify-content: space-between;
    width: 80%;
}
.amt-article_textArea .arrows a {
    position: inherit;
    background: transparent;
    margin: 0 5px;
}
.amt-article_textArea .arrows i {
    position: inherit;
}
.amt-article_textArea > div:first-of-type {
    width: 510px;
    margin-right: -25px;
}
.amt-article_textArea header {
    display: flex;
    align-items: center;
}
.amt-article_textArea header > div {
    position: relative;
}
.amt-article_textArea header > div > span:first-of-type {
    width: 26px;
    height: 1px;
    background: #d7d6d6;
    display: inline-block;
    transform: rotate(270deg);
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: -43px;
}
.amt-article_textArea header > div > span:last-of-type {
    font-size: 11px;
    transform: rotate(270deg);
    display: inline-block;
    color: #727272;
    width: max-content;
}
.landing-most-popular-card {
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    border: none;
    box-shadow: 1px 5px 15px #0000001c;
    height: 120px;
    margin-top: 30px;
    background: #fff;
}
.landing-most-popular-card .card-img-clippath-wrapper {
    clip-path: url(#most-popular-card-img-bg);
    max-height: 120px;
    width: 38%;
    overflow: hidden;
    display: inline-block;
}
.landing-most-popular-card .card-img-clippath-wrapper .episode-card-img {
    width: 100%;
    min-height: 100%;
    height: auto;
    object-fit: cover;
}
.landing-most-popular-card .episode-card-text-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 5px 16px;
    width: 50%;
    position: relative;
}
.landing-most-popular-card .episode-card-text-container h3 {
    font-family: 'Kalame';
    margin: 0;
    margin-top: 5px;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: ltr;
    padding-left: 15px;
    color: #000;
    text-align: right;
}
.landing-most-popular-card .episode-card-text-container .type {
    position: initial;
    background: transparent;
    color: #848484;
    font-weight: 300;
    display: inline-block;
    font-size: 11px;
}
.landing-most-popular-card .episode-card-text-container .d-flex.align-items-center.justify-content-center {
    text-align: right;
    justify-content: space-between !important;
    margin-top: 4px;
}
.landing-most-popular-card-wrapper {
    flex: 0 0 300px;
    width: 300px;
    margin-left: 15px;
    position: absolute;

}
.landing-most-popular-card-wrapper.ob1 {
    bottom: -427px;
    left: -800px;
    transition: all .5s;
}
.landing-most-popular-card-wrapper.ob1 li {
    width: 95%;
    box-shadow: 1px 1px 73px #e6e6e6;
    border-radius: 6px;
}
.landing-most-popular-card-wrapper.ob1 .cover {
    width: 96%;
    overflow: hidden;
    height: 145px;
    margin: 6px auto;
    border-radius: 20px;
}
.landing-most-popular-card-wrapper.ob1 .cover img {
    width: 100%;
    min-height: 149px;
}
.landing-most-popular-card-wrapper.ob2 {
    bottom: -271px;
    left: -800px;
    transition: all .5s;
}

.landing-most-popular-card-wrapper.fixed {
    left: -15px;

}
.landing-most-popular-card .episode-card-text-container .content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    font-size: 11px;
    margin: 0;
    margin-top: 5px;
    color: #4b4b4b;
}
.landing-most-popular-card-wrapper .price {
    font-family: kalame;
    font-weight: bold;
    font-size: 20px;
}
.amt-article-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home-elements_style_item_card_component__root {
    display: flex;
}
.home-elements_items_block_component__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    overflow: initial;
    padding: 0;
    position: relative;
    margin-left: 15px;
    width: 80%;
}
.home-elements_items_block_component__items > div {
    width: 33%;
}
.home-elements_items_block_component__item {
    border-radius: 10px;
    display: block;
    height: auto;
    overflow: hidden;
    position: relative;
    text-decoration: none;
}
.home-elements_style_item_card_component__preview {
    border-radius: 2px 2px 0 0;
    overflow: hidden;
    position: relative;
    width: 235px;
}
.home-elements_style_item_card_component__preview a {
    display: flex;
}
.home-elements_style_item_card_component__details {
    display: flex;
    padding: 1px 4px 3px;
    justify-content: space-between;
    width: calc(100% - 238px);
    margin-right: 11px;
    text-align: right;
}
.single .post-thumbnail {
    display: block;
    margin: 26px 0;
    max-width: 100%;
    height: auto;
    border-radius: 27px;
}
.shared-item_preview_component__root::before {
    position: absolute;
    background-image: url(../../../public/images/post-eleman.svg);
    background-repeat: no-repeat;
    bottom: -1px;
    right: 0;
    left: 0;
    z-index: 9;
    height: 10px;
    background-size: 139%;
    background-position: bottom;
}
.post__description p::after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, #fff 0, rgba(246, 246, 246, 0));
}
.post__description p {
    color: #737373;
    font-size: 13px;
    text-align: justify;
    overflow: hidden;
    position: relative;
    height: 60px;
    white-space: initial;
    margin-top: 0;
    font-weight: 200;
}
.shared-item_preview_component__image {
    border-radius: 0;
    height: auto;
    width: 100%;
    min-height: 124px;
}
.switcher-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    direction: ltr;
}
.switcher-wrapper .mode {
    padding: 0 6px;
    font-weight: 900;
    font-size: 14px;
    font-family: 'Yekan-B';
}
.switcher-wrapper .mode.light {
    color: #252e48;
}
.switcher-wrapper .mode.dark {
    color: #c7cedb;
}
.switcher-wrapper #switcher {
    width: 53px;
    height: 30px;
    position: relative;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    background: #86afff;
    transition: all 0.2s linear;
    direction: ltr;
    margin: 0 10px 0 0;
    box-shadow: inset -5px 7px 5px #0000001f;
}
.switcher-wrapper .mode.dark {
    background: transparent !important;
}
.switcher-wrapper #switcher .star {
    position: absolute;
    background: #fff;
    clip-path: circle(45% at 60% 60%);
    transition: all 0.2s linear;
}
.switcher-wrapper #switcher .star.star1 {
    width: 6px;
    height: 6px;
    top: 6px;
    left: 70%;
}
.switcher-wrapper #switcher .star.star2 {
    width: 4px;
    height: 4px;
    top: 18px;
    left: 65%;
}
.switcher-wrapper #switcher .star.star3 {
    width: 3px;
    height: 3px;
    top: 7px;
    left: 52%;
    opacity: 0;
}
.switcher-wrapper #switcher .round-btn {
    width: 20px;
    height: 20px;
    clip-path: circle(50% at 50% 50%);
    background: #ffffff;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: all 0.2s linear;
}
.switcher-wrapper #switcher .round-btn .moon-mode {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #311B92;
    position: absolute;
    left: -4px;
    top: 0;
    opacity: 0;
    transition: all 0.1s linear;
}
.night .mode.light {
    color: #3f3f3f;
}
.night .mode.dark {
    color: #ffffff;
}
.night #switcher {
    background: #311B92;
}
.night #switcher .star {
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}
.night #switcher .star1 {
    opacity: 0.6;
    left: 16% !important;
    animation: blink 1.8s linear infinite;
}
.night #switcher .star2 {
    left: 36% !important;
    animation: blink 2.4s linear infinite;
}
.night #switcher .star3 {
    animation: blink 1.2s linear infinite;
}
.night #switcher .round-btn {
    transform: translateX(21px) rotate(20deg);
    border-color: #311B92;
}
.night #switcher .round-btn .moon-mode {
    opacity: 1;
}
@keyframes blink {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}
.home-elements_style_item_card_component__itemType {
    background-color: rgba(72, 72, 72, .6);
    border-radius: 4px;
    line-height: 1em;
    max-width: calc(100% - 8px * 2);
    padding: 6px 8px;
    position: absolute;
    right: 13px;
    text-align: right;
    top: 13px;
    color: #fff;
    font-size: 10px;
}
.home-elements_style_item_card_component__content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
}
.home-elements_style_item_card_component__cta {
    display: block;
    margin: 0 0 0 4px;
}
.home-elements_style_item_card_component__name {
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    margin: 6px 0 2px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-family: 'IRANYEKANX';
    margin-bottom: 14px;
}
.top-lists .home-elements_style_item_card_component__name {
    text-align: right;
    margin-bottom: 5px;
}
.home-elements_style_item_card_component__cta {
    border: 1px solid #e64b5d;
    border-radius: 6px;
    color: #e64b5d;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 33px;
    padding: 0 15px;
}
.amt-article .home-elements_items_block_component__title {
    font-family: 'IRANYEKANX';
    font-size: 17px;
    margin-bottom: 12px;
    text-align: right;
    margin-top: 0;
    font-weight: 1000;
}
.amt-article .home-elements_items_block_component__title .home-elements_items_block_component__leftButton {
    vertical-align: text-top;
    margin-right: 10px;
    display: inline-block;
}
.amt-article .home-elements_items_block_component__subtitle {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 15px;
    color: #747474;
    font-weight: 100;
    text-align: right;
}
.shared-cta_button_component__root.shared-cta_button_component__large {
    border-radius: 4px;
    font-size: 11px;
    margin: auto;
    padding: 7px 9px;
    border: 1px solid #000;
    font-weight: bold;
    font-family: Yekan;
    vertical-align: middle;
    color: #000;
}
.amt-article .back {
    position: absolute;
    right: 34px;
    top: 0;
    width: 376px;
    z-index: -1;
}
.back-main {
    background: #f7f7f7;
    position: absolute;
    height: 213px;
    width: 100%;
    z-index: -3;
    top: 32%;
    border-radius: 28px;
}
.home-elements_style_item_card_component__authorAndCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home-elements_style_item_card_component__authorAndCategory > div {
    display: flex;
    align-items: center;
}
.home-elements_style_item_card_component__authorAndCategory img {
    width: 25px;
}
.home-elements_style_item_card_component__authorAndCategory span {
    font-weight: 200;
    font-size: 12px;
    margin-right: 3px;
}
.discount-box {
    border-radius: 18px;
    padding-bottom: 15px;
}
.assets-box-2 {
    background-color: #ffffff;
    width: 100%;
    height: 385px;
    box-shadow: 1px 1px 73px #c6c6c670;
    position: absolute;
    top: 1%;
    right: 0;
    left: 0;
    -webkit-transform: skewY(-4deg);
    -moz-transform: skewY(-4deg);
    transform: skewY(-4deg);
    -moz-border-radius: 40px;
    border-radius: 30px;
    opacity: 1;
    z-index: -1;
    margin: 0 auto;
}
.assets-box-3 {
    background-color: #f1f1f1;
    width: 99%;
    height: 103%;
    position: absolute;
    top: -1%;
    right: 0;
    left: 0;
    -webkit-transform: skewY(-4deg);
    -moz-transform: skewY(-4deg);
    transform: skewY(4deg);
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 72px;
    opacity: 1;
    z-index: -2;
    margin: 0 auto;
}
.discount-box .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 20px 5px;
}
.discount-box .head h2 {
    text-shadow: 0 2px 3px rgb(0 0 0 / 35%);
    font-size: 19px;
    margin: 0;
}
.discount-box .head i {
    font-family: yadakpars !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 27px;
    margin-left: 0px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
}
.yp-offer:before {
    content: "\e91f";
}
.discount-box .head ul {
    padding: 0;
}
.discount-box .head ul li {
    margin: 0 1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #8d8d8d80;
    border-radius: 100%;
    transition: all .5s;
    cursor: pointer;
}
.discount-box .head ul li.active {
    width: 21px;
    border-radius: 10px;
    background: #7e7e7e;
}
.discount-box .cover {
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    height: 156px;
    border-radius: 12px;
}
.discount-box .cover img {
    width: 100%;
    height: auto;
    min-height: 156px;
}
.discount-box .footer {
    padding: 0 20px;
}
.discount-box .footer h3 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 900;
    margin-top: 13px;
    color: #000;
}
.rate-modal > p {
    text-align: center;
    font-weight: bold;
}
.rate-modal i {
    font-size: 20px;
    margin: 0 6px;
    cursor: pointer;
}
.rate-modal div div {
    text-align: center;
    direction: ltr;
}
.rate-modal .rate-num {
    font-family: 'Kalame';
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
    direction: ltr;
    color: #5e5e5e;
    margin-top: 10px;
}
.rate-modal .rate-num span {
    font-family: 'Kalame';
    font-weight: 700;
    font-size: 39px;
    color: #444;
    line-height: 1;
}
.rate-modal button {
    float: none;
    margin: 0 auto 14px;
    display: block;
    font-weight: 900 !important;
    cursor: pointer;
}
.rate-modal > span {
    font-size: 12px;
    line-height: 2;
    display: block;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 300;
    color: #898686;
}
.no-buy {
    background: #FFF3E0;
    font-size: 12px;
    padding: 7px 8px;
    text-align: center;
    font-weight: bold;
    font-family: 'IRANYekanX';
}
.discount-box .footer > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.discount-box .footer > div .price {
    text-align: center;
}
.discount-box .footer > div .price > div {
    display: inline-block;
}
.discount-box .footer > div .price > div:first-of-type {
    margin-left: 5px;
}
.discount-box .footer > div .price > div:last-of-type {
    vertical-align: super;
}
.discount-box .footer > div .price span:first-of-type {
    display: block;
    font-family: Vazirmatn FD;
    font-size: 23px;
    font-weight: bold;
    line-height: 1;
    margin-top: 6px;
}
.discount-box .footer > div .price del {
    font-family: Vazirmatn FD;
    color: #777;
}
.discount-box .footer > div ul {
    padding: 0;
    margin: 0;
}
.discount-box .footer p {
    display: -webkit-box;
    font-size: 13px;
    opacity: 1;
    font-weight: 200 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 7px;
    margin-bottom: 3px;
}
.discount-box .footer > div ul li {
    display: inline-block;
    margin-left: 13px;
    font-size: 13px;
    font-family: Yekan-B;
}
.discount-box .footer > div ul li span {
    color: #000000;
    font-family: 'Kalame';
    line-height: 0;
    font-size: 26px;
    font-weight: bold;
    margin: 0 7px;
}
.why-tpay {
    position: relative;
    margin-top: 50px;
}
.why-tpay .container {
    width: 80%;
    margin: 0 auto;
}
.why-tpay__title {
    color: #0d1834;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 8px;
    white-space: nowrap;
    font-family: Virgoul;
    position: relative;
}
.why-tpay__main {
    display: flex;
    overflow: visible;
    margin-top: 28px;
}
.why-tpay__item {
    position: relative;
    display: flex;
    align-items: center;
    box-shadow: 0 20px 32px rgba(29, 46, 54, 0.08);
    background-color: #ffffff;
    z-index: 3;
    transition: .3s;
    border-radius: 10px;
    margin-left: 20px;
}
.why-tpay__title-sec {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13px 39px 13px 41px;
    transition: .3s;
}
.why-tpay__title-sec:before {
    content: "";
    position: absolute;
    top: 35px;
    bottom: 26px;
    left: 29px;
    width: 0px;
    background-color: #e1e4eb;
    transition: .3s;
}
.why-tpay__item-img {
    margin-bottom: 0;
}
.why-tpay__item-img img {
    width: 60px;
}
.why-tpay__item-title {
    color: #3b414d;
    font-size: 17px;
    font-weight: 700;
    line-height: 15px;
    white-space: nowrap;
}
.why-tpay__item-title a {
    color: #3b414d;
}
.why-tpay__item-short-description {
    color: #929294;
    font-size: 13px;
    font-weight: 400;
    width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.why-tpay__item-btn {
    background-color: #c59425;
    box-shadow: 0 9px 17px -5px #c59425;
    border-radius: 41%;
    padding: 3px 9px 1px 9px;
    position: absolute;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
}
.assets-box-4 {
    background-color: #ffffff;
    width: 100%;
    height: 96%;
    box-shadow: 1px 1px 73px #c6c6c670;
    position: absolute;
    top: 11%;
    right: -45px;
    left: 0;
    -webkit-transform: skewY(-4deg);
    -moz-transform: skewY(-4deg);
    transform: skewY(0deg);
    -moz-border-radius: 40px;
    border-radius: 30px;
    opacity: 1;
    z-index: -1;
    margin: 0 auto;
}
.single .content-wrapper .head.fixed .assets-box-4 {
    top: 20px;
}
.assets-box-5 {
    width: 87%;
    height: 75%;
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    transform: skewY(354deg);
    border-radius: 72px;
    opacity: 1;
    z-index: -2;
    margin: 0 auto;
    box-shadow: inset 1px 1px 14px #dadada;
}
.single .content-wrapper .head.fixed .assets-box-5 {
    display: none;
}
.assets-box-11 {
    background-color: #f1f1f1;
    width: 93%;
    height: 93%;
    position: absolute;
    top: 35px;
    right: 0;
    left: 0;
    transform: skewY(6deg);
    border-radius: 72px;
    opacity: 1;
    z-index: -2;
    margin: 0 auto;
}
.why-tpay__item-btn i {
    font-size: 6px;
    color: #ffffff;
}
.why-tpay__item-description {
    color: #3b414d;
    font-weight: 400;
    line-height: 30px;
    text-align: justify;
    margin-left: 35px;
    transition: .3s transform;
    font-size: 0;
    max-width: 400px;
    transform: scale(0);
    position: absolute;
    left: 0;
}
.why-tpay .active .why-tpay__title-sec {
    padding: 13px 39px 13px 54px;
}
.why-tpay .active .why-tpay__title-sec:before {
    width: 1px;
}
.why-tpay .active .why-tpay__item-description {
    transform: scale(1);
    position: relative;
    font-size: 13px;
}
.why-tpay .active .why-tpay__item-btn {
    bottom: -17px;
    visibility: visible;
    opacity: 1;
}
footer.footer-main {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    position: relative;
}
footer .footer-top {
    width: 80%;
    margin: 15px auto 0;
    display: flex;
    justify-content: space-between;
}
footer .footer-top > div {

}
footer .footer-top > div h3 {
    margin-top: 0;
    font-size: 15px;
}
footer .footer-top > div h3 i {
    font-size: 18px;
    vertical-align: middle;
    margin-left: 4px;
}
.swal2-icon-error {
    background: #E53935 !important;
    color: #fff !important;
}
footer .footer-top .r-1 {
    width: 400px;
}
footer .footer-top .r-1 p {
    font-size: 13px;
    text-align: justify;
    line-height: 2;
    color: #535353;
}
footer .footer-top .r-2 ul li a {
    color: #535353;
}
footer .footer-top .r-2 {
    margin-right: 48px;
}
footer .footer-top .r-2 ul {
    padding: 0;
    list-style: none;
}
footer .footer-top .r-2 ul li {
    width: 49%;
    display: inline-block;
    font-size: 13px;
    margin-bottom: 17px;
}
footer .footer-top .r-3 {

}
footer .footer-top .r-3 p {
    font-size: 12px;
    color: #535353;
}
footer .footer-top .r-3 form {
    background: #f4f4f4a3;
    padding: 0 12px;
    border-radius: 3px;
}
footer .footer-top .r-3 form i {
    color: #d9d9d9;
    margin-left: 5px;
    font-size: 18px;
    vertical-align: middle;
}
footer .footer-top .r-3 form input {
    height: 40px;
    width: 78%;
    border: none;
    box-sizing: border-box;
    border-radius: 7px;
    font-weight: 300;
    font-size: 12px;
    font-family: kalame;
    background: transparent;
}
footer .footer-top .r-3 form a {
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #757474;
    padding: 1px 10px;
    border-radius: 4px;
    color: #757474;
}
footer .footer-top .r-3 form input::placeholder {
    font-size: 15px;
    font-weight: 900;
    color: #d6d6d6;
}
footer .footer-top .r-4 {

}
footer .footer-bottom {
    width: 80%;
    margin: 0 auto 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 17px 6px;
    border-radius: 6px;
    box-sizing: border-box;
}
footer .footer-bottom div p {
    margin: 0;
    font-size: 12px;
    font-weight: 200;
    color: #9e9e9e;
}
footer .footer-bottom div ul {
    padding: 0;
    list-style: none;
    margin: 0;
    line-height: 1;
}
footer .footer-bottom div ul li {
    display: inline-block;
    margin: 0 4px;
    font-size: 21px;
}
footer .footer-bottom div ul li i {
    color: #cecece;
}
.content {
    position: relative;
}
.single {
    width: 80%;
    margin: 0 auto;
    position: relative;
}
.single .back-single {
    position: absolute;
    width: 100%;
    height: 300px;
    background: #787676;
    z-index: -1;
}
.single .sidebar {
    position: absolute;
    width: 300px;
    background: #fff;
    box-shadow: 1px 1px 30px #9593932b;
    top: 15px;
    left: 1%;
    border-radius: 30px;
    overflow: hidden;
    z-index: 1;
}
.course-video .single .sidebar {
    position: unset;
}
.single .sidebar.c-l {
    border-radius: 1px;
    top: 27px;
}
.single .sidebar.fixed {
    position: fixed;
    top: 15px;
    left: 10.8%;
}
.single .sidebar.abs {
    position: absolute;
    bottom: -50px;
    left: 13px;
    top: auto;
}
.single .sidebar .trailer {
    position: relative;
}
.single .sidebar .trailer::before {
    content: "";
    position: absolute;
    background-image: url(../../../public/images/post-eleman.svg);
    background-repeat: no-repeat;
    bottom: 8px;
    right: 0;
    left: 0;
    z-index: 9;
    height: 45px;
    background-size: 135%;
    background-position: bottom;
}
.single .sidebar .sidebar-info {
    padding: 0 25px;
}
.single .sidebar .sidebar-info .free {
    font-family: 'IRANYEKANX';
    font-weight: 900;
    background: #00C853;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    padding: 3px 7px;
    font-size: 15px;
    width: max-content;
    margin: 10px auto;
}
.single .content-wrapper .head .left-head .free {
    font-family: 'IRANYEKANX';
    font-weight: 900;
    background: #00C853;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    padding: 8px 63px;
    font-size: 15px;
    width: max-content;
    margin: 3px auto;
}
.bottom-sidebar .free {
    font-family: 'IRANYEKANX';
    font-weight: 900;
    background: #00C853;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    padding: 8px 41px;
    font-size: 15px;
    width: max-content;
    margin: 2px auto;
}
.single .head-info {
    display: flex;
    align-items: center;
}
.single .course-menu {
    display: flex;
    list-style: none;
    margin: 5px 0;
}
.single .course-menu.fixed {
    background: #fff;
    border-radius: 0 0 15px 15px;
    box-shadow: 1px 1px 50px #e0e0e0;
    display: none;
    justify-content: center;
    padding: 14px 37px;
    position: fixed;
    right: 18%;
    top: -11px;
    width: calc(71% - 315px);
    z-index: 10;
}
.single .course-menu.fixed li {
    margin-left: 4%;
}
.single .course-menu.fixed.active {
    display: flex;
}
.single .course-menu li {
    margin-left: 12px;
    padding: 5px 15px;
    border-radius: 9px;
}
.single .course-menu li i {
    font-size: 19px;
    vertical-align: middle;
    margin-left: 8px;
}
.single .course-menu li a {
    font-family: 'IRANYekanX';
    font-size: 13px;
}
.single .course-menu li.active {
    background: #fcbf17;
    font-weight: 900;
}
.single .head-info .trailer {
    width: 175px;
    height: 104px;
    margin-left: 20px;
    margin-top: 13px;
    overflow: hidden;
    position: relative;
    border-radius: 18px;
    display: none;
}
.single .head-info .trailer img {
    width: 100%;
    height: auto;
    min-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}
.single .price {
    justify-content: flex-end;
    display: flex;
    align-items: center;
}
.single .price .discount {
    background: #ef394e;
    color: #fff;
    font-weight: 900;
    padding: 1px 5px;
    border-radius: 14px;
    font-size: 16px;
    font-family: 'Vazirmatn FD';
    margin-left: 14px;
}
.single .price {
    font-family: 'Vazirmatn FD';
    font-weight: bold;
    font-size: 27px;
    line-height: 1;
    margin-left: 7px;
}
.single .sidebar .discount-time {
    font-size: 13px;
    color: #FF1744;
    font-weight: bold;
}
.single .sidebar .de {
    font-size: 11px;
    font-weight: 200;
    margin: 3px 0 11px;
    text-align: center;
}
.single .sidebar .de span {
    font-weight: 500;
    font-size: 13px;
    display: inline-block;
    padding: 0 3px;
}
.single .sidebar .discount-time i {
    vertical-align: sub;
}
.single .sidebar .discount-time span {
    display: inline-block;
    margin-right: 3px;
    font-family: 'Yekan-B';
}
.single .buy-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sidebar .buy-btn {
    margin-bottom: 20px;
}
.single .buy-btn a:first-of-type {
    color: #fff;
    background: #651FFF;
    padding: 7px 12px;
    border-radius: 9px;
    font-weight: bold;
    font-size: 11px;
    height: 40px;
    font-family: 'IRANYekanX';
    width: 54%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 8px 7px -8px #7335fc;
}
.see-course {
    color: #a0a0a0;
    padding: 9px 12px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 12px;
    font-family: 'IRANYekanX';
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px auto 0;
    cursor: pointer;
    border: 1px solid #cfcfcf;
}
.see-course i {
    font-size: 15px;
    vertical-align: middle;
    position: relative;
    margin-left: 8px;
    line-height: 0;
}
.single .buy-btn a:hover {
    opacity: .7;
}
.single .buy-btn a:last-of-type {
    display: flex;
    text-align: center;
    font-size: 11px;
    padding: 10px;
    height: 40px;
    color: #8d8d8d !important;
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid #c5c5c5 !important;
    width: 42%;
    font-family: 'IRANYekanX';
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}
.single .buy-btn a:last-of-type i {
    position: absolute;
    font-size: 40px !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 3px;
    opacity: .1;
    transform: rotate(18deg);
}
.swal2-title {
    font-size: 19px !important;
    font-weight: 900 !important;
}
.swal2-html-container {
    font-size: 14px !important;
}
.swal2-styled.swal2-confirm, .swal2-styled.swal2-deny {
    font-weight: 900 !important;
    font-size: 13px !important;
    font-family: 'IRANYekanX';
    padding: 9px 31px !important;
}
.swal2-title {
    font-family: 'IRANYEKANX';
}
.swal2-styled.swal2-deny {
    background-color: #bebebe !important;
}
.swal2-styled.swal2-confirm {
    background-color: #00C853 !important;
}
.swal2-styled.swal2-deny {
    padding: 9px 16px !important;
}
.no-video {
    margin: 0;
    padding: 18px 16px 10px;
    text-align: center;
    font-size: 13px;
    color: #dbdbdb;
    font-weight: 900;
    font-family: 'IRANYekanX';
}
.no-video i {
    vertical-align: middle;
    margin-left: 5px;
    font-size: 24px;
}
.swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
}
.single .buy-btn a i {
    font-size: 16px;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 5px;
}
.single .sidebar .other-info {
    margin-top: 8px;
}
.single .sidebar .other-info h4 {
    margin-bottom: 2px;
    margin-top: 8px;
    font-size: 15px;
    font-family: IRANYEKANX;
    padding: 15px;
    text-align: center;
    border-top: 1px solid #eee;
}
.single .sidebar .other-info ul {
    margin-top: 4px;
    list-style: none;
    padding: 0px 31px 12px;
    margin-bottom: 0;
}
.single .sidebar .other-info ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.single .sidebar .other-info ul li i {
    vertical-align: sub;
}
.single .sidebar .other-info ul li div span {
    display: inline-block;
    font-size: 12px;
    margin-right: 8px;
    color: #000;
    font-weight: 400;
}
.single .sidebar .other-info ul li span {
    display: inline-block;
    font-size: 12px;
    margin-right: 8px;
    font-weight: 200;
}
.single .sidebar .trailer img {
    width: 100%;
}
.show-all {
    font-family: 'IRANYEKANX';
    font-size: 11px;
    display: inline-block;
    color: #909090;
    font-weight: 500;
    padding: 4px 6px;
    border-radius: 2px;
    vertical-align: 1px;
    margin-right: 8px;
    z-index: 1;
    position: relative;
}
.trailer .popup-unlock {
    width: 900px;
}
.trailer .popup-unlock .unlock__body {
    padding: 0;
}
.trailer i.fi-sr-play-alt {
    position: absolute;
    z-index: 1;
    line-height: 1;
    margin: 0 auto;
    left: 0;
    right: 0;
    font-size: 49px;
    color: #fff;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    opacity: .9;
    cursor: pointer;
}
.single .sidebar .trailer i:hover {
    opacity: .4;
}
.single .content-wrapper {
    width: calc(100% - 315px);
}
.single .content-wrapper .head {
    padding-top: 50px;
    position: relative;
    margin-top: 30px;
}
.single .content-wrapper .head.fixed {
    position: fixed;
    top: -231px;
    width: 61%;
    z-index: 9;
}
.single .content-wrapper .head .rating {
    position: absolute;
    left: 56px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
html {
    scroll-behavior: smooth;
}
.single .content-wrapper .head .rating i {
    font-size: 30px;
    line-height: 0;
    margin-right: 7px;
}
.single .content-wrapper .head .rating div {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.single .content-wrapper .head .rating div span:first-of-type {
    font-family: 'Kalame';
    font-weight: bold;
    font-size: 32px;
}
.single .content-wrapper .head .rating div span:last-of-type {
    font-size: 11px;
    color: #6d6d6d;
}
.single .content-wrapper .breadcrumb a {
    color: #868686;
    vertical-align: super;
    font-size: 13px;
    display: inline-block;
}
.single .content-wrapper .breadcrumb i {
    color: #868686;
    margin: 0 12px;
}
.single .content-wrapper .head h1 {
    font-weight: 900;
    font-family: 'IRANYekanX';
    font-size: 22px;
}
.single .content-wrapper .head h3 {
    font-size: 12px;
    line-height: 2;
    font-weight: 300;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 96%;
    word-wrap: break-word !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.single .content-wrapper .head .rate {
    display: flex;
    align-items: center;
}
.single .content-wrapper .head .rate .star {
    margin-left: 6px;
}
.single .content-wrapper .head .rate .star span {
    font-weight: 500;
    font-size: 20px;
    margin-left: 8px;
    line-height: 0;
    color: #818181;
}
.single .content-wrapper .head .rate .star span span {
    font-size: 13px;
    vertical-align: -5px;
    margin-left: 0;
    font-weight: 200;
}

.single .content-wrapper .head .rate .people span {
    font-size: 11px;
    color: #636363;
}
.dashboard-content {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.title-section {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    margin-top: 15px;
}
.title-section i {
    font-size: 24px;
    margin-left: 10px;
}
.title-section h3 {
    margin: 0;
    font-size: 15px;
    font-family: 'IRANYEKANX';
}
.title-section h3 .subtitle {
    display: inline-block;
    background: #00C853;
    color: #fff;
    margin-right: 10px;
    padding: 0 5px;
    border-radius: 2px;
    font-weight: 900;
    font-size: 13px;
}
.title-section h3 .double {
    display: inline-block;
    background: #2979FF;
    color: #fff;
    margin-right: 10px;
    padding: 0 5px;
    border-radius: 2px;
    font-weight: 900;
    font-size: 13px;
}
.title-section h4 {
    margin: 0;
    font-weight: 200;
    font-size: 11px;
    color: #6f6f6f;
}
.test-wrapper {
    width: 29rem;
    margin: 48px auto;
    text-align: center;
    border-radius: 28px;
}
.test-wrapper .main {
    box-shadow: 1px 1px 89px #e2e2e2;
    border-radius: 20px;
    padding: 20px;
    margin-top: 30px;
}
.test-wrapper .main .stepsWrapper {
    border: none;
}
.test-wrapper > h3 {
    font-family: virgoul;
    text-align: right;
    font-size: 23px;
    margin: 0 40px;
    color: #333;
}
.test-wrapper .test-link {
    display: block;
    padding: 10px;
    border: 2px dashed #e1e1e1;
    text-align: center;
    width: 63%;
    margin: 0 auto 20px;
    border-radius: 5px;
    font-weight: 900;
    font-family: 'Yekan-B';
    color: #8c8c8c;
}
.test-closed {
    font-weight: bold;
    padding: 15px;
    color: #cbcbcb;
    font-size: 17px;
    font-family: Yekan-B;
}
.success-message {
    background: #E8F5E9;
    padding: 15px;
    font-weight: bold;
    font-family: 'IRANYEKANX';
    color: #474747;
}
.test-closed i {
    font-size: 23px;
    vertical-align: middle;
    margin-left: 10px;
}
.test-wrapper .sub-file {
    border: 2px dashed #ebe8e8ee;
    width: 70%;
    display: block;
    text-align: center;
    padding: 21px;
    border-radius: 7px;
    margin: 10px auto;
    color: #9d9c9c;
    font-weight: 900;
    cursor: pointer;
}
.test-wrapper .sub-file input {
    display: none;
}
.test-wrapper .sub-file span {
    font-family: Yekan-B;
}
.test-wrapper .test-link i {
    font-size: 28px;
    vertical-align: middle;
    margin-left: 10px;
    opacity: .5;
}
.single .content-wrapper .body {
    margin-top: 40px;
    margin-left: 20px;
}
.single .content-wrapper .body .inner {
    display: flex;
    justify-content: space-between;
}
.single .content-wrapper .body .inner > div:nth-child(2) {
    width: 13%;
}
.single .content-wrapper .body .inner > div:nth-child(2) h5 {
    text-align: center;
    font-weight: 300;
    color: #5e5e5e;
    margin-top: 13px;
}
.single .content-wrapper .body .inner > div:nth-child(2) ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 10px 11px 0 10px;
    border-radius: 3px;
}
.single .content-wrapper .body .inner > div:nth-child(2) ul li {
    font-size: 19px;
    cursor: pointer;
    position: relative;
}
.single .content-wrapper .body .inner > div:nth-child(2) ul li.like-share span {
    font-family: 'Kalame';
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    text-align: center;
}
.buy-icon {
    position: relative;
    cursor: pointer;
}
.cart-page {
    width: 80%;
    margin: 0 auto;
    margin-top: 40px;
}
.cart-page > div {
    display: flex;
    justify-content: space-between;
}
.cart-page .carts {
    width: 73%;
}
.cart-page > h3 {
    font-weight: 900;
    font-size: 23px;
    margin-bottom: 0;
    font-family: IRANYEKANX;
}
.cart-page > h3 span {
    font-size: 12px;
    font-weight: 200;
    margin-right: 7px;
}
.cart-page > span {
    font-size: 12px;
    color: #989898;
    margin-bottom: 25px;
    display: inline-block;
}
.cart-page .carts ul .price {
    text-align: left;
    margin-top: 16px;
}
.cart-page .carts ul > li .price > span {
    font-family: 'Kalame';
    font-size: 31px;
    font-weight: 700;
    line-height: 0;
    vertical-align: super;
    margin-left: 10px;
}
.no-item {
    text-align: center;
    padding: 10px;
    font-weight: 900;
    color: #d6d6d6;
    font-size: 14px;
}
.no-item-b {
    text-align: center;
    padding: 0;
    font-weight: 900;
    color: #d6d6d6;
    font-size: 16px;
    margin-top: 2px;
    font-family: 'IRANYEKANX';
}
g[transform='matrix(1,0,0,1,702.2779541015625,329.59698486328125)'], g[transform='matrix(1,0,0,1,389.9049987792969,45.448001861572266)'] {
    display: none !important;
}
.no-item-b i {
    font-size: 23px;
    vertical-align: middle;
    margin-left: 10px;
}
.cart-page .carts > ul {
    list-style: none;
    box-shadow: 1px 1px 73px #c6c6c66e;
    border-radius: 4px;
    overflow: hidden;
    margin: 0;
    padding: 20px 0 20px 44px;
}
.cart-page .carts > ul .no-cart {
    text-align: center;
    margin: 0;
    color: #6d6d6d;
    font-size: 14px;
}
.cart-page .carts > ul .no-cart i {
    vertical-align: middle;
    margin-left: 6px;
}
.cart-page .carts > ul > li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    width: 100%;
    justify-content: space-between;
}
.cart-page .carts > ul > li:last-of-type {
    margin-bottom: 0;
}
.cart-page .carts > ul > li .cover {
    width: 120px;
    height: 90px;
    overflow: hidden;
    border-radius: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 30px #0000002e;
}
.factor-lists .carts > ul > li .cover {
    width: 110px;
    height: 71px;
}
.cart-page .carts > ul > li .cover img {
    width: fit-content;
    height: 100%;
}
.cart-page .carts > ul > li .price {
    font-size: 13px;
    font-weight: 200;
}
.cart-page .carts > ul > li .price span {
    font-family: 'Vazirmatn FD';
    font-size: 23px;
    vertical-align: middle;
    margin-left: 3px;
}
.cart-page .carts > ul > li > div {
    display: flex;
}
.cart-page .carts > ul > li > div:last-of-type {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.cart-page .carts > ul > li span.actions {
    margin-top: 20px;
}
.cart-page .carts > ul > li span.actions a {
    background: #FF1744;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 4px 9px;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    font-size: 11px;
    font-family: 'IRANYekanX';
    color: #fff;
    box-shadow: 1px 2px 3px #ef9a9a;
    font-weight: 900;
}
.bookmark-table tr a.remove {
    background: #FF1744;
    display: inline-block;
    text-align: center;
    padding: 7px 12px;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    font-size: 11px;
    font-family: 'IRANYekanX';
    color: #fff;
    box-shadow: 1px 2px 3px #ef9a9a;
    font-weight: 900;
}
.cart-page .carts > ul > li > div:last-of-type span.actions a i {
    vertical-align: middle;
    margin-left: 5px;
}
.cart-page .carts > ul > li > div > div {
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.factor-lists .carts > ul > li > div > div {
    margin-right: 0 !important;
}
.factor-lists .carts > ul > li > div > div:last-of-type {
    margin-right: 20px !important;
    text-align: right
}
.cart-page .carts > ul > li > div > div .sub-header {
    font-size: 12px;
    color: #676767;
}
.submit-code {
    position: absolute;
    left: 8px;
    border: 1px solid #c6c4c4;
    padding: 3px 8px;
    border-radius: 5px;
    top: 33%;
    font-weight: 500;
    color: #7f7f7f;
    transform: translateY(-50%);
    font-size: 11px;
    box-shadow: 1px 1px 10px #fbfbfb;
    cursor: pointer;
    background: #fff;
}
.discount-percent {
    position: absolute;
    left: 52px;
    padding: 3px 8px;
    top: 33%;
    font-weight: 700 !important;
    color: #808080 !important;
    transform: translateY(-50%);
}
.cart-page .cart-btn {
    background: #0385fe;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    font-size: 13px;
    color: #fff;
    box-shadow: 1px 2px 3px #a8bfff;
}
.cart-page .cart-btn-continue {
    border: 1px solid #bdbdbd;
    width: 100%;
    display: inline-block;
    margin-top: 10px;
    text-align: center;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 13px;
    box-shadow: 1px 2px 3px #eee;
    color: #838383;
    font-weight: bold;
}
.cart-page .results {
    box-shadow: 1px 1px 73px #c6c6c66e;
    padding: 12px;
    height: max-content;
    width: 23%;
}
.vjs-theme-sea .vjs-control-bar {
    direction: ltr;
}
.course-video {
    width: 80%;
    margin: 20px auto 0;
    display: flex;
    border-radius: 4px;
}
.title-video {
    margin: 0;
    font-size: 19px;
    font-weight: 900;
    margin-bottom: 10px;
    margin-top: 5px;
}
.course-video .video-header {
    margin: 25px 5px 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.course-video .video-header > div {
}
.course-video .download-video {
    color: #fff !important;
    background: #0385fe;
    padding: 10px 12px;
    border-radius: 7px;
    box-shadow: 1px 2px 3px #a8bfff;
    font-weight: 900;
    font-size: 12px !important;
    display: inline-block;
    width: max-content;
    text-align: center;
    font-family: 'IRANYekanX';
}
.course-video .download-video i {
    font-size: 16px;
    vertical-align: middle;
    margin-left: 8px;
}
.course-video .video-header > div div {

}
.course-video .video-header > div div > span {
    margin-left: 14px;
    font-size: 12px;
    color: #827e7e;
    font-weight: 200;
}
.course-video .video-header > div div a {

}
.course-video .video-header > div div a i {
    vertical-align: middle;
    margin-left: 8px;
    color: #837f7f;
    font-size: 19px;
}
.course-video .video-header a {
    font-size: 14px;
    color: #9b9b9b;
    margin-bottom: 4px;
    display: inline-block;
}
.course-video .video-header a i {
    vertical-align: middle;
    margin-left: 8px;
}
.course-video > div:first-of-type {
    width: calc(100% - 370px);
}
.course-video > div:last-of-type {
    width: 330px;
    margin-right: 3%;
    padding-top: 26px;
}
.course-video > div .course-progress {
    margin-bottom: 13px;
    display: inline-block;
    width: 100%;
}
.course-video > div .course-progress span {
    font-size: 13px;
    color: #606060;
}
.course-video > div .course-progress span span {
    font-family: 'Kalame';
    font-weight: bold;
    font-size: 23px;
}
.course-video > div .course-progress div {
    height: 5px;
    width: 100%;
    background: #cccccc;
    border-radius: 100px;
    margin-top: 10px;
}
.course-video > div ul.course-lists {
    padding: 0;
    list-style: none;
    margin: 0;
}
.course-video > div ul.course-lists > li > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    font-weight: bold;
    cursor: pointer;
}
.course-video > div ul.course-lists > li > div span {
    font-family: Yekan;
}
.course-video > div ul.course-lists > li > div i {
    transition: all .5s;
}
.course-video > div ul.course-lists > li.active > div i {
    transform: rotate(180deg);
}
.course-video > div ul.course-lists > li > ul {
    padding: 0;
    list-style: none;
    background: #fff;
}
.course-video > div ul.course-lists > li > ul li {
    padding: 10px 20px;
    font-size: 13px;
    font-weight: 400;
}
.course-video > div ul.course-lists > li > ul li a {
    color: #000;
    display: flex;
    align-items: center;
}
.course-video > div ul.course-lists > li > ul li div i {
    font-size: 23px;
    line-height: 0;
    vertical-align: middle;
}
.course-video > div ul.course-lists > li > ul li:hover, .course-video > div ul.course-lists > li > ul li.active {
    background: #f7f7f7;
    cursor: pointer;
}
.course-video > div ul.course-lists > li > ul li div {
    font-size: 12px;
    font-weight: 200;
    color: #7f7f7f;
    margin-right: 7px;
}
.course-video h3 {
    font-weight: 500;
    margin: 0;
    font-family: Yekan-b;
    font-size: 12px;
}
.course-video h3 a {
    color: #263238;
    margin-right: 6px;
    font-size: 14px;
    font-family: 'Yekan-B';
    font-weight: 900;
}
.course-video .share {
    color: #fff;
    background: #737373;
    padding: 10px 12px;
    border-radius: 7px;
    box-shadow: 1px 2px 3px #c1c1c1;
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
    width: max-content;
    text-align: center;
    margin-right: 8px;
}
.course-video .share i {
    font-size: 16px;
    vertical-align: middle;
    margin-left: 8px;
}
.course-video h4 {
    font-weight: 500;
    color: #000;
    font-family: 'Virgoul';
    margin: 0;
    font-size: 18px;
}
.course-video p {
    text-align: justify;
    margin: 10px auto 20px;
    font-size: 13px;
    font-weight: 300;
    color: #808080;
    padding: 0 10px;
}
.video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3, .video-js.vjs-9-16, .video-js.vjs-1-1 {
    width: 100% !important;
    height: 500px !important;
    padding-top: 0 !important;
    margin: 0 auto;
}
.video-js .vjs-progress-control .vjs-progress-holder {
    direction: ltr;
}
.cart-page .results .discount-code {
    padding: 0 0px;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
    position: relative;
}
.cart-page .results .discount-code input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #c3c3c3;
}
.cart-page .results .discount-code span {
    font-size: 11px;
    font-weight: 200;
    color: #000;
}
.cart-page .results ul {
    list-style: none;
    padding: 0 17px;
    font-size: 13px;
}
.cart-page .results ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.cart-page .results ul li span:first-of-type {
    font-weight: 200;
}
.cart-page .results ul li.line {
    border-bottom: 1px solid #efefef;
}
.cart-page .carts > ul > li > div a {
    color: #000;
    margin-bottom: 3px;
    display: inline-block;
}
.cart-page .carts > ul > li > div a.remove-cart {
    padding: 5px 10px;
    font-size: 11px;
    background: #FF1744;
    color: #fff;
    border-radius: 3px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 28px;
}
.cart-page .carts > ul > li > div a.remove-cart:hover {
    opacity: .7;
}
.cart-page .carts > ul > li > div a.remove-cart i {
    vertical-align: middle;
    margin-left: 5px;
    font-size: 13px;
}
.cart-page .carts > ul > li > div a h4 {
    margin: 0;
    font-family: 'IRANYEKANX';
}
.factor-lists .carts > ul > li > div a h4 {
    font-size: 17px;
    margin-bottom: 4px;
}
.cart-page .carts > ul > li > div ul {
    padding: 0;
    margin-top: 12px;
}
.cart-page .carts ul li > div ul li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 13px;
}
.cart-page .carts ul li > div ul li .count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45px;
    border: 1px solid #d1d1d1;
    padding: 3px 10px;
    border-radius: 6px;
    color: #5b5b5b;
    font-size: 11px;
    cursor: no-drop;
}
.cart-page .carts ul li > div ul li .count i {
    margin: 0;
    vertical-align: middle;
}
.cart-page .carts ul li > div ul li > span {
    font-size: 11px;
    margin-right: 10px;
    color: #b2afaf;
}
.cart-page .carts ul li > div ul li .count span:nth-child(2) {

}
.cart-page .carts ul li > div ul li > div {
    font-size: 12px;
    color: #7c7b7b;
}
.cart-page .carts ul li > div ul li > div i {
    vertical-align: sub;
    margin-left: 7px;
}
.buy-icon span {
    position: absolute;
    background: #FFA000;
    color: #fff;
    width: 17px;
    text-align: center;
    border-radius: 100%;
    font-size: 14px;
    font-weight: bold;
    height: 17px;
    padding: 1px;
    margin-right: 10px;
    font-family: kalame;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -10px;
    right: -9px;
}
.buy-icon i {
    line-height: 0;
    font-size: 21px;
    vertical-align: middle;
    margin-right: 12px;
    color: #616161;
}
.animate__swing {
    display: inline-block;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
@-webkit-keyframes swing {
    20% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    40% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg)
    }
    60% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg)
    }
    80% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg)
    }
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}
@keyframes swing {
    20% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    40% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg)
    }
    60% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg)
    }
    80% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg)
    }
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}
.single .content-wrapper .body .inner > div:nth-child(3) {
    width: 85%;
}
.share-lists {
    display: flex;
    list-style: none;
    padding: 10px;
    justify-content: center;
}
.share-lists li {
    margin-left: 20px;
}
.share-lists li a {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #656565;
}
.single .content-wrapper .body .inner > div:nth-child(2) .article-wrapper {
    box-shadow: 1px 1px 30px #eeeeee;
    padding: 10px 30px;
    box-sizing: border-box;
    border-radius: 5px;
}
.single .content-wrapper .body .inner > div:nth-child(2) .article-wrapper h2 {
    text-align: center;
}
.single .author {
    display: flex;
    margin: 4px 0 20px;
    justify-content: space-between;
    align-items: center;
}
.single .author > div ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}
.single .author > div ul li {
    margin-left: 20px;
}
.single .author > div ul li i {
    font-size: 22px;
    cursor: pointer;
}
.single .author img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-left: 10px;
}
.single .author > div:not(.modal-backdrop) {
    display: flex;
    align-items: center;
}
.single .author > div > div {
    line-height: 1.4;
}
.single .author > div a {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #767676;
    font-family: 'IRANYekanX';
}
.offer-back {
    overflow: hidden;
    position: absolute;
    right: -40%;
    width: 154%;
    height: 106%;
    top: -6px;
    background: rgba(103, 100, 229, 1);
}
.offer-back img {
    width: 100%;
    height: auto;
    position: absolute;
    top: -12%;
    transform: rotateY(181deg);
    right: 254px;
    opacity: .3;
}
.single .author > div span {
    font-size: 11px;
    font-weight: 200;
    color: #797979;
    line-height: 2;
}
.single .content-wrapper .body .top-box {
    display: flex;
    width: 97%;
    margin: 0 auto;
}
.single .content-wrapper .body .w-l {
    padding: 9px 9px 1px;
    border-radius: 15px;
    background: #f6f6f6;
    width: 65%;
    margin-left: 16px;
}
.single .content-wrapper .body .w-l h3 {
    margin: 0;
    font-size: 21px;
    color: #e2e2e2;
    background: #fff;
    padding: 11px;
    border-radius: 8px;
    font-weight: 900;
    text-align: center;
}
.single .content-wrapper .body .w-l ul {
    list-style: none;
    padding: 2px 13px;
    margin: 16px 0 0;
}
.single .content-wrapper .body .w-l ul li {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 6px;
}
.single .content-wrapper .body .w-l ul li span {
    color: #636363;
    font-weight: 300;
}
.single .content-wrapper .body .w-l ul li i {
    font-size: 19px;
    margin-left: 8px;
}
.edit-post {
    font-size: 11px;
    color: #000;
    border: 1px solid #000;
    margin-right: 10px;
    vertical-align: middle;
    padding: 1px 6px;
    border-radius: 3px;
}
.single .content-wrapper .body .description {
    margin-bottom: 30px;
}
.single .content-wrapper .body .description strong {
    font-weight: 900;
}
.google-captcha > div > div > div {
    margin: 0 auto;
}
.login-video {
    margin: 0 auto;
    display: block;
    width: max-content;
    padding: 15px 35px;
    color: #1B5E20;
    font-family: 'IRANYEKANX';
    font-weight: 900;
    background: #E8F5E9;
    border-radius: 3px;
    font-size: 14px;
}
.single .content-wrapper .body .description p, .single .content-wrapper .body .description div.desc {
    font-size: 13px;
    line-height: 2;
    font-weight: 300;
    width: 97%;
    text-align: justify;
    display: block;
    margin: 7px auto;
}
.comment-send-wrapper .popup-unlock {
    width: 40%;
}
.modal-comment h3 {
    margin: 15px 0 0;
    font-size: 16px;
}
.comment-stream.stream.mm1 {
    padding: 0 50px 0 0 !important;
}
.comment-stream.stream.mm2 {
    padding: 15px 100px 0 0 !important;
}
.comment-stream.stream.mm3 {
    padding: 15px 150px 0 0 !important;
}
.comment-stream.stream.mm4 {
    padding: 15px 200px 0 0 !important;
}
.comment-stream.stream.mm5 {
    padding: 15px 250px 0 0 !important;
}
.clear {
    clear: both;
}
.modal-comment h4 {
    margin: 0;
    font-weight: 200;
    font-size: 13px;
    color: #4d4d4d;
    margin-bottom: 16px;
}
.modal-comment textarea {
    width: 100%;
    padding: 12px;
    height: 99px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
}
.counter {
    direction: ltr;
}
.counter.login-timer {
    font-weight: 900;
    font-size: 16px;
    position: absolute;
    top: 34px;
    left: 19px;
    font-family: 'Yekan-b';
    color: #a9a9a9;
}
.lasts .see-more {
    display: inline-block;
    font-family: 'IRANYekanX';
    font-size: 10px;
    color: #717171;
    border: 0.5px solid #666464;
    padding: 4px 7px;
    border-radius: 3px;
    margin-right: 5px;
    font-weight: 700;
    vertical-align: middle;
}
.single .content-wrapper .body .desc-see-more {
    border: 1px solid #797979;
    font-size: 9px;
    padding: 4px 6px;
    font-weight: bold;
    float: left;
    margin-left: 9px;
    margin-top: 0px;
    color: #797979;
    border-radius: 3px;
}
.info-box {
    width: 49%;
    background: #f2f2f2;
    border-radius: 15px;
}
.info-box .head-box {
    background: #fff;
    margin: 5px;
    margin-top: 8px;
    display: inline-block;
    width: 97%;
    padding: 14px 20px;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
}
.info-box .head-box h3 {
    margin: 0;
    font-size: 15px;
}
.info-box .head-box span {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    font-family: kalame;
    font-weight: bold;
    font-size: 30px;
    color: #eee;
}
.info-box .bottom-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
}
.info-box .bottom-box div {

}
.two-box-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 68px;
}
.info-box .head-box {
    background: transparent;
    padding: 3px 18px;
}
.info-box .head-box p {
    font-size: 12px;
    margin: 0;
    line-height: 2;
    color: #9f9d9d;
}
.info-box .bottom-box {
    background: #fff;
    margin: 7px;
    border-radius: 10px;
}
.info-box .bottom-box .i {
    display: flex;
    align-items: center;
}
.info-box .bottom-box a {
    font-size: 11px;
    background: #ffffff;
    padding: 7px 13px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}
.info-box .bottom-box a span {
    position: relative;
    z-index: 99;
    color: #263238;
}
.info-box .bottom-box a::before {
    content: '';
    position: absolute;
    width: 39%;
    height: 100%;
    top: 0;
    left: 0;
    border-left: 0;
    background: #ffcd4b;
    clip-path: polygon(0 0, 100% 0, 81% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 38% 100%, 0% 100%);
}
.info-box .bottom-box div img {
    width: 129px;
    height: 80px;
    margin-left: 10px;
    border-radius: 9px;
}
.info-box .bottom-box div h3 {
    font-size: 14px;
    font-family: 'Virgoul';
    color: #585858;
}
.info-box .bottom-box div h4 {
    font-size: 12px;
    font-weight: 300;
    color: #6d6d6d;
}
.r-b-t {
    position: absolute;
    right: 7px;
    top: calc(50% - 18px);
    z-index: -1;
    width: 34px;
    height: auto;
    opacity: .8;
}
.single .content-wrapper .body .course-curriculum {
    position: relative;
}
.course-min-i {
    position: absolute;
    left: 50px;
    top: 61px;
}
.course-min-i div i {
    font-size: 18px;
    vertical-align: middle;
}
.course-min-i div span {
    display: inline-block;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 200;
}
.course-min-i div a {
    font-size: 12px;
    margin-left: 6px;
    font-weight: 200;
}
.course-min-i div {
    width: 300px;
    text-align: left;
    margin-bottom: 5px;
    color: #797979;
}
.single .content-wrapper .body .course-curriculum > span {
    font-size: 12px;
    margin-top: 12px;
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 10px;
    color: #727272;
    position: absolute;
    top: 11px;
    left: 12px;
}
.comment-course .comment-streams {
    box-sizing: border-box;
    padding: 0 0 5px;
    border-radius: 4px;
    width: 100%;
    margin: 15px auto 0;
}
.single .content-wrapper .body .course-curriculum-lists {
    padding: 0;
    background: #fff;
    box-shadow: 1px 1px 30px #eeeeee;
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    padding-bottom: 10px;
}
.single .content-wrapper .body .course-curriculum-lists > li {
    list-style: none;
}
.single .content-wrapper .body .course-curriculum-lists > li .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 19px 0 !important;
    margin-top: 5px;
    cursor: pointer;
    margin-bottom: 3px;
}
.single .content-wrapper .body .course-curriculum-lists > li .head div h4 {
    display: inline-block;
    margin: 0;
    color: #000;
    font-size: 13px;
    font-weight: 300;
}
.single .content-wrapper .body .course-curriculum-lists > li .head div i {
    vertical-align: middle;
    margin-left: 7px;
    transform: rotate(90deg);
    transition: all .5s;
}
.single .content-wrapper .body .course-curriculum-lists > li.active .head div i {
    transform: rotate(0deg);

}
.single .content-wrapper .body .course-curriculum-lists > li .head > span span {
    display: inline-block;
    font-weight: 200;
    font-size: 13px;
    margin-left: 15px;
}
.single .content-wrapper .body .course-curriculum-lists > li .head > span span i {
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: sub;
}
.popup-unlock .body__bottom {
    position: relative;
    padding: 18px 16px;
    background-color: #f6f6f6;
    border-radius: 0 0 9px 9px;
}
.popup-unlock .unlock__body {
    border-radius: 8px;
    background-color: #fff;
    padding: 5px 40px;
}
.popup-unlock .unlock__header {
    height: 32px;
    margin-bottom: 12px;
}

.popup-unlock .unlock__header .coin-status {
    display: inline-block;
    height: 32px;
    padding: 0 8px 0 4px;
    border-radius: 20px;
    background-color: #262636;
    font-size: 0;
}

.popup-unlock .unlock__header .coin-status i, .popup-unlock .unlock__header .coin-status p {
    display: inline-block;
}

.popup-unlock .unlock__header .coin-status i {
    margin-top: 6px;
    color: #fff;
    z-index: 9999;
    font-size: 18px;
}

.popup-unlock .unlock__header .coin-status .amount {
    vertical-align: top;
    margin: 4px 8px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    font-weight: 700 !important;
}

.popup-unlock .unlock__header .close-btn {
    display: inline-block;
    float: left;
    width: 32px;
}

.popup-unlock .unlock__header .close-btn i {
    margin: 10px;
    font-size: 22px;
    cursor: pointer;
    color: #ffffffc4;
}
.popup-unlock .unlock__header .close-btn i:hover {
    opacity: .5;
}
.popup-unlock {
    width: max-content;
    margin: 0 auto;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}
.popup-unlock .body__bottom .app__cta {
    font-size: 0;
    line-height: 0;
    width: 77%;
}
.top-nav .top-nav__logo img {
    width: 115px;
}
.popup-unlock .body__bottom .app__cta p {
    margin: 0;
    line-height: 18px;
    font-size: 13px;
}
.popup-unlock .body__bottom .app__cta .cta__title {
    margin-bottom: 4px;
    color: #262636;
    font-weight: 700 !important;
}
.popup-unlock .body__bottom .app__cta .cta__label {
    margin-bottom: 6px;
    color: #666676;
}
.popup-unlock .body__bottom .app__cta .cta__button {
    font-size: 0;
    line-height: 0;
}
.popup-unlock .body__bottom .app__cta .cta__button > a {
    display: inline-block;
}

.popup-unlock .body__bottom .app__cta .cta__button > a:first-child {
    margin-right: 4px;
}
.popup-unlock .body__bottom > img {
    position: absolute;
    top: 7px;
    left: 16px;
    width: 61px;
}
.body-outline {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
}
.modal-backdrop {
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    background-color: rgba(0, 0, 0, .6);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 100% !important;
    z-index: 1005;
}
.modal-backdrop.login {
    display: none;
}
.circle-top {
    background: #fff;
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 100%;
    position: absolute;
    top: -14px;
    left: 9px;
    right: 0;
    margin: 0 auto;
    z-index: 99;
}
.circle-bottom {
    background: #fff;
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 100%;
    position: absolute;
    bottom: -14px;
    left: 9px;
    right: 0;
    margin: 0 auto;
    z-index: 99;
}
.single .content-wrapper .body .course-curriculum-lists > li .head > span {
    color: #575757;
    font-size: 14px;
    font-weight: bold;
}
.single .content-wrapper .body .course-curriculum-lists > li .head > span svg {
    vertical-align: sub;
}
.single .content-wrapper .body .course-curriculum-lists > li ul {
    padding: 0;
    display: none;
    overflow: hidden;
    transition: all .5s;
}
.single .content-wrapper .body .course-curriculum-lists > li.active ul {
    display: block;
    transition: all .5s;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 9px;
    margin: 0 27px;
    position: relative;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li > div:not(.free):not(.buy) {
    background: #f8f8f8;
    display: flex;
    width: calc(100% - 80px);
    align-items: center;
    justify-content: space-between;
    padding: 4px 14px;
    box-sizing: border-box;
}
.course-counter {
    font-family: 'Kalame';
    font-weight: bold;
    font-size: 18px !important;
    border-left: 1px solid #c3c1c1;
    line-height: .7;
    padding-left: 9px;
    margin-left: 11px;
    width: 20px;
}
.course-video .single .sidebar {
    width: 100% !important;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li > div .free {
    display: inline-block;
    background: #00C853;
    margin-right: 14px;
    padding: 2px 7px;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 900;
    color: #fff;
    vertical-align: middle;
    font-family: 'IRANYEKANX';
}
.single .content-wrapper .body .course-curriculum-lists > li ul li > span {
    font-size: 13px;
    padding: 2px 13px 0;
    border-radius: 3px;
    color: #5d5d5d;
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: max-content;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li > .buy {
    color: #fff;
    background: #868686;
    padding: 7px 12px;
    border-radius: 7px;
    width: 69px;
    box-shadow: 1px 2px 3px #b7b7b7;
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
    text-align: center;
}
.swal2-icon-warning {
    background: rgb(243, 156, 18) !important;
    color: #fff !important;
}
.swal2-icon-success {
    background: #00C853 !important;
    color: #fff !important;
}
.swal2-success-circular-line-left, .swal2-success-circular-line-right, .swal2-success-fix {
    background: transparent !important;
}
.swal2-popup.swal2-toast .swal2-title {
    font-size: 13px !important;
    font-weight: 900 !important;
    margin: 0 13px 0 0 !important;
    font-family: 'IRANYEKANX';
}
.swal2-popup.swal2-toast.swal2-show {
    display: flex !important;
    align-items: center;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li > .free {
    font-size: 12px;
    color: #fff;
    text-align: center;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li a {
    color: #fff;
    background: #0385fe;
    padding: 7px 12px;
    border-radius: 7px;
    width: 69px;
    box-shadow: 1px 2px 3px #a8bfff;
    font-weight: bold;
    font-size: 11px;
    display: inline-block;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li > .buy i {
    vertical-align: middle;
    margin-left: 5px;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li > .buy span {
    cursor: pointer;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li span i {
    vertical-align: middle;
    margin-left: 5px;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li div i {
    line-height: 1;
    vertical-align: bottom;
    margin-left: 6px;
}
.single .content-wrapper .body .course-curriculum-lists > li ul li div:not(.buy) span:not(.free) {
    font-size: 12px;
    display: inline-block;
    color: #4e4d4d;
}
.single .content-wrapper .body .teacher-course > div {
    display: flex;
    align-items: center;
    width: 95%;
    margin: 18px auto;
}
.single .content-wrapper .body .teacher-course > div img {
    border-radius: 100%;
    width: 85px;
}
.single .content-wrapper .body .teacher-course > div div {
    margin-right: 14px;
}
.single .content-wrapper .body .teacher-course > div h4 {
    margin: 0;
    font-family: kalame;
    font-size: 24px;
}
.single .content-wrapper .body .teacher-course > div span {
    font-size: 13px;
    font-weight: 300;
}
.recommend {
    width: 100% !important;
    margin-top: 38px !important;
}
.recommend .content-lists {
    margin-bottom: 0 !important;
    display: flex;
}
.archive-page .content-lists {
    flex-wrap: wrap;
}
.recommend .content-lists li {
    width: 31% !important;
    box-shadow: none;
    margin-left: 23px !important;
    padding: 0 !important;
    background: transparent;
    filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, .10));
}
.archive-page .content-lists li {
    width: 18% !important;
    margin-bottom: 25px;
}
.recommend .content-lists li > div {
    clip-path: url(#popular-categories-card-bg);
    background: #fff;
    padding: 11px;
}
.archive-page .content-lists li > div {
    clip-path: none;
    border-radius: 16px;
}
.search-lists .content-lists li > div {
    clip-path: none;
    background: #fff;
    padding: 6px 8px 11px;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.no-result {
    text-align: center;
    font-weight: 200;
    font-size: 15px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #535353;
}
@media (max-width: 1200px) {

    .why-tpay__title-sec:before {
        left: 15px;
    }

    .why-tpay__item-description {
        margin-left: 20px;
    }
}
.comment-course {
    margin-top: 50px;
    border-radius: 3px;
}
.comment-course h3 {
    margin: 0;
    position: relative;
    margin-bottom: 3px;
    font-size: 15px;
}
.comment-course .subtitle {
    font-size: 13px;
    font-weight: 200;
    margin-bottom: 16px;
    display: inline-block;
    color: #524e4e;
}
.comment-section {
    width: 100%;
    font-size: 14px;
    line-height: 1.3;
    margin: 10px 0 20px;
    transform: scale(1);
}
.comment-section form {
    filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, .10));
}
.comment-section .reply {
    padding: 5px 10px;
    font-size: 10px;
    border-radius: 3px;
    margin-top: 0;
    font-weight: 300;
    cursor: pointer;
    display: inline-block;
    color: #5f5f5f;
}
.comment-section .comment-streams .comment-stream .line {
    height: 114%;
    width: 2px;
    background: #e5e5e5;
    position: absolute;
    right: 50px;
    top: 31px;
}
.comment-section .comment-streams .comment-stream.mm1:before {
    content: '';
    position: absolute;
}
.comment-section .reply i {
    vertical-align: middle;
    margin-left: 5px;
    font-size: 15px;
    color: #c1c1c1;
}
.comment-section.more .comment-streams .comment-stream:last-child {
    border-bottom: 0 none;
}
.comment-section.more .comment-streams > ul.children {
    border-bottom: 1px solid #f6f6f6;
}
.comment-section .comment-header {
    font-size: 16px;
    color: #868696;
    position: relative;
    line-height: 1.2;
    padding-top: 15px;
    margin-bottom: 20px;
}
.comment-section .comment-header .comment-cnt {
    font-weight: 700 !important;
    color: #a6a6a6;
}
.comment-section .comment-header .dropdown-wrap {
    position: relative;
}
.comment-section .comment-header .dropdown-wrap.mod-stats {
    position: absolute;
    left: 0;
    top: 15px;
}
.comment-section .write-box {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0 30px;
}
.comment-section .write-box .input-wrap textarea {
    padding-right: 0;
    width: 100%;
    height: 100px;
}
#commentform .form-submit {
    display: none;
}
.comment-section .write-box .avatar-wrap {
    float: right;
}
.comment-section .write-box .avatar-wrap > a {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 100%;
    margin: 0 auto;
}
.search .right-side {
    width: 420px;
    position: relative;
    margin-top: 55px;
}
.search .right-side .search {
    display: none;
}
.back-character {
    width: 300px;
    height: 300px;
    position: absolute;
    background: #fdbe19;
    bottom: 55px;
    margin: 0 auto;
    left: -100px;
    right: 0;
    border-radius: 100%;
}
.icon-1 {
    z-index: 999;
    position: absolute;
    width: 49px;
    left: 206px;
    bottom: 109px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}
.line-header {
    position: absolute;
    bottom: 23px;
    opacity: .1;
    transform: rotate(-32deg);
    right: -35px;
}
.search .character {
    margin-left: -75px;
    margin-top: 0px;
    z-index: -1;
    position: relative;
    width: 355px;
    margin-right: 60px;
}
.search .b3-head {
    width: calc(100% - 830px);
    margin-right: 55px;
    line-height: 3;
    margin-top: 110px;
}
.search .b3-head p {
    font-family: 'IRANYekanX';
    font-weight: 1000;
    font-size: 17px;
    width: 100%;
    line-height: 1.5;
    text-align: center;
}
.all-course {
    background: #fcbf17;
    font-weight: 1000;
    margin-left: 12px;
    padding: 13px 18px;
    border-radius: 9px;
    color: #373737;
    font-family: 'IRANYEKANX';
    font-size: 12px;
    margin-bottom: 20px;
    display: inline-block;
    margin-top: 20px;
}
.all-course:hover {
    opacity: .7;
}
.icon-2 {
    position: absolute;
    width: 49px;
    left: 180px;
    top: 136px;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}
.icon-3 {
    position: absolute;
    width: 49px;
    right: 39px;
    top: 58px;
}
@-webkit-keyframes bounceIn {
    0% {
        -webkit-transform: scale(0.1) rotate(45deg);
        transform: scale(0.1) rotate(45deg)
    }
    to {
        opacity: 1;
        -webkit-transform: scaleX(1) rotate(15deg);
        transform: scaleX(1) rotate(15deg)
    }
}
@-webkit-keyframes pulse {
    0% {
        -webkit-transform: rotate(15deg) scaleX(.3);
        transform: rotate(15deg) scaleX(.3)
    }
    50% {
        -webkit-transform: rotate(15deg) scale3d(1.9, 1.9, 1.9);
        transform: rotate(15deg) scale3d(1.9, 1.9, 1.9)
    }
    to {
        -webkit-transform: rotate(15deg) scaleX(1);
        transform: rotate(15deg) scaleX(1)
    }
}
.user-label.subscriber {
    font-size: 10px;
    background: #464646;
    padding: 5px 7px;
    box-sizing: border-box;
    border-radius: 7px;
    font-weight: 900;
    color: #fff;
    box-shadow: 1px 2px 3px #b3b3b3;
    margin-top: 6px;
}
.comment-section .write-box .left-arrow {
    position: absolute;
    top: 23%;
    transform: rotate(180deg);
    right: -8px;
    width: 0;
    height: 0;
    border: solid transparent;
    margin-top: -8px;
    border-width: 8px 8px 8px 0;
}
.comment-section .write-box .left-arrow.in {
    right: -7px;
    transform: rotate(180deg);
    border-right-color: #fff;
}
.comment-section .write-box .holder {
    display: block;
    cursor: pointer;
    position: relative;
}
.comment-section .write-box .input-outer {
    margin-right: 68px;
}
.comment-section .write-box .input-wrap textarea {
    transition: padding .3s linear;
    padding: 0;
    box-sizing: border-box;
    border: 1px solid #e9e9e9;
    border-radius: 19px;
}
.comment-section .write-box .input-wrap .comment-body {
    font-size: 13px;
    padding: 12px 12px;
    width: 100%;
    height: 55px;
    box-sizing: initial;
}
.comment-section .write-box .input-wrap .comment-body:-moz-placeholder, .comment-section .write-box .input-wrap .comment-body::-moz-placeholder {
    color: #c6c6c6 !important;
    text-overflow: ellipsis;
}
.comment-section .write-box .input-wrap .comment-body:-ms-input-placeholder {
    color: #c6c6c6 !important;
    text-overflow: ellipsis;
}
.comment-section .write-box .input-wrap .comment-body::-webkit-input-placeholder {
    color: #c6c6c6 !important;
    text-overflow: ellipsis;
    line-height: normal;
}
.comment-section .comment-streams .comment-stream {
    padding: 0;
}
.comments-wrap ul.children {
    margin-right: 60px;
}
.comments-wrap ul.children .comment-stream .body-wrap {
    margin-right: 42px;
}
.comments-wrap ul.children .comment-stream .avatar-wrap .avatar {
    width: 30px;
    height: auto;
}
.comments-wrap ul.children .comment-stream .avatar-wrap .avatar {
    width: 30px;
    height: auto;
}
.comment-section .comment-streams .avatar-wrap {
    float: right;
}
.comment-notice {
    text-align: right;
    padding: 0 31px;
    margin-bottom: 22px;
    font-weight: 200;
    font-size: 12px;
}
.comment-section .comment-streams .avatar-wrap .avatar {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-left: 10px;
}

.comment-section .comment-streams .parent-body-wrap {
    position: relative;
    border-radius: 28px;
    padding: 12px;
    margin-bottom: 8px;
    background: #eeeeee78;
}
.comment-section .comment-streams .body-wrap {
    position: relative;
    padding: 8px 0 1px;
}
.comment-section .comment-streams .body-wrap .body-header {
    font-size: 12px;
    display: flex;
    width: 100%;
    text-align: right;
}
.comment-section .comment-streams .body-wrap .body-header .commenter {
    font-weight: 300 !important;
    color: rgb(115 115 115);
    vertical-align: bottom;
    font-family: 'AkharinKhabar';
    font-size: 16px;
    line-height: 1;
    margin-left: 7px;
    display: inline-block;
    margin-top: 3px;
}
.comment-section .comment-streams .body-wrap .body-header .commenter:hover {
    color: #464656;
}
.comment-section .comment-streams .body-wrap .body-header .date {
    font-size: 11px;
    color: #a6a6a6;
    display: inline-block;
    margin-right: 2px;
    font-weight: 200;
}
.comment-section .comment-streams .body-wrap .comment-txt {
    font-size: 12px;
    word-wrap: break-word;
    color: #666676;
    margin-bottom: 3px;
    line-height: 2;
}
.comment-section .comment-streams .body-wrap .comment-txt p {
    font-weight: 300;
    margin-bottom: 0;
    margin-right: 0;
    margin-top: 0;
    font-size: 12px;
}
.comment-section .comment-streams .vote-wrap .vote-btn {
    display: inline;
    line-height: 36px;
}
.comment-section .comment-streams .downvote-btn i {
    font-size: 13px;
    color: #d32f2f;
}
.comment-section .comment-streams .upvote-btn i {
    color: #388E3C;
    font-size: 13px;
}
.comment-section .comment-streams .vote-wrap .up-count {
    font-size: 20px;
    color: #757575;
    vertical-align: top;
    font-weight: bold;
    font-family: yekan;
    margin: 0 3px;
}
.comment-section .comment-streams .vote-wrap .up-count.positive {
    color: #56c676;
}
.comment-section .comment-streams .vote-wrap .up-count.negative {
    color: #f64656;
}
#smart-view .ep-footer .comments-wrap .comment-header {
    font-size: 16px;
    color: #d6d6d6;
}
.archive {
    padding: 1px 10%;
    width: 100%;
    margin-top: 25px;
}
.archive .tops {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.archive-page .tops {
    display: block;
}
.archive .tops .r {
    width: 300px;
    text-align: right;
    font-family: Virgoul;
    font-size: 23px;
}
.archive .tops .r a {
    font-family: 'Vazirmatn';
    font-size: 11px;
    border: 1px solid #000;
    padding: 3px 7px;
    border-radius: 5px;
    font-weight: bold;
}
.assets-box-7 {
    background-color: #f3f3f3;
    width: 100%;
    height: 350px;
    position: absolute;
    top: -80px;
    right: 0;
    left: 0;
    -moz-transform: skewY(4deg);
    transform: skewY(4deg);
    opacity: 1;
    z-index: -1;
    margin: 0 auto;
}
.assets-box-15 {
    background-color: #f3f3f3;
    width: 100%;
    height: 450px;
    position: absolute;
    top: -80px;
    right: 0;
    left: 0;
    -moz-transform: skewY(4deg);
    transform: skewY(4deg);
    opacity: 1;
    z-index: -1;
    margin: 0 auto;
}
.archive .tops-lists {
    margin-top: 0 !important;
}
.archive .tops-lists .content-lists li {
    margin-left: 20px !important;
}
.comment__sort .sub-filter__items {
    display: inline-block;
    float: right;
    font-size: 0;
    line-height: 0;
}
.comment__sort .sub-filter__items .sub-filter__item {
    display: inline-block;
    line-height: 20px;
    padding: 0 16px;
    font-size: 13px;
}
.comment__sort .sub-filter__items .sub-filter__item a {
    display: block;
    color: rgba(22, 22, 38, .6);
}
.comment__sort .sub-filter__items .sub-filter__item a:hover {
    color: #161626;
}
.buy-course {
    color: #4CAF50;
    font-size: 14px;
    padding: 7px 8px;
    text-align: center;
    font-weight: 900;
    font-family: 'IRANYEKANX';
}
.rate-wrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 53px;
    flex-direction: row-reverse;
    position: absolute;
    top: 47px;
    left: -7px;
}
.comment__write-btn {
    padding: 6px 12px;
    float: left;
    background-color: #f6b606;
    border-radius: 4px;
    line-height: 18px;
    font-size: 13px;
    color: #fff !important;
    font-weight: 600 !important;
    transition: background-color .2s linear;
    margin-top: 13px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
}
#cancel-comment-reply-link {
    cursor: pointer;
}
.comment__write-btn:hover {
    opacity: .7;
}
.comment__write-btn.disabled {
    background-color: #d6d6d6;
}
.comment__sort .sub-filter__items .sub-filter__item.filter__item--on a {
    color: #161626;
    font-weight: 600 !important;
}
.comment__sort .sub-filter__items .sub-filter__divider {
    display: inline-block;
    margin: 4px 0;
    vertical-align: bottom;
    width: 1px;
    height: 12px;
    background-color: #d6d6d6;
}
@media (max-width: 480px) {
    .comment__sort .sub-filter__item {
        padding: 0 8px !important;
        font-size: 12px !important;
    }
}
.comment-pagination {
    text-align: center;
    margin-bottom: 15px;
}
.comment-pagination > a {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.comment-pagination > a {
    font-family: 'Vazirmatn';
    color: #BEC2BF !important;
    border: 0;
    padding: 5px 11px 3px;
    display: inline-block;
    font-weight: bold;
    margin-left: 3px;
    font-size: 14px;
}
.comment-pagination .current {
    color: #5C665F !important;
    background-color: transparent !important;
    border: 1px solid #aaaaaa;
    border-radius: 2px;
}
.comment-pagination .next {
    font-family: IRANYekan;
    color: #131A15 !important;
    border: 1px solid #131A15 !important;
    border-radius: 0 !important;
    padding: 10px;
    font-size: 12px;
    font-size: 12px;
    font-weight: bold;
}
.more-learn {
    position: relative;
}
.more-learn span.more {
    position: absolute;
    top: -118px;
    font-family: Yekan-B;
    width: 282px;
    background: #fff;
    box-shadow: 1px 1px 30px #3333331f;
    padding: 10px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 1.8;
    right: -50px;
    display: none;
}
ul.comment-head {
    position: absolute;
    top: -11px;
}
ul.comment-head li {
    background: #fff;
    padding: 4px 10px 1px;
    font-family: 'Yekan-B';
    font-size: 12px;
    border-radius: 2px;
    display: inline-block;
    cursor: pointer;
}
ul.comment-head li i {
    vertical-align: middle;
    margin-left: 2px;
}
.more-learn:hover .more {
    display: block;
}

.pretty-input {
    position: relative;
    padding-right: 21px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    color: #757575;
    font-family: 'IRANYekanX';
    font-weight: 300;
}
.search-check-box-label::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    background: url("../../../public/images/check.svg") no-repeat center/100%;
    right: -3px;
    top: -4px;
    transform: scale(0);
    opacity: 0;
    animation: check-wave-close .5s;
}
.teacher-label::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    background: url("../../../public/images/check.svg") no-repeat center/100%;
    right: 2px;
    top: 2px;
    transform: scale(0);
    opacity: 0;
    animation: check-wave-close .5s;
}
.teacher-label.active::before {
    content: '';
    position: absolute;
    width: 27px;
    height: 27px;
    background: #000;
    border-radius: 100%;
    opacity: .3;
}
.teacher-label.active::after {
    animation: check-wave .5s running forwards;
}
.search-checkbox {
    display: none;
}
.search-checkbox:checked + .search-check-box-label::after {
    animation: check-wave .5s running forwards;
}
.search-check-box-label {
    margin: 0;
    cursor: pointer;
    color: #979797;
    transition: .3s;
    position: relative;
    font-size: 12px;
    font-family: 'IRANYEKANX';
}
.search-check-box-label::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    border: 1px solid #bdced6;
    border-top-color: rgb(189, 206, 214);
    border-right-color: rgb(189, 206, 214);
    border-bottom-color: rgb(189, 206, 214);
    border-left-color: rgb(189, 206, 214);
    border-radius: 4px;
    margin-left: 10px;
    transition: .3s;
}
.bot {
    width: 100%;
    margin: 17px 0;
}
.bot label {
    display: block;
    margin-bottom: 12px;
    width: 100%;
}
.bot label span {
    font-family: 'IRANYekanX';
    font-weight: 300;
    color: #5d5b5b;
    font-size: 13px;
}
.bot label input {
    width: 100%;
    padding: 6px 20px;
    direction: ltr;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    margin-top: 4px;
    color: #4d4d4d;
}
.bot-btn {
    display: inline-block;
    font-size: 11px;
    width: max-content;
    border: 1px solid #000;
    padding: 1px 7px;
    border-radius: 3px;
    margin-right: 11px;
    cursor: pointer;
    vertical-align: text-top;
}
.bot a {
    border-radius: 7px;
    height: 30px;
    width: 90px;
    font-size: 12px;
    background: #0385fe;
    box-shadow: 1px 2px 3px #a8bfff;
    display: block;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-family: 'IRANYEKANX';
    line-height: 2.4;
    margin: 22px auto;
}
@keyframes check-wave {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes check-wave-close {
    100% {
        transform: scale(1.2);
        opacity: 0;
    }

    0% {
        transform: scale(1);
        opacity: 1;
    }
}
.pretty-input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.pretty-input .checkmark {
    position: absolute;
    top: 5px;
    right: 0;
    height: 12px;
    width: 12px;
    border: 1px solid #ababab;
    border-radius: 13px;
}
.pretty-input input:checked ~ .checkmark {
}
.pretty-input .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.pretty-input input:checked ~ .checkmark:after {
    display: block;
}
.pretty-input .checkmark:after {
    left: 2px;
    top: 2px;
    width: 6px;
    height: 6px;
    border-width: 0 3px 3px 0;
    background: #5cb85c;
    border-radius: 13px;
}
* {
    box-sizing: border-box;
}
.table-wrapper-custom tbody tr.unread {
    background: #FFF3E0 !important;
}
.archive .sidebar > div {
    border-radius: 3px;
    position: relative;
}
.archive .sidebar div.sid-box {
    position: relative;
}
.footer-box {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 9px;
}
.footer-box img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
}
.footer-box > div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.footer-box > div span:first-of-type {
    font-size: 12px;
    color: #404040;
}
.free .footer-box > div span:first-of-type {
    background: #00C853;
    color: #fff;
    font-weight: bold;
    font-size: 10px;
    width: 35px;
    border-radius: 4px;
    margin-bottom: 2px;
    text-align: center;
}
.free .home-elements_style_item_card_component__details p {
    font-size: 12px;
    margin-bottom: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 0;
    font-weight: 200;
    white-space: break-spaces;
    color: #6b6a6a;
    max-height: 39px;
}
.footer-box > div span:last-of-type {
    font-size: 11px;
    color: #8b8b8b;
}
a.search-submit {
    background: #FFB300;
    width: 98%;
    display: block;
    text-align: center;
    padding: 9px;
    font-size: 12px;
    border-radius: 21px;
    margin: 18px auto 25px;
    font-weight: bold;
    cursor: pointer;
    color: #fff;
    font-family: 'IRANYekanX';
}
a.search-submit:hover {
    opacity: .7;
}
.ticket-wrapper .ticket-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 35px;
}
.ticket-wrapper .ticket-head > div:first-of-type {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.ticket-wrapper .ticket-head ul li h3 {
    display: inline-block;
    margin: 0;
    font-family: 'IRANYEKANX';
    vertical-align: bottom;
    margin-top: 8px;
    font-size: 16px;
    margin-left: 7px;
}
.ticket-wrapper .ticket-head > div:first-of-type > div:first-of-type {
    display: flex;
    align-items: center;
}
.ticket-wrapper .ticket-head img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
}
.ticket-wrapper .ticket-head ul {
    list-style: none;
    margin: 0;
    padding: 0 16px 0;
}
.ticket-wrapper .ticket-head ul li .status0 {
    padding: 3px 4px;
    font-family: 'IRANYekanX';
    font-weight: 900;
}
.return-ticket {
    color: #494747;
    font-size: 12px;
    display: inline-block;
    border: 1px solid #878787;
    padding: 9px 13px;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'IRANYEKANX';
    font-weight: 900;
}
.return-ticket.mob {
    display: none;
}
.return-ticket i {
    margin-right: 7px;
    font-size: 16px;
    vertical-align: middle;
}
.ticket-wrapper .ticket-head ul li i {
    vertical-align: middle;
    margin-left: 9px;
}
.ticket-wrapper .ticket-head ul li.course {
    border-radius: 5px;
    margin: 5px 0;
}
.ticket-wrapper .ticket-head ul li span {
    font-size: 12px;
    display: inline-block;
    font-weight: 300;
}
.ticket-wrapper .ticket-head ul li.course span {
    font-weight: bold;
    font-family: 'IRANYEKANX';
}
.ticket-wrapper .ticket-head ul li span.line {
    margin-left: 10px;
    border-left: 1px solid #c5bcbc;
    padding-left: 10px;
}
.ticket-body {

}
.ticket-body .ticket {
    display: flex;
    align-items: end;
    margin-bottom: 20px;
}
.ticket-body .ticket.sender {
    flex-direction: row-reverse;
}
.ticket-body .ticket.getter {

}
.ticket-body .ticket img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
.ticket-body .ticket.getter > div {
    margin-right: 20px;
    width: 80%;
}
.ticket-body .ticket.sender > div {
    margin-left: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 80%;
}
.ticket-body .ticket.sender div.p {
    background: #f7f7f7;
    padding: 18px;
    width: max-content;
    border-radius: 13px 13px 13px 0;
    font-size: 13px;
    line-height: 2;
    max-width: 60%;
}
.ticket-body .ticket.getter div.p {
    padding: 1px 18px;
    width: max-content;
    border-radius: 13px 13px 0 13px;
    font-size: 13px;
    line-height: 2;
    background: #666;
    color: #fff;
    max-width: 60%;
}
.status0 {
    background: #FFA000;
    padding: 6px 6px;
    border-radius: 3px;
    font-size: 11px !important;
    color: #fff;
    margin-right: 5px;
    display: inline-block;
    font-weight: 900 !important;
    font-family: 'IRANYekanX';
}
.status1 {
    background: #64DD17;
    padding: 6px 6px;
    border-radius: 3px;
    font-size: 11px !important;
    color: #fff;
    margin-right: 5px;
    display: inline-block;
    font-weight: 900 !important;
    font-family: 'IRANYekanX';
}
.status2 {
    background: #515151;
    padding: 6px 6px;
    border-radius: 3px;
    font-size: 11px !important;
    color: #fff;
    margin-right: 5px;
    display: inline-block;
    font-weight: 900 !important;
    font-family: 'IRANYekanX';
}
.arrow-line {
    transform: rotateX(170deg) rotate(193deg);
    opacity: .1;
    position: absolute;
    right: -120px;
    bottom: 59px;
}
.ticket-body .ticket div b {
    display: inline-block;
    font-size: 12px;
}
.ticket-body .ticket div span {
    font-size: 12px;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #a8a8a8;
}
.profile .content {
    width: 80%;
    margin: 50px auto 0;
    display: flex;
    align-items: flex-start;
}
.ticket-form-reply {
    box-shadow: 1px 1px 30px #9593932b;
    padding: 5px 40px 14px;
    margin-top: 50px;
    border-radius: 5px;
}
.ck.ck-content.ck-editor__editable p {
    color: #858585;
    font-size: 14px;
    font-family: Yekan-B;
}
.ck.ck-icon {
    opacity: .7;
}
.ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
    font-family: 'Vazirmatn';
}
.ck.ck-content.ck-editor__editable {
    font-size: 15px;
    padding: 10px;
    height: 125px;
}
.ck.ck-toolbar {
    background: transparent !important;
    border: none !important;
    margin-bottom: 10px !important;
}
.ticket-form-reply label {
    width: 100%;
    display: block;
}
.ticket-form-reply h3 {
    margin-bottom: 8px;
}
.errors {

}
.errors p {
    text-align: center;
    font-size: 13px;
    background: #ffecef;
    padding: 8px;
    color: #B71C1C;
}
.ticket-form-reply .comment__write-btn {
    padding: 8px 40px;
}
.ticket-form-reply label textarea {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    height: 200px;
    border: 1px solid #c8c8c8;
}

.assets-box-9 {
    clip-path: url(#landing-header-mask-path);
    position: absolute;
    width: 100%;
    height: 252px;
    background: #f4f4f4;
    z-index: -1;
}
.profile .content .sidebar {
    background: #fff;
    box-shadow: 1px 1px 30px #9593932b;
    width: 250px;
    border-radius: 30px;
    padding: 20px 20px;
    box-sizing: border-box;
    z-index: 1;
}
.profile .content .top-boxes {
    display: flex;
    width: 100%;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
}
.profile .content .top-boxes li {
    width: 29%;
    text-align: center;
    position: relative;
    filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, .10));
    padding-bottom: 15px;
    border-radius: 3px;
}
.wallet-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 89px #e2e2e2;
    width: 360px;
    border-radius: 20px;
    padding: 37px;
    margin: 0 auto;
}
.wallet-wrapper > div {
    width: 100%;
}
.wallet-wrapper .box-wallet {
    width: 100%;
    text-align: center;
}
.wallet-wrapper .box-wallet p:nth-child(1) {
    font-weight: 900;
    font-family: 'IRANYEKANX';
    font-size: 19px;
    margin-top: 0;
}
.wallet-wrapper .box-wallet p:nth-child(2) {
    font-family: 'VAZIRMATN FD';
    font-size: 30px;
    font-weight: 900;
    margin-top: 0;
}
.wallet-wrapper .wallet-info {
    padding: 0;
    margin-top: 0;
    text-align: center;
}
.wallet-wrapper .wallet-info li {
    list-style: none;
    line-height: 2;
    font-size: 13px;
    color: #878787;
}
.price-wrapper .unlock__body p {
    font-size: 13px;
    color: #5e5e5e;
}
.price-wrapper .unlock__body ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    font-size: 13px;
    margin: 25px 0;
}
.price-wrapper .unlock__body ul li {
    border: 1px solid #b8b8b8;
    line-height: 3;
    padding: 0 10px;
    border-radius: 5px;
    color: #8c8c8c;
    font-family: 'IRANYekanX';
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
}
.price-wrapper .unlock__body ul li.active {
    border: 1px solid #000;
    color: #000
}
.price-wrapper .unlock__body a {
    display: flex;
    flex-direction: row-reverse;
    height: 39px;
    line-height: 39px;
    padding: 0 22px;
    background-color: #0668e1;
    font-weight: 700;
    color: #ffffff;
    box-shadow: 0 8px 7px -8px #0668e1;
    border-radius: 8px;
    font-size: 13px;
    margin-top: 13px;
    width: max-content;
    margin-bottom: 20px;
    float: left;
}
.price-wrapper .unlock__body .your-price {
    text-align: center;
}
.price-wrapper .unlock__body .your-price p {
    font-family: 'IRANYEKANX';
    font-weight: 900;
    color: #000;
    margin-bottom: 0;
    margin-top: 30px;
    font-size: 24px;
}
.price-wrapper .unlock__body .your-price p.p {
    font-family: 'Vazirmatn FD';
    font-weight: 900;
    color: #000;
    margin-top: 0;
    font-size: 25px;
}
.price-wrapper .unlock__body .your-price p span {
    font-family: 'IRANYekanX';
    font-size: 15px;
}
.price-wrapper .unlock__body .price-input {
    margin-bottom: 25px;
    display: none;
}
.clearfix {
    clear: both;
}
.price-wrapper .unlock__body .price-input.active {
    display: block;
}
.price-wrapper .unlock__body .price-input p {
    margin-bottom: 7px;
    font-family: 'IRANYekanX';
    font-size: 12px;
    color: #969696;
}
.price-wrapper .unlock__body .price-input input {
    width: 100%;
    direction: ltr;
    padding: 6px 13px;
    font-family: 'VAZIRMATN FD';
    font-size: 18px;
    font-weight: bold;
    border-radius: 3px;
    border: 0.1px solid #979797;
    color: #979797;
}
.wallet-wrapper .wallet-btn {
    display: flex;
    align-self: end;
    height: 39px;
    line-height: 39px;
    padding: 0 15px;
    background-color: #0668e1;
    font-weight: 700;
    color: #ffffff;
    box-shadow: 0 8px 7px -8px #0668e1;
    border-radius: 8px;
    font-size: 13px;
    margin-top: 13px;
}
.profile .content .top-boxes li:nth-child(2) {
    width: 36%;
}
.profile .content .top-boxes li > div {
    background: #fff;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.profile .content .top-boxes li:nth-child(1) > div {
    clip-path: url(#card-info-path-ltr);
}
.profile .content .top-boxes li:nth-child(2) > div {
    clip-path: url(#card-info-path-center);
}
.profile .content .top-boxes li:nth-child(3) > div {
    clip-path: url(#card-info-path-rtl);
}
.profile .content .top-boxes li i {
    position: absolute;
    top: 7%;
    font-size: 56px;
    right: 21px;
    opacity: .1;
    transform: rotate(-45deg);
    margin: 0 auto;
}
.profile .content .top-boxes li img {
    width: 34px;
    vertical-align: middle;
    margin-left: 9px;
}
.profile .content .top-boxes li h4 {
    font-size: 18px;
    margin: 0;
    font-weight: 900;
    font-family: 'IRANYekanX';
    margin-top: 11px;
    margin-right: 12px;
}
.profile .content .top-boxes li span {
    font-size: 32px;
    font-weight: 900;
    opacity: .1;
}
.profile .content .top-boxes li p {
    font-weight: 200;
    font-size: 13px;
    margin: 0;
    padding: 0 15px;
}
.profile .content .sidebar .avatar {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 23px;
    padding-left: 11px;
}
.profile .content .sidebar .avatar img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-left: 10px;
}
.profile .content .sidebar .avatar > div {
    display: flex;
    margin-bottom: 7px;
    font-size: 13px;
    align-items: center;
}
.profile .content .sidebar .avatar > div div:first-of-type {
    font-weight: bold;
    font-family: virgoul;
}
.profile .content .sidebar .avatar > div div:last-of-type {
    font-weight: 200;
    font-size: 12px;
    margin-top: 0;
}
.profile .content .sidebar .mobile-menu {
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    display: none;
    position: absolute;
    left: -48px;
    background: #fff;
    z-index: 9999;
    top: 65%;
    font-size: 24px;
    padding: 14px 15px;
    border-radius: 10px 0 0 10px;
    flex-direction: column;
}
.profile .content .sidebar .mobile-menu span {
    font-family: 'IRANYekanX';
    font-size: 13px;
    margin-right: 0;
    font-weight: 900;
}
.profile .content .sidebar .avatar > div div:last-of-type div:first-of-type {
    text-align: right;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Vazirmatn';
    margin-bottom: 5px;
}
.profile .content .sidebar .avatar > a {
    color: #979797;
}
.profile .content .sidebar .avatar > div div:last-of-type div:last-of-type {
    font-size: 13px;
    color: #616161;
}
.profile .content .sidebar ul {
    padding: 0 10px;
    list-style: none;
}
.profile .content .sidebar ul li {
    margin-bottom: 14px;
    font-size: 13px;
    position: relative;
}
a {
    cursor: pointer;
}
.profile .content .sidebar ul li a {
    color: #000;
    cursor: pointer;
}
.table-wrapper-custom {
    position: relative;
    max-width: 100%;
}
.table-box {
    box-shadow: 1px 1px 30px #9593932b;
    padding: 33px 20px 20px;
    border-right: 5px;
    position: relative;
}
.table-pages #pagination-previous-page, .table-pages #pagination-next-page, .table-pages #pagination-previous-page:focus, .table-pages #pagination-next-page:focus {
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
}
.table-pages #pagination-previous-page svg, .table-pages #pagination-next-page svg {
    fill: #000;
}
.table-pages #pagination-previous-page svg:hover, .table-pages #pagination-next-page svg:hover {
    opacity: .7;
}
.table-pages .detail {
    vertical-align: 8px;
    display: inline-block;
    font-size: 12px;
}
.table-pages {
    text-align: left;
    margin-top: 17px;
}
.table-wrapper-custom > input {
    position: absolute;
    left: 0;
    top: -44px;
    border: 1px solid #d3d3d3 !important;
    padding: 7px 13px;
    border-radius: 4px;
    font-size: 12px;
    color: #000;
}
.table-wrapper-custom > input::placeholder {
    color: #aaaaaa;
}
.table-wrapper-custom tbody tr:nth-child(2n-1) {
    background-color: #f6f6f6a1;
}
.bookmark-table tbody tr img {
    width: 80px;
    height: auto;
    border-radius: 9px;
}
.table-wrapper-custom tbody tr:nth-child(2n) {
    background-color: #fff;
}
.table-responsive {
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 8px;
    width: 100%;
}
.table-responsive table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}
.table-responsive th, .table-responsive td {
    text-align: center;
    padding: 13px;
    font-weight: 300;
    font-size: 12px;
    line-height: 1;
    border-left: 6px solid #fff;
    color: #6a6a6a;
}
.profile .content-lists li {
    width: 24% !important;
    margin-bottom: 27px;
    margin-left: 1.3% !important;
}
.table-responsive tr th:last-of-type {
    border-left: none
}
.table-responsive thead tr {
}
.table-responsive thead tr th {
    font-weight: 700;
    font-size: 13px;
    padding-bottom: 15px;
    font-family: 'IRANYEKANX';
    color: #646464;
}
.profile .content .sidebar ul li i {
    font-size: 17px;
    vertical-align: middle;
    margin-left: 10px;
    padding: 3px 5px;
    border-radius: 5px;
    z-index: 2;
    position: relative;
}
.profile-content-wrapper {
    box-shadow: 1px 1px 30px #9593932b;
    background: #fff;
    padding: 20px;
}
.send-ticket form {
    box-shadow: 1px 1px 30px #9593932b;
    background: #fff;
    padding: 20px;
}
.send-ticket form label {
    width: 25%;
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 11px;
    position: relative;
}
.send-ticket form label ul {
    padding: 10px;
    list-style: none;
    border: 1px solid #c6c6c6;
    border-top: none;
    width: 95%;
    position: absolute;
    background: #fff;
    z-index: 9;
    top: 46px;
    border-radius: 0 0 10px 10px;
}
.send-ticket form label ul li {
    font-size: 13px;
    margin-bottom: 8px;
    cursor: pointer;
}
.send-ticket form label ul li:hover {
    opacity: .6
}
.send-ticket form label.course {
    width: 46%;
}
.send-ticket form label span {
    font-size: 13px;
    margin-bottom: 8px;
    display: inline-block;
    color: #505050;
}
.send-ticket form label input {
    display: block;
    width: 95%;
    padding: 6px;
    border-radius: 6px;
    border: 1px solid #c0c0c0;
}
.send-ticket form label select {
    display: block;
    width: 100%;
    padding: 6px;
    border-radius: 6px;
    border: 1px solid #c0c0c0;
    background: #fff;
}
.send-ticket form label textarea {
    width: 99%;
    height: 200px;
    border-radius: 5px;
    border: 1px solid #c0c0c0;
    padding: 10px;
    display: block;
}
.send-ticket form label.content-ticket {
    width: 97%;
}
.profile .content .sidebar ul li img {
    display: none;
}
.profile .content .sidebar ul li img {
    display: block;
    position: absolute;
    right: 0px;
    top: -4px;
    z-index: 0;
    width: 30px;
    height: auto;
}
.table-responsive th a {
    color: #6b6b6b;
    font-weight: 500;
}
.table-responsive th a.unread {
    font-weight: 700;
    color: #000;
}
.bookmark-table th a {
    font-weight: 700;
    font-family: 'IRANYekanX';
    font-size: 13px;
    color: #333;
}
.table-box .send-ticket {
    color: #585858;
    font-size: 12px;
    padding: 6px 11px;
    font-weight: 900;
    border-radius: 6px;
    margin-bottom: 8px;
    display: inline-block;
    border: 2px solid #4f4f4f;
    box-shadow: 2px 2px 7px #edededc4;
}
.table-box .send-ticket i {
    vertical-align: middle;
    font-size: 18px;
    margin-left: 7px;
}
.table-box .send-ticket:hover {
    opacity: .8;
}
.profile .content .content-wrapper {
    width: calc(100% - 265px);
    padding-right: 30px;
    box-sizing: border-box;
}
.profile .dashboard-content .last-notification {
    width: 100%;
}
.profile .dashboard-content .last-notification ul {
    list-style: none;
    padding: 0;
}
.profile .dashboard-content .last-notification ul li {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    padding: 8px 14px;
}
.profile .dashboard-content .last-notification ul li a {
    font-weight: 300;
    font-size: 12px;
    color: #000;
}
.profile .dashboard-content .last-notification ul li span {
    font-weight: 200;
    font-size: 11px;
}
.profile .dashboard-content .last-notification ul li:nth-child(odd) {
    background: #eee;
}
.archive .sidebar > div .see-more {
    font-size: 12px;
    border-top: 1px solid #d7d7d7;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-weight: 200;
    color: #b2b2b2;
}
.archive .sidebar h3 {
    color: #6b6b6b;
    font-size: 13px;
    margin: 0;
    font-family: 'Yekan-B';
    margin-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 13px 13px 5px;
    position: relative;
    z-index: 2;
}
.archive .sidebar h3 span i {
    vertical-align: middle;
    margin-left: 5px;
}
.archive .sidebar h3 span {
    font-family: 'IRANYekanX';
    color: #646464;
}
.archive .sidebar h3 > i {
    font-size: 20px;
    line-height: 0;
}
.page-head {
    margin: 0 10%;
}
.page-head span {
    font-size: 12px;
    color: #979797;
}
.page-head h3 {
    font-size: 23px;
    margin: 0;
    font-family: 'IRANYEKANX';
    font-weight: 900;
}
.assets-box-222 {
    background-color: #ffffff;
    width: 100%;
    height: 120%;
    box-shadow: 1px 1px 73px #c6c6c670;
    position: absolute;
    top: -6%;
    right: 0;
    left: 0;
    transform: skewY(0deg);
    border-radius: 25px;
    opacity: 1;
    z-index: 0;
    margin: 0 auto;
}
.page-head h4 {
    margin-top: 0;
    font-weight: 400;
    font-size: 13px;
    color: #797878;
}
.page-head {
    display: flex;
    align-items: center;
    margin-top: 15px;
}
.page-head i {
    font-size: 25px;
    margin-left: 15px;
}
.archive .sidebar ul {
    list-style: none;
    padding: 0 15px;
    margin-top: 5px;
}
.archive .sidebar ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
}
.archive .sidebar ul li img {
    width: 27px;
    height: 27px;
    border-radius: 100%;
    margin-left: 12px;
    margin-bottom: 6px;
}
.archive .sidebar ul li .count {
    font-family: 'VAZIRMATN FD';
    font-size: 13px;
    color: #9d9999;
}
.archive .content {
    display: flex;
    margin-top: 10px;
    align-items: flex-start;
}
.archive .content .sidebar {
    width: 240px;
    margin-left: 30px;
    box-shadow: 1px 1px 73px #c6c6c670;
    padding: 0 20px;
    border-radius: 33px;
}
.archive .content .sidebar .check-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.archive .content .sidebar .check-box > span {
    font-size: 12px;
    color: #7c7c7c;
    font-family: 'IRANYekanX';
    font-weight: 700;
}
.archive .content .search-lists {
    width: calc(100% - 263px) !important;
    margin-top: 2px !important;
}
.content .head .search-type-box {
    padding: 3px;
    border-radius: 9px;
    display: flex;
    align-items: center;
}
.content .head .search-type-box li {
    padding: 8px 5px 8px 15px;
    margin-left: 17px;
    list-style: none;
}
.content .head .search-type-box .active {
    border: 2px solid #000000;
    color: #000;
    border-radius: 7px;
    font-size: 12px;
    padding: 8px 15px;
}
.content .head .search-type-box li:last-of-type {
    margin-left: 0;
}
.content .head .search-type-box li a {
    display: flex;
    align-items: center;
    color: #7d7d7d;
    font-family: 'IRANYEKANX';
    font-weight: 900;
    font-size: 12px;
}
.content .head .search-type-box li.active a {
    color: #000;
}
.content .head .search-type-box li a .count {
    font-family: kalame;
    margin-right: 10px;
    font-size: 18px;
}
.content .head .search-type-box li a .icon {
    margin-left: 8px;
    line-height: 0;
}
.archive .content .search-lists .content-lists li {
    width: 24% !important;
    margin-bottom: 27px;
    margin-left: 1.3% !important;
    display: flex;
}
.archive.teachers ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.archive.teachers ul li {
    width: 14.6%;
    margin-left: 2%;
    text-align: center;
}
.archive.teachers ul li img {
    width: 100%;
    height: auto;
    border-radius: 100%;
}
.archive.teachers ul li a {
    font-family: IRANYEKANX;
    font-weight: 700;
    display: block;
    text-align: center;
    color: #000;
    font-size: 13px;
    margin: 10px 0 12px;
}
.archive .content .search-lists .head-result {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 14px;
    color: #7f7f7f;
    padding: 0 10px
}
.archive .content .search-lists .content-lists li:nth-child(4n) {
    margin-left: 0 !important;
}
.page-container {
    width: 80%;
    margin: 0 auto;
}
.contact .header {
    text-align: center;
}
.contact .header h2 {
    font-family: 'IRANYEKANX';
    margin-bottom: 10px;
    font-size: 29px;
    font-weight: 900;
}
.nav-btn-mob {
    margin-right: 13px;
    margin-top: 6px;
    font-size: 28px;
    display: none;
}
.contact .header span {
    color: #6f6f6f;
    font-size: 13px;
}
.contact .content {
    box-shadow: 1px 45px 89px #dfdfdf;
    overflow: hidden;
    width: 501px;
    margin: 40px auto 0;
    border-radius: 45px;
}
.contact .content ul {
    list-style: none;
    padding: 16px 35px 1px;
}
.contact .content img {
    width: 100%;
    margin: 0 auto;
    margin-bottom: -90px;
}
.contact .content ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: 900;
    font-family: 'IRANYEKANX';
    color: #000;
}
.contact .content ul li span:first-of-type {
    width: 130px;
}
.contact .content ul li span:first-of-type i {
    vertical-align: middle;
    margin-left: 8px;
    font-size: 18px;
}
.contact .content ul li span:last-of-type {
    max-width: calc(100% - 142px);
}
.archive .content .search-lists .content-lists {
    flex-wrap: wrap;
    margin: 0;
}
.archive .content .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    align-items: center;
}
.warning {
    background: #FFD54F;
    padding: 11px;
    font-weight: bold;
    font-size: 12px;
    border-radius: 2px;
    width: 80%;
    margin: 15px auto;
    color: #585858;
    font-family: IRANYEKANX;
}
.archive .content .head .search-top-box div {
    border: 1px solid #d8d7d7;
    padding: 4px 10px;
    border-radius: 10px;
}
.archive .content .head .search-top-box input {
    border: none;
    width: 250px;
    font-size: 12px;
}
.archive .content .head .search-top-box i {
    line-height: 0;
    color: #b7b7b7;
    cursor: pointer;
}
.archive .content .head .search-top-box i:hover {
    opacity: .6;
}
.search-top-box {
    width: 35%;
    text-align: center;
}
.search-top-box > div {
    border: 2px solid #183153;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.search-top-box input {
    border: none;
    height: 33px;
    border-radius: 25px;
    padding-right: 24px;
    background: transparent;
    font-size: 13px;
}
.search-top-box i {
    margin: 3px 0 0 2px;
}
.archive .content .head i {
    font-size: 20px;
    vertical-align: middle;
}
.archive .content .head ul {
    display: inline-block;
    padding: 0;
    margin: 0;
}
.archive .content .head ul li {
    display: inline-block;
    margin-left: 11px;
    font-size: 13px;
    padding-bottom: 4px;
}
.archive .content .head ul li a {
    cursor: pointer;
}
.archive .content .head ul li.active {
    border-bottom: 2px solid #636161;
}
.archive .content .head .counter {
    font-size: 11px;
    margin-left: 8px;
    border: 1px solid #000;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: bold;
}
.archive .content .sidebar-mob {
    display: none;
}
.archive .content .sidebar-mob .head {
    flex-wrap: wrap;
}
.archive .content .sidebar-mob .head .search-top-box div {
    margin-bottom: 21px;
}
.archive .content .sidebar-mob .head .search-type-box .active {
    color: #fff;
    border-radius: 9px;
    font-size: 12px;
    padding: 8px 6px;
}
.archive .content .sidebar-mob .head .search-top-box input {
    border: none;
    width: 197px;
    font-size: 12px;
}
.modal-rate-btn {
    font-size: 11px;
    margin-right: 10px;
    border: 1px solid #b3b3b3;
    padding: 2px 8px;
    border-radius: 8px;
    border-bottom: 4px solid #aeaeae;
    color: #565656;
    transition: all .5s;
    cursor: pointer;
}
.modal-rate-btn:hover {
    border-bottom: 1px solid #aeaeae !important;
}
.modal-backdrop {
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    background-color: rgba(0, 0, 0, .6);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 100% !important;
    z-index: 1005;
}
.modal-backdrop.on {
    display: block;
}
.global-prompt {
    min-height: 335px;
    width: 320px;
    margin: 0 auto;
}
.global-prompt.mod-sign {
    background-color: #fff;
    border-radius: 12px;
}
.global-prompt.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -236px;
    margin-left: -175px;
    padding: 0;
    visibility: hidden;
}
.global-prompt.popup.mod-sign {
    margin-top: -230px;
    margin-left: -160px;
}
.global-prompt .sign-bg {
    font-size: 0;
    margin-top: -82px;
}
.content-lists.courses {
    text-align: right;
    margin-top: 15px;
}
.content-lists.courses li {
    width: 21%;
}
.global-prompt .sign-menu {
    text-align: center;
    padding: 9px;
}
.global-prompt .sign-menu.mod-effect {
    opacity: 0;
}
.global-prompt .sign-menu.mod-effect.on {
    transition: opacity .5s ease-out;
    opacity: 1;
}
.global-prompt .sign-menu .sign-label {
    line-height: 1;
    font-size: 29px;
    color: #464656;
    font-family: Yekan;
    font-weight: 700 !important;
    margin-bottom: 8px;
    margin-top: 5px;
    font-family: 'Kalame';
}
.global-prompt .sign-menu p {
    font-size: 12px;
    color: #464656;
    margin-bottom: 9px;
}
p.error {
    background: #ffebeeb3;
    padding: 12px;
    color: #B71C1C;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    line-height: 2;
    font-family: 'IRANYEKANX';
}
p.success {
    background: #E8F5E9;
    padding: 12px;
    color: #388E3C;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    line-height: 2;
    font-family: 'IRANYEKANX';
}
.register-title-info {
    font-family: 'IRANYEKANX';
    font-weight: 400;
    font-size: 12px;
    color: #666565;
    line-height: 2;
    width: 80%;
    margin: 0 auto;
    text-align: justify;
    margin-bottom: 8px;
}
.show-pass {
    position: absolute;
    top: 57%;
    left: 15px;
    font-size: 16px;
    color: #bdbdbd;
    cursor: pointer;
}
.global-prompt .sign-menu p.mod-p {
    line-height: 20px;
}
.back-close-handle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.menu-lists {
    position: absolute;
    top: 43px;
    z-index: 9999;
    background: #fff;
    width: 200px;
    list-style: none;
    padding: 16px 23px;
    box-shadow: 1px 1px 50px #5858582b;
    font-size: 13px;
    border-radius: 5px;
    right: -75px;
    left: 15px;
    margin: 0 auto;
    display: block;
}
.cart-fix-button {
    position: fixed;
    z-index: 999;
    font-size: 22px;
    box-shadow: -3px 5px 14px #33333326;
    top: 245px;
    background: #fff;
    color: #9e9e9e;
    padding: 6px 10px;
    right: -4px;
    line-height: 0;
    border-radius: 10px 0 0 10px;
    transition: all .5s;
}
.cart-fix-button.open {
    right: 319px;
}
.cart-fix-button.hide {
    right: -70px;
}
.cart-lists-fixed ul {
    padding: 0;
}
.cart-lists-fixed h2 {
    text-align: center;
    font-size: 16px;
    font-family: 'IRANYEKANX';
    font-weight: 700;
    color: #010101;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 12px;
}
.cart-lists-fixed ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.cart-lists-fixed ul li .r-item-cart {
    display: flex;
    align-items: center;
}
.cart-lists-fixed ul li .r-item-cart img {
    width: 102px;
    border-radius: 12px;
    margin-left: 11px;
}
.cart-lists-fixed ul li .r-item-cart h3 {
    margin: 0;
    font-size: 15px;
}
.cart-lists-fixed ul li .r-item-cart h3 a {
    color: #000;
    display: inline-block;
    font-family: 'IRANYekanX';
    width: 112px;
    font-size: 13px;
}
.cart-lists-fixed ul li .r-item-cart span {
    font-weight: 100;
    font-size: 12px;
    font-family: 'Vazirmatn FD';
    color: #7e7d7d;
}
.cart-lists-fixed ul li .l-item-cart {

}
.cart-lists-fixed ul li .l-item-cart a {
    background: #ff1744;
    border-radius: 5px;
    color: #fff;
    font-family: 'IRANYEKANX';
    font-size: 10px;
    font-weight: 900;
    padding: 4px 7px;
}
.cart-lists-fixed {
    position: fixed;
    border-radius: 20px;
    right: -300px;
    padding: 0 15px;
    min-height: 300px;
    width: 300px;
    top: 10%;
    background: #fff;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all .5s;
}
.cart-lists-fixed.open {
    right: 22px;
    transition: all .5s;
}
.cart-lists-fixed.hide {
    right: -366px;
}
.modal-backdrop {
    display: none;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    background-color: rgba(0, 0, 0, .6);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 100% !important;
    z-index: 99;
}
.modal-backdrop.open {
    display: block;
}
.cart-fix-button .alert {
    position: absolute;
    background: #f6b606;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    left: -12px;
    top: -9px;
    border: 3px solid #fff;
    box-shadow: -7px -3px 14px #33333326;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Vazirmatn FD';
}
.cart-modal-wrapper .continue-btn-modal {
    display: inline-block;
    text-align: center;
    margin-bottom: 13px;
    border-radius: 7px;
    padding: 6px 0;
    font-weight: 900 !important;
    width: 37%;
    color: #979797;
    font-size: 12px;
    border: 1px solid #d1d1d1;
    font-family: 'IRANYEKANX';
}
.cart-modal-wrapper .cart-btn-modal {
    width: 60%;
    display: inline-block;
    text-align: center;
    background: #FFC400;
    margin-bottom: 10px;
    border-radius: 7px;
    padding: 6px 0;
    font-weight: 900 !important;
    color: #fff;
    font-size: 12px;
    margin-left: 3%;
    font-family: 'IRANYekanX';
}
.cart-count {
    background: #FFA000;
    font-family: 'Vazirmatn FD';
    border-radius: 100%;
    color: #fff;
    font-weight: bold;
    height: 19px;
    width: 19px;
    text-align: center;
}
.menu-lists .flex-menu a {
    display: flex;
    justify-content: space-between;
}
.menu-lists .avatar {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 23px;
    padding-left: 11px;
}
.menu-lists > div > div {
    display: flex;
    margin-bottom: 7px;
    font-size: 13px;
    align-items: center;
}
.menu-lists > div > div div:last-of-type {
    font-weight: 200;
    font-size: 12px;
    margin-top: 0;
    text-align: right;
}
.menu-lists > div > div div:last-of-type div:first-of-type {
    text-align: right;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Vazirmatn';
    margin-bottom: 5px;
}
.menu-lists > div > a {
    color: #979797;
}
.menu-lists li {
    margin-bottom: 13px;
}
.menu-lists li:last-of-type {
    margin-bottom: 0;
}
.menu-lists li a {
    color: #000;
}
.menu-lists li a i {
    margin-left: 11px;
    font-size: 16px;
    vertical-align: middle;
}
.global-prompt .sign-menu p.note {
    margin-bottom: 7px;
    font-size: 11px;
    color: #666676;
    line-height: 2;
}
.global-prompt .sign-menu p a {
    color: #56c676;
    font-weight: 900 !important;
    cursor: pointer;
    font-size: 13px;
}
.team .delete-chapter {
    bottom: 15px;
    top: unset !important;
}
.global-prompt .sign-menu p a.quince {
    color: #f6b606;
    margin-right: 8px;
}
.global-prompt .sign-menu p a.mint {
    color: #56c676;
    margin-left: 8px;
}
.global-prompt .sign-button-wrap > p {
    line-height: 48px;
    margin-bottom: 14px;
    font-size: 16px;
}
.global-prompt .sign-button-wrap .sign-input {
    position: relative;
    margin-bottom: 16px;
}
.global-prompt .sign-button-wrap .sign-input label {
    position: absolute;
    line-height: 0;
    font-size: 13px;
    color: #464656;
    font-weight: 700 !important;
}
.global-prompt .sign-button-wrap .sign-input .sign-input-text {
    min-height: 37px;
    font-size: 13px;
    width: 93%;
    margin-top: 16px;
    padding: 0 8px;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
}
.global-prompt .sign-button-wrap .sign-input a {
    position: absolute;
    left: 0;
    top: -3px;
}
.global-prompt .sign-button-wrap .sign-input .forgot-password {
    color: #a6a6a6;
    line-height: 1;
    font-size: 11px;
}
.global-prompt .sign-button-wrap .sign-input .show-password {
    top: 16px;
    line-height: 0;
    margin: 11px;
}
.global-prompt .sign-button-wrap .sign-input .sign-input-text:-moz-placeholder, .global-prompt .sign-button-wrap .sign-input .sign-input-text::-moz-placeholder {
    color: #c6c6c6 !important;
    text-overflow: ellipsis;
}
.global-prompt .sign-button-wrap .sign-input .sign-input-text:-ms-input-placeholder {
    color: #c6c6c6 !important;
    text-overflow: ellipsis;
}
.global-prompt .sign-button-wrap .sign-input .sign-input-text::-webkit-input-placeholder {
    color: #c6c6c6 !important;
    text-overflow: ellipsis;
    line-height: normal;
}
.global-prompt .sign-button-wrap .sign-btn button {
    width: 100%;
    margin-bottom: 12px;
    line-height: 40px;
    height: 40px;
    border-radius: 2px !important;
    cursor: pointer;
}
.global-prompt .sign-button-wrap .sign-btn button:hover {
    opacity: .7;
}
.global-prompt .sign-button-wrap .sign-btn button.sing-in {
    background: #FFB300;
    font-weight: bold;
}
.global-prompt .sign-button-wrap .sign-btn button.sing-up {
    background: #56c676;
    font-weight: bold;
}
.global-prompt .sign-button-wrap .sign-btn button {
    padding: 0;
    border: none;
    color: #fff;
}
.loading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000b5;
}
#loading-indicator, .loading-indicator {
    margin: 40px 0 0;
    text-align: center;
}
.loading-indicator.mint > div {
    background-color: #56c676;
}
#loading-indicator.yellow > div {
    background-color: #f6b606;
}
#loading-indicator > div, .loading-indicator > div {
    background-color: #fff;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 7px;
    vertical-align: middle;
    border-radius: 50%;
    animation: 1.4s ease-in-out 0s infinite alternate both running q;
}
#loading-indicator > div:first-child, .loading-indicator > div:first-child {
    margin-left: 0;
    animation-delay: -.32s;
}
#loading-indicator > div:nth-child(2), .loading-indicator > div:nth-child(2) {
    animation-delay: -.16s;
}
.loading-indicator {
    margin: 40px 0 0;
    text-align: center;
}
#loading-indicator > div {
    background-color: #fff;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 7px;
    vertical-align: middle;
    border-radius: 50%;
    animation: 1.4s ease-in-out 0s infinite alternate both running bouncedelay;
}
.main-head {

}
@keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
        opacity: 0;
    }
    40% {
        transform: scale(1);
        opacity: 1;
    }
}
.loading-indicator > div:nth-child(1) {
    margin-left: 0;
    animation-delay: -0.32s;
}
.loading-indicator > div:nth-child(2) {
    animation-delay: -0.16s;
}
.loading p {
    text-align: center;
    font-weight: 900;
    font-size: 21px;
    color: #fff;
    font-family: 'IRANYekanX';
}
.hidden {
    display: none;
}
.sp-aw-tapamon-login {
    background-image: url(../../../public/images/sprites-artwork@2x.b41d3f2.png);
    background-size: 1340px 1146px;
    background-position: -602px -225px;
    width: 320px;
    height: 232px;
    display: inline-block;
}
.bigEntrance {
    animation-name: i;
    -webkit-animation-name: i;
    animation-duration: 1.6s;
    -webkit-animation-duration: 1.6s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    visibility: visible !important;
}
@keyframes i {
    0% {
        transform: scale(.3) rotate(6deg) translateX(-30%) translateY(30%);
        opacity: .2;
    }
    30% {
        transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
        opacity: 1;
    }
    45% {
        transform: scale(.98) rotate(1deg) translateX(0) translateY(0);
        opacity: 1;
    }
    60% {
        transform: scale(1.01) rotate(-1deg) translateX(0) translateY(0);
        opacity: 1;
    }
    75% {
        transform: scale(.99) rotate(1deg) translateX(0) translateY(0);
        opacity: 1;
    }
    90% {
        transform: scale(1.01) rotate(0deg) translateX(0) translateY(0);
        opacity: 1;
    }
    to {
        transform: scale(1) rotate(0deg) translateX(0) translateY(0);
        opacity: 1;
    }
}
@media (max-width: 530px) {

    .comment-section .comment-streams .body-wrap {
        margin: 0;
    }

    .comment-section .comment-streams .parent-body-wrap {
        text-align: center;
    }
}
button.blue-btn {
    font-size: 12px;
    padding: 8px 15px;
    color: #484848;
    font-weight: bold;
}
.bookmark-lists {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.bookmark-lists > li {
    list-style: none;
    box-shadow: 1px 1px 73px #c6c6c68a;
    border-radius: 8px;
    overflow: hidden;
    margin: 0;
    padding: 16px 0 10px 14px;
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    width: 32%;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    margin-left: 2%;
}
.bookmark-lists > li:nth-child(3n) {
    margin-left: 0;
}
.bookmark-lists > li > div {
    display: flex;
    width: 100%;
    align-items: center;
}
.bookmark-lists > li .cover {
    width: 75px;
    height: 75px;
    overflow: hidden;
    border-radius: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 9px;
    box-shadow: 1px 1px 30px #0000002e;
}
.bookmark-lists > li .cover img {
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
}
.show-filters {
    display: none;
    cursor: pointer;
    border: 1px solid #000;
    padding: 5px 12px;
    border-radius: 6px;
    color: #3a3a3a;
    cursor: pointer;
}
.show-filters i {
    font-size: 12px !important;
    margin-left: 2px;
}
.show-filters span {
    font-size: 12px;
    font-family: IRANYEKANX;
    font-weight: 900;
}
.bookmark-lists > li > div > div:first-of-type {
    margin-right: 19px;
    text-align: center;
}
.bookmark-lists > li > div > div:last-of-type {
    margin-right: 18px;
    width: calc(100% - 115px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

a.remove-bookmark {
    background: #FF1744;
    font-weight: bold;
    width: max-content !important;
    color: #fff !important;
    text-align: center;
    padding: 2px 8px;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    font-size: 11px;
    box-shadow: 1px 2px 3px #ef9a9a;
}
.bookmark-lists > li > div a {
    color: #000;
    margin-bottom: 3px;
    display: inline-block;
    width: 100%;
}
.bookmark-lists > li > div a h4 {
    margin: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
}
.bookmark-lists > li > div > div .sub-header {
    font-size: 11px;
    color: #000000;
    margin-bottom: 7px;
    border: 1px solid #000;
    width: max-content;
    padding: 1px 5px;
    border-radius: 4px;
    font-weight: bold;
}
.bookmark-lists > li > div p {
    display: -webkit-box;
    font-size: 12px;
    opacity: 1;
    font-weight: 500 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: Yekan-B;
}
.top-ticket-type {
    display: flex;
    padding: 0;
    margin: 0;
    background: #f4f4f4;
    margin-bottom: 25px;
    border-radius: 9px;
}
.top-ticket-type li {
    width: 25%;
    text-align: center;
    list-style: none;
    padding: 15px 0;
    font-size: 13px;
    color: #797979;
    cursor: pointer;
}
.top-ticket-type li.active {
    background: #fff;
    box-shadow: 1px 0 20px #eee;
    border-radius: 11px;
}
.btn-open {
    border: none;
    background: #2979ff !important;
    padding: 7px 21px !important;
    border-radius: 3px !important;
    font-family: 'IRANYEKANX';
    color: #fff !important;
    font-weight: 900 !important;
    cursor: pointer;
}
.btn-open:hover {
    opacity: .8;
}
.dashboard-page form {
    position: relative;
    box-shadow: 1px 1px 30px #9593932b;
    padding: 24px 10px;
}
.dashboard-page form.edit-form {
    display: flex;
}
.dashboard-page form label {
    line-height: normal;
    margin-bottom: 15px;
    display: inline-block;
    width: 49%;
    margin-right: 1%;
}
.dashboard-page .change-pass label {
    width: 32%;
}
.dashboard-page form label span {
    color: #000;
    margin: 11px 7px 10px;
    display: inline-block;
    font-size: 13px;
    font-family: 'IRANYekanX';
    font-weight: bold;
}
input:focus-visible, textarea:focus-visible {
    outline: none;
}
.send-comment-box {
    text-align: center;
    margin: 15px 0 0;
}
.send-comment-box span {
    font-weight: 200;
    margin-left: 10px;
    font-size: 12px;
}
.send-comment {
    background: #0385fe;
    border: none;
    display: inline-block;
    text-align: center;
    padding: 6px 10px;
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    box-shadow: 1px 2px 3px #a8bfff;
    cursor: pointer;
}
.dashboard-page form label div {
    border-bottom: 1px solid #0f0f0f1c;
    width: 99%;
    margin-left: 15px;
    position: relative;
    padding: 4px 19px 3px 3px;
    box-sizing: border-box;
}
.dashboard-page form label div.disable {
    background: #f6f6f6;
    border-bottom: none;
}
.dashboard-page .dashboard-body h3 {
    color: #ffffffc9;
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 30px;
}
.dashboard-page form label div input {
    border: none;
    line-height: 2.5;
    vertical-align: top;
    width: 100%;
    background: transparent;
    font-size: 12px;
    color: #2e2e2e;
}
.dashboard-page form button:hover {
    opacity: .7;
}
.dashboard-page form label div textarea {
    background: transparent;
    border: none;
    width: 100%;
    height: 100px;
    padding-top: 10px;
    font-size: 12px;
    color: #3d3d3d;
}
.dashboard-page form label i.fa-eye, .dashboard-page form label .fa-eye-slash {
    position: absolute;
    left: 12px;
    top: 12px;
    color: #c6c6c6;
    cursor: pointer;
}
.avatar-wrapper {
    text-align: center;
    border-radius: 100%;
    overflow: hidden;
    width: max-content !important;
    margin: 0 auto 18px !important;
    display: block !important;
    position: relative;
    cursor: pointer;
    height: 200px;
}
.avatar-wrapper input {
    display: none;
}
.avatar-wrapper span {
    display: none !important;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff !important;
    background: #00000080;
    margin: 0 !important;
    padding: 7px 0;
    font-weight: 700;
    font-size: 17px !important;
}
.avatar-wrapper img {
    width: 200px;
    height: 200px;
}
.home-elements_items_block_component__items > div:last-of-type {
    display: none;
}
.home-elements_items_block_component__items > div:nth-child(4) {
    margin-top: 20px;
}
.avatar-wrapper:hover span {
    display: block !important;
}

.amt-offer-discount .offer .content-lists li {
    flex: 0 0 23.5%;
    margin-left: 1.5%;
}
.amt-offer-discount .offer.full .content-lists li {
    flex: 0 0 19%;

}
.bottom-sidebar {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    justify-content: space-between;
    background: #ffff;
    z-index: 99;
    box-shadow: 1px 1px 55px #9593936b;
    display: none;
    padding: 11px;
    box-sizing: border-box;
}
.single .content-wrapper .head .left-head {
    display: none;
}
.single .content-wrapper .head .left-head a:last-of-type {
    border: none;
    color: #fff;
    width: max-content;
    margin-right: 10px;
}
.single .content-wrapper .head .left-head .price {
    font-size: 26px;
}
.bottom-sidebar .buy-btn {
    line-height: 1;
}
.bottom-sidebar .buy-btn a {
    width: max-content !important;
    border: none !important;
    color: #fff !important;
}
.amt-offer-discount .offer-992 {
    display: none;
}
.discount-box.mob {
    display: none;
}
.search-sub {
    margin-left: 8px;
    margin-top: 5px;
    cursor: pointer;
    color: #848484;
}
.search-sub:hover {
    opacity: .5;
}
.mb-action-cart {
    display: none;
}
.sub-title-article {
    font-size: 12px;
    color: #a8a8a8;
    margin-bottom: 20px;
    font-weight: 300;
}
.mob-arrow {
    display: none !important;
}
.sub-title-article span {
    display: inline-block;
}
.article-wrapper .inner {
    display: flex;
    margin-top: 20px;
}
.article-wrapper .inner > div:first-of-type .cover {
    position: relative;
    width: 300px;
}
.top-header .login {
    background: #2a79ff;
    box-shadow: 0 8px 7px -8px #0668e1;
    color: #fff;
}
span.effect::before {
    background: #0c6fe9;
}
.article-wrapper .inner > div:first-of-type .cover ul {
    list-style: none;
}
.star-article {
    display: flex;
    align-items: center;
}
.star-article span {
    margin-left: 13px;
    font-size: 12px;
    color: #828282;
}
.article-wrapper .inner > div:last-of-type {
    width: 80%;
    margin: 0 auto;
}
.article-wrapper .inner .article-box > div {
    display: flex;
    align-items: center;
}
.article-wrapper .inner .article-box > div > div:last-of-type {
    width: calc(100% - 300px);
    padding-right: 25px;
}
.article-wrapper .inner .article-box > div > div:last-of-type .bt {
    display: flex;
}
.article-wrapper .inner .article-box > div > div:last-of-type .bt .cat {
    margin-left: 25px;
    font-size: 12px;
}
.article-wrapper .inner .article-box > div > div:last-of-type .bt .cat span {
    display: inline-block;
    margin-left: 3px;
    color: #828282;
}
.article-wrapper .inner .article-box > div > div:last-of-type .bt .cat a {
    color: #838383;
    cursor: pointer;
    font-family: 'IRANYekanX';
    font-weight: bold;
}
.star-wrapper i {
    color: rgb(222, 222, 222);
    cursor: pointer;
    font-size: 16px;
}
.star-wrapper i.active {
    color: rgb(255, 196, 0);
}
.star i {
    font-size: 13px;
    margin-left: 3px;
    cursor: pointer;
    color: rgb(222, 222, 222);
}
.star i.active {
    color: #FFD600;
}
.star-wrapper i:hover, .star i:hover {
    color: #FFD600;
}
.star-wrapper:hover i, .star:hover i {
    color: #FFD600;
}
.star-wrapper i:hover ~ i, .star i:hover ~ i {
    color: rgb(222, 222, 222);
}
.article-wrapper .inner .article-box .single-section.description div {
    font-size: 13px;
    line-height: 2;
    text-align: justify;
    width: 100%;
    padding-right: 0;
    margin-bottom: 25px;
}
.single-section.description .teacher {
    display: flex;
    align-items: center;
}
.single-section.description .teacher img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    filter: grayscale(1);
}
.single-section.description .teacher h4 {
    font-family: 'IRANYEKANX';
    font-weight: bold;
    font-size: 13px;
    margin: 0;
    color: #353535;
    width: max-content;
    position: relative;
    margin-left: 11px;
    display: inline-block;
}
.single-section.description .teacher h4 a {
    font-family: 'IRANYekanX';
    color: #000;
    font-weight: 900;
}
.single-section.description .teacher h4::before {
    content: '';
    position: absolute;
}
.single-section.description .teacher div.desc {
    color: #515151;
}
.article-wrapper .inner .article-box .single-section.description div img {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    height: auto;
}
.comment-form {

}
.comment-form form {
    width: 90%;
    margin: 0 auto;
    border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    filter: none;
    padding: 4px 4px;
}
.comment-form form img {
    border-radius: 8px;
    width: 40px;
    height: 40px;
}
.comment-form form div {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #e5e5e5;
    padding: 5px;
    border-radius: 5px;
}
.comment-form form textarea {
    border: none;
    background: transparent;
    width: calc(100% - 65px);
    line-height: 2;
    height: 30px;
    margin-right: 10px;
    font-size: 13px;
    display: flex;
    font-family: Yekan-B;
    margin-top: 4px;
}
.content-lists li .ft > div span.free {
    background: #00c853;
    border-radius: 17px;
    color: #fff;
    font-family: IRANYEKANX;
    font-weight: 900;
    padding: 1px 6px;
}
.article-star {
    font-family: 'Kalame';
    font-size: 31px;
    line-height: 0;
    color: #a4a4a4;
}
.article-star span {
    margin: 0;
    color: #a4a4a4;
    font-size: 18px;
    font-family: 'Kalame';
}
.comment-form form button {
    margin: 0;
    border-radius: 7px;
    margin-left: 1px;
    margin-right: 10px;
    height: 30px;
    width: 90px;
    font-size: 12px;
    background: #0385fe;
    box-shadow: 1px 2px 3px #a8bfff;
}
.amt-article-wrapper .home-elements_style_item_card_component__root {
    display: flex;
    flex-direction: column;
}
.amt-article-wrapper .home-elements_style_item_card_component__preview {
    width: 94%;
    margin: 8px auto 0;
    border-radius: 17px;
    overflow: hidden;
}
.amt-article-wrapper .home-elements_style_item_card_component__details {
    width: 95%;
    margin: 10px auto;
}
.article-wrapper .inner .article-box h1 {
    font-family: 'IRANYEKANX';
    font-weight: 900;
    margin-bottom: 12px;
    font-size: 22px;
}
#root_raychat #animation-root {
    border-radius: 100% !important;
    box-shadow: 1px 1px 55px #e2e2e2 !important;
}
#root_raychat .raychat_greeting_message_container p {
    font-family: IRANYEKANX;
}
#najva-bell {
    left: -20px !important;
    right: auto !important;
    bottom: 5px !important;
}
#najva-popup-text {
    color: #3e3c3c !important;
    font-family: 'IRANYEKANX' !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    margin: 10px !important;
}
.najva-popup-button {
    font-weight: 900 !important;
    font-family: 'IRANYEKANX' !important;
    font-size: 14px !important;
    padding: 8px 25px !important;
    cursor: pointer;
}
#root_raychat #animation-root .raychat_luancher_centered img:last-of-type {
    width: 30px !important;
}
#root_raychat .raychat_greeting_greeting_avatar {
    display: none !important;
}
.dashboard-page form.edit-form label:last-of-type, .dashboard-page form.edit-financial label:last-of-type {
    width: 100%;
}
.dashboard-page form.change-pass label:last-of-type {
    width: 33%;
}
.sub-title-article span:first-of-type {
    margin-left: 10px;
    padding-left: 10px;
}
.wallet-wrapper.request-wallet {
    width: 245px;
}
.wallet-wrapper.request-wallet .wallet-btn {
    align-self: center;
}
.wallet-wrapper.request-wrapper {
    width: calc(100% - 270px);
    justify-content: flex-start;
}
.single .extra-info {
    display: none;
}
.single .extra-info .share {
    width: 91px;
    display: flex;
    justify-content: center;
    margin-left: 24px;
}
.single .extra-info .share ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
}
.single .extra-info .title-section {
    margin: 0;
}
.single .extra-info .share ul li {
    margin-left: 13px;
    font-size: 18px;
}
@media (max-width: 500px) {
    button.blue-btn {
        margin-top: 6px;
    }

    .dashboard-page form label {
        width: 100%;
    }
}
@media (max-width: 1590px) {
    .embla__slide {
        flex: 0 0 23.5%;
    }

    .offers > div > div:first-of-type {
        width: 305px;
    }

    .offers > div > div:last-of-type {
        width: calc(100% - 320px);
    }

    .offers > div > div:first-of-type svg {
        left: 15px !important;
    }
}
@media (max-width: 1550px) {
    .amt-article .embla__slide {
        flex: 0 0 23.5%;
        width: 23.5%;
    }

    .archive .content .search-lists .content-lists li {
        width: 32% !important;
    }

    .search .character {
        margin-right: 15px;
    }

    .search .b3-head {
        width: calc(100% - 784px);
    }
}
@media (max-width: 1500px) {
    .amt-offer-discount .offer .content-lists li {
        flex: 0 0 32%;
    }
}

@media (max-width: 1450px) {
    .why-tpay__title-sec {
        padding: 6px 19px;
    }

    .offers > div > div:first-of-type {
        width: 270px;
    }

    .offers > div > div:last-of-type {
        width: calc(100% - 299px);
    }

    .stepsWrapper {
        padding: 2px 26px;
    }

    .home-elements_style_item_card_component__preview {
        width: 195px;
    }

    .home-elements_style_item_card_component__details {
        width: calc(100% - 206px);
    }

    .search .right-side {
        width: 365px;
    }

    .search .b3-head {
        width: calc(100% - 728px);
    }
}
@media (max-width: 1400px) {
    .offers > div > div:last-of-type .content-lists {
        margin-top: -12px !important;
    }

    .search .b3-head {
        width: calc(100% - 666px);
        margin-right: 75px;
    }

    .search h2 {
        margin-bottom: 0;
    }

    .search h3 {
        margin-bottom: 5px;
    }

    .all-course {
        background: #fcbf17;
        font-weight: 1000;
        margin-left: 12px;
        padding: 13px 29px;
        border-radius: 9px;
        color: #373737;
        font-family: 'IRANYEKANX';
        font-size: 12px;
        margin-bottom: 15px;
        display: inline-block;
        margin-top: 7px;
    }

    .back-character {
        width: 250px;
        height: 250px;
    }

    .search h2 span {
        font-size: 33px;
    }

    .search .right-side {
        width: 339px;
    }


    .search .character > div:nth-child(2) {
        width: 330px !important;
    }

    .archive .content .search-lists .content-lists li {
        width: 32% !important;
    }

    .search .character {
        width: 300px;
    }

    .back-right {
        width: 143%;
        height: 172%;
        top: -81%;
        right: -33%;
    }

    .archive .content .search-lists .content-lists li:nth-child(4n) {
        margin-left: 1.3% !important;
    }

    .offers > div > div:first-of-type {
        width: 360px;
    }

    .archive .content .search-lists .content-lists li:nth-child(3n) {
        margin-left: 0 !important;
    }

    .offers .embla__slide {
        flex: 0 0 48%;
    }

    .offers > div > div:last-of-type {
        width: calc(100% - 444px);
    }
}
@media (max-width: 1300px) {
    .content .lasts, .amt-offer-discount, .why-tpay .container, .amt-article, .top-header, .offers, .advertise, .row-t {
        width: 90%;
        max-width: 90%;
    }

    .archive {
        padding: 1px 5%;
    }

    .page-head {
        margin: 20px 5% 0;
    }


    .back-right {
        width: 137%;
        height: 168%;
        top: -79%;
        right: -37%;
        border-radius: 156px;
    }

    .single .course-menu.fixed {
        right: 15%;
        width: calc(78% - 315px);
    }

    .teachers {
        width: 65%;
    }

    .archive.teachers {
        width: 100%;
        margin-top: 0;
    }

    .archive.teachers .content .search-lists {
        width: 100% !important;
        margin-top: 2px !important;
    }

    .row-t .top-lists > div:first-of-type {
        width: 100%;
    }

    .row-t .top-lists > div:last-of-type {
        width: 100%;
        margin-right: 0;
    }

    .amt-article_textArea {
        width: 100%;
    }

    .home-elements_items_block_component__items {
        width: 100%;
    }

    .amt-article .embla__slide {
        flex: 0 0 32%;
        width: 32%;
    }

    .home-elements_style_item_card_component__preview {
        width: 175px;
    }

    .home-elements_style_item_card_component__details {
        width: calc(100% - 187px);
    }

    .search {
        width: 90%;
    }

    .course-video {
        width: 90%;
    }

    .profile .content {
        width: 90%;
    }

    .why-tpay__item-img img {
        width: 45px;
    }

    .why-tpay__item-title {
        font-size: 14px;
    }

    .why-tpay__title-sec {
        padding: 1px 8px;
    }

    .single {
        width: 90%;
    }

    .single .sidebar.fixed {
        left: 6%;
    }

    .single .sidebar.abs {
        left: 13px;
    }

    .why-amt-back {
        left: 70px;
    }

    .search form label {
        width: 60%;
    }
}
@media (max-width: 1200px) {
    .cart-page {
        width: 90%;
    }

    .search .character {
        margin-left: -83px;
    }

    .single .course-menu.fixed {
        right: 13%;
        width: calc(81% - 315px);
    }

    .single .course-menu li a {
        font-size: 12px;
    }

    .single .course-menu.fixed li {
        margin-left: 0;
    }

    .offers > div > div:first-of-type {
        width: 371px;
        margin-left: 35px;
    }

    .profile .content .sidebar {
        width: 210px;
    }

    .profile .content .sidebar > div > a {
        display: none;
    }

    .profile .content .content-wrapper {
        width: calc(100% - 210px);
    }

    .single .content-wrapper .body .inner > div:nth-child(2) ul li {
        margin-bottom: 15px;
    }

    .advertise > div:first-of-type {
        width: 490px;
    }

    .advertise > div:last-of-type {
        width: calc(100% - 490px);
    }

    .advertise .routes li img {
        width: 176px;
    }

    .home-elements_style_item_card_component__preview {
        width: 175px;
    }

    .offers > div > div:last-of-type {
        width: calc(100% - 400px);
    }

    .archive .content .search-lists {
        width: calc(100% - 269px) !important;
    }

    .archive {
        width: 100%;
    }

    .archive .content .search-lists .content-lists li {
        width: 49% !important;
        margin-left: 2% !important;
    }

    .archive .content .search-lists .content-lists li:nth-child(3n), .archive .content .search-lists .content-lists li:nth-child(4n) {
        margin-left: 2% !important;
    }

    .archive .content .search-lists .content-lists li:nth-child(2n) {
        margin-left: 0 !important;
    }

    .cart-page .carts {
        width: 65%;
    }

    .cart-page .results {
        width: 31%;
    }

    .single .content-wrapper .body .inner > div:nth-child(2) ul {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

}
@media (max-width: 1100px) {
    .embla__slide {
        flex: 0 0 32%;
    }

    .offers > div > div:last-of-type {
        width: calc(100% - 330px);
    }

    .offers > div > div:first-of-type {
        width: 297px;
        margin-left: 35px;
    }

    .head-course-info {
        display: none;
    }

    .all-course {
        margin: 16px 10px 25px;
    }

    .back-right {
        width: 156%;
        height: 168%;
        top: -78%;
        right: -44%;
        border-radius: 310px;
    }

    .top-header .back {
        width: 100%;
        left: 0;
    }

    .search {
        width: 89%;
    }

    .dashboard-page form.edit-form {
        flex-direction: column;
    }

    .dashboard-page form.edit-form > div {
        width: 100% !important;
    }

    .profile .content .top-boxes, .dashboard-content {
        flex-wrap: wrap;
    }

    .profile .content .top-boxes li:nth-child(1) {
        width: 100%;
    }

    .profile .content .top-boxes li, .profile .content .top-boxes li:nth-child(2) {
        width: 49%;
    }

    .profile .content .top-boxes li:nth-child(1) > div {
        clip-path: url(#card-info-path-center);
    }

    .profile .content .top-boxes li:nth-child(2) > div {
        clip-path: url(#card-info-path-ltr);
    }

    .search h3 {
        font-size: 13px;
    }
}
@media (max-width: 1100px) {
    .teachers {
        width: 100%;
    }

    .b3-head {
        display: none;
    }

    .search {
        width: 87%;
        justify-content: space-between;
    }

    .search .character > div:nth-child(2) {
        width: 400px !important;
        margin-top: -50px
    }

    .search .character {
        margin-left: 0;
    }

    .search .right-side {
        width: 440px;
    }

    .search .right-side .search {
        margin: 1px auto 0;
        display: block;
    }

    .row-t .tops {
        width: 100%;
        margin-right: 0;
    }

    .row-t {
        flex-wrap: wrap;
    }

    .row-t .top-lists {
        display: flex;
        width: 100%;
    }

    .row-t .top-lists > div {
        width: 48% !important;
        flex: 0 0 48%;
    }

    .row-t .top-lists > div:first-of-type {
        margin-left: 2%;
    }
}
@media (max-width: 1030px) {
    .offers > div > div:first-of-type {
        width: 80%;
        position: relative;
        margin-bottom: 105px;
    }

    .offers > div > div:last-of-type {
        width: calc(100% - 294px);
    }

    .offers > div > div:first-of-type {
        width: 261px;
        margin-left: 35px;
    }

    .offers > div > div:first-of-type {
        width: 310px;
        position: relative;
        margin-bottom: 5px;
    }

    .offers .embla__slide {
        flex: 0 0 49%;
        margin-left: 2% !important;
    }

    .amt-offer-discount .offer .section-title-wrap {
        margin-right: 0;
    }

    .offers .character {
        display: none;
    }

    .amt-article-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .profile .content .content-wrapper {
        width: calc(100% - 84px);
    }

    .course-video {
        flex-direction: column;
    }

    .course-video > div:last-of-type {
        width: 100%;
        margin-right: 0;
        padding-top: 0;
    }

    .course-video p {
        margin-bottom: 0;
    }

    .course-video > div:first-of-type {
        width: 100%;
    }

    .course-video .single .sidebar {
        display: block !important;
        position: initial;
        box-shadow: 1px 36px 30px #9593932b;
    }

    .amt-offer-discount .offer {
        width: 65%;
    }

    .amt-offer-discount .offer .content-lists li {
        flex: 0 0 49%;
    }

    .amt-offer-discount .discount {
        width: 35%;
    }

    .why-tpay .active .why-tpay__item-description, .why-tpay__title-sec:before {
        display: none;
    }

    .why-tpay__item {
        width: 30%;
    }

    .why-tpay__title-sec {
        width: 100%;
    }

    .why-tpay .active .why-tpay__title-sec {
        padding: 6px 19px;
    }

    .single .content-wrapper .body .inner > div:nth-child(2) ul {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 992px) {
    .show-filters {
        display: block;
    }

    .single .course-menu.he {
        display: flex;
        list-style: none;
        margin: 25px 0 15px;
        justify-content: center;
        border-bottom: 0.5px solid #e4e4e4;
        padding-bottom: 17px;
    }

    .single .course-menu.fixed {
        right: 5%;
        width: 90%;
    }

    .single .course-menu.fixed li {
        margin-left: 4%;
    }

    .single .course-menu li a {
        font-size: 13px;
    }

    .trailer .video-js.vjs-fluid {
        height: 400px !important;
    }

    .rate-wrapper {
        position: unset;
        padding-left: 0;
        margin: 8px 0;
    }

    .single .content-wrapper .body {
        margin-top: 20px
    }

    .single .content-wrapper .head h3 {
        margin-bottom: 0;
    }

    .single .content-wrapper .head .extra h3 {
        font-family: 'IRANYEKANX';
        font-weight: 700;
        font-size: 15px;
    }

    .stepsWrapper .footer-tour .tour-func {
        padding: 0 5px;
    }

    .single .content-wrapper > .head {
        box-shadow: 1px 1px 73px #c6c6c670;
        padding-top: 26px;
        position: relative;
        margin-top: 30px;
        padding-bottom: 20px;
        border-radius: 30px;
    }

    .assets-box-4, .assets-box-5 {
        display: none;
    }

    .trailer .popup-unlock {
        width: 80%;
    }

    .embla__slide {
        flex: 0 0 32.3%;
    }

    .top-header .back {
        width: 95%;
        left: 0;
        margin: 0 auto;
        right: 0;
    }

    .archive .content .sidebar {
        width: 240px;
        margin-left: 30px;
        padding: 0 20px;
        border-radius: 33px;
        box-shadow: none;
        transition: all .5s;
        position: fixed;
        right: -300px;
        top: 11px
    }

    .single .extra-info {
        display: flex;
    }

    .single .extra-info .extra {
        width: 100%;
    }

    .single .extra-info .header {
        display: flex;
        justify-content: space-between;
        background: #f4f4f47a;
        width: 111%;
        margin-right: -4%;
        padding: 0 18px;
        margin-top: 10px;
    }

    .single .extra-info .share {
        width: 91px;
        display: flex;
        justify-content: center;
        margin-top: 6px;
    }

    .single .extra-info .extra .other-info ul {
        display: flex;
        list-style: none;
        padding: 0;
        font-size: 14px;
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    .single .extra-info .extra .other-info ul li {
        width: 33%;
        margin-bottom: 20px;
    }

    .single .extra-info .extra ul li div span {
        font-family: 'IRANYEKANX';
        font-weight: bold;
        font-size: 13px;
        vertical-align: text-top;
        margin-right: 10px;
    }

    .single .extra-info .extra ul li > span, .single .extra-info .extra ul li > a {
        font-size: 12px;
        color: #595757;
        font-weight: 300;
    }

    .single .content-wrapper .body .inner > div:nth-child(2) {
        display: none;
    }

    .single .content-wrapper .body .inner > div:nth-child(3) {
        width: 100%;
    }

    .single .head-info > div:last-of-type {
        width: calc(100% - 190px);
    }

    .single .head-info .trailer {
        display: block;
        width: 227px;
        height: 137px;
    }

    .search {
        width: 91%;
        z-index: 0;
    }

    .advertise {
        flex-direction: column;
        margin: 30px auto;
    }

    .advertise > div:first-of-type {
        width: 100%;
        margin-bottom: 40px;
    }

    .stepsWrapper {
        margin-bottom: 40px;
    }

    .advertise .routes {
        flex-direction: row;
    }

    .advertise .routes li {
        width: 49%;
        height: 117px;
    }

    .advertise .routes li p {
        width: 53%;
    }

    .advertise .routes li:nth-child(1) {
        margin-left: 2%;
    }

    .advertise > div:last-of-type {
        width: 100%;
        padding: 0;
    }

    .home-elements_style_item_card_component__preview {
        width: 200px;
    }

    .archive .content .head > div:first-of-type {
        display: none;
    }

    .archive .content .sidebar-mob .head > div:first-of-type {
        display: block;
    }

    .archive .content .search-lists {
        width: 100% !important;
    }

    .archive .content .search-lists .content-lists li {
        width: 32% !important;
        margin-left: 2% !important;
    }

    .archive .content .search-lists .content-lists li:nth-child(2n), .archive .content .search-lists .content-lists li:nth-child(4n) {
        margin-left: 2% !important;
    }

    .archive .content .search-lists .content-lists li:nth-child(3n) {
        margin-left: 0 !important;
    }

    .archive .content .sidebar.active {
        position: fixed;
        background: #fff;
        top: 11px;
        right: 25px;
        padding: 5px 15px;
        width: 280px;
        z-index: 1006;
        height: 95%;
        display: block;
        overflow: scroll;
    }

    .archive .sidebar div.sid-box {
        padding: 0;
        margin-top: 15px;
    }

    .archive .sidebar h3 {
        margin-left: 0;
    }

    .assets-box-222 {
        box-shadow: none;
    }

    .archive .content .sidebar-mob, .archive .content .sidebar-mob .head {
        display: block;
    }

    .article-wrapper .inner > div:last-of-type {
        width: 100%;
    }

    .amt-offer-discount {
        flex-wrap: wrap;
    }

    .cart-page > div {
        flex-direction: column;
    }

    .cart-page .carts {
        width: 100%;
        box-sizing: border-box;
    }

    .cart-page .cart-btn, .cart-page .cart-btn-continue {
        width: 49%;
    }

    .cart-page .cart-btn {
        width: 49%;
        padding: 8px;
    }

    .cart-page .cart-btn-continue {
        margin-right: 2%;
        width: 49%;
    }

    .cart-page .results {
        width: 100%;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .single .sidebar {
        display: none;
    }

    .single .content-wrapper .head .left-head {
        display: flex;
        flex-direction: row-reverse;
        margin: 0 0 0 25px;
        align-items: center;
        justify-content: space-between;
    }

    .single .content-wrapper {
        width: 100%;
    }

    .single .content-wrapper .body {
        margin-left: 0;
    }

    .single .content-wrapper .head {
        padding: 20px 27px;
    }

    .assets-box-4 {
        right: 0;
    }

    footer .footer-top {
        width: 90%;
    }

    .top-header {
        width: 100%;
        max-width: 100%;
        padding: 15px 35px 10px;
        z-index: 99;
    }

    .menu-lists {
        left: -11px;
        right: auto;
    }

    .advertise {
        margin: 15px auto;
    }

    .top-header.o {
        padding: 15px 35px 10px;
    }

    .top-header .back {
        width: 100%;
        border-radius: 0;
    }
}
@media (max-width: 920px) {
    .offers .embla__slide {
        flex: 0 0 100%;
        margin-left: 0 !important;
    }

    .offers > div > div:last-of-type {
        width: calc(100% - 480px);
    }

    .offers > div > div:first-of-type {
        width: 380px;
    }
}
@media (max-width: 860px) {
    footer .footer-top .r-2 ul li {
        width: 100%;
    }

    .search {
        width: 100%;
    }

    .search .right-side {
        width: 100%;
        background: #f5f5f5;
        padding: 35px 8px;
    }

    .all-course {
        margin: 16px 10px 25px;
    }

    .search h2 span {
        text-align: center;
        margin-bottom: 11px;
    }

    .offers > div > div:last-of-type {
        width: calc(100% - 436px);
    }

    .archive.teachers ul li {
        width: 18%;
        margin-left: 2%;
    }

    .arrow-search {
        display: none;
    }

    .search .right-side > div:last-of-type {
        text-align: center;
    }

    .back-right {
        width: 300%;
        height: 180%;
        top: -93%;
        right: -100%;
        border-radius: 0;
        transform: rotate(0);
    }

    .amt-article .embla__slide {
        margin: 0 0px;
    }

    .nav-btn-mob {
        display: inline-block;
    }

    .advertise .routes li p {
        width: calc(100% - 170px);
    }

    .advertise .routes li img {
        height: auto;
    }

    .search .character {
        display: none;
    }

    .search .right-side {
        margin: 45px auto 0;
    }

    .head-course-info {
        text-align: center;
        justify-content: center;
    }

    .top-header .back {
        height: 668px;
    }

    .head-course-info li {
        width: 44%;
        margin-bottom: 12px;
        margin-left: 0;
    }

    .search.search-box {
        width: 87%;
        margin-top: -14px;
    }

    .search-ex {
        margin-right: 91px;
        text-align: center;
    }

    .search form {
        width: 100%;
    }

    .embla__slide {
        flex: 0 0 48.5%;
        margin-left: 2.5% !important;
    }

    .article-wrapper .inner .article-box > div {
        flex-direction: column;
    }

    .article-wrapper .inner .article-box > div > div:last-of-type {
        width: 100%;
        margin-bottom: 35px;
        padding-right: 0;
    }

    .article-wrapper .inner > div:first-of-type .cover {
        width: 70%;
        margin: 0 auto;
    }

    footer .footer-top .r-2 {
        margin-right: 26px;
    }

    footer .footer-top .r-3 form input {
        width: 80%;
    }

    .advertise .routes {
        flex-direction: column;
    }

    .advertise .routes li {
        width: 100%;
        height: auto;
    }

    .discount-box:not(.mob) {
        display: none;
    }

    .discount-box.mob {
        display: flex;
    }

    .bookmark-lists > li {
        width: 100%;
    }

    .bookmark-lists > li:nth-child(odd) {
        margin-left: 0;
    }

    .profile .dashboard-content .last-notification {
        width: 100%;
    }

    .profile .content .top-boxes li:nth-child(1) > div {
        clip-path: url(#card-info-path-center);
    }

    .profile .content .top-boxes li:nth-child(2) > div {
        clip-path: url(#card-info-path-ltr);
    }

    .profile .content .top-boxes li:nth-child(1) {
        width: 100%;
    }

    .profile .content .top-boxes, .dashboard-content {
        flex-wrap: wrap;
    }

    .profile .content .top-boxes li, .profile .content .top-boxes li:nth-child(2) {
        width: 49%;
    }

    .amt-article_textArea {
        width: 100%;
    }

    .home-elements_items_block_component__items > div:nth-child(4) {
        margin-top: 0;
    }

    .home-elements_items_block_component__items > div:last-of-type {
        display: inline-block;
    }

    .home-elements_items_block_component__items > div {
        width: 48%;
    }

    .home-elements_items_block_component__items > div:first-of-type {
        margin-top: 0;
    }

    .amt-offer-discount .offer {
        width: 100%;
    }

    .amt-offer-discount .discount {
        width: 100%;
        display: flex;
        margin-right: 0;
    }

    .amt-offer-discount .discount .list-discounts {
        display: flex;
    }

    .discount-box .footer > div ul li span {
        font-size: 19px;
    }

    .discount-box .footer > div .price span:first-of-type {
        font-size: 17px;
    }

    .amt-offer-discount .discount .list-discounts > div {
        width: 50%;
        background: #fff;
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 10px;
    }

    .discount-box.mob {
        box-shadow: 1px 29px 40px #d4d4d461;
        padding: 10px 10px 0;
    }

    .discount-box {
        border-radius: 5px;
    }

    .why-tpay {
        margin-top: 20px;
    }

    .amt-offer-discount {
        margin: 0 auto 60px;
    }

    .amt-offer-discount .offer .content-lists li {
        flex: 0 0 33%;
    }

    .back-offer {
        top: -27px;
        left: -37px;
        right: 0;
        width: 465px;
    }

    .search .right-side {
        margin-bottom: 15px;
    }

    .search h2, .search form {
        margin: 0 auto;
        text-align: center;
    }

    .search h3 {
        text-align: center;
    }

    .nav-lists {
        display: none;
    }

    .nav-lists {
        position: fixed;
        display: flex;
        flex-direction: column;
        z-index: 999;
        background: #fff;
        top: 82px;
        box-shadow: 1px 1px 30px #484848;
        width: 171px;
        text-align: center;
        padding: 18px;
        border-radius: 25px;
        right: -250px;
        transition: all .5s;
    }

    .nav-lists li {
        display: inline-block;
        margin-left: 0;
        position: relative;
        padding: 7px;
        margin-bottom: 12px;
    }

    .nav-lists.active {
        right: 30px
    }

}
@media (max-width: 800px) {
    .offers > div > div:first-of-type {
        width: 340px;
    }

    .offer-back img {
        right: 191px;
        width: 187%;
    }

    .archive .content .search-lists .content-lists li {
        width: 48% !important;
    }

    .profile .content .sidebar .mobile-menu {
        display: flex;
    }

    .profile .content .sidebar .avatar {
        display: none;
    }

    .profile .content .sidebar {
        position: fixed;
        top: 55px;
        right: -214px;
        z-index: 999999;
        filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, .10));
        transition: all .5s;
    }

    .row-t .top-lists > div {
        width: 80% !important;
        flex: 0 0 80%;
    }

    .row-t .top-lists > div:last-of-type {
        margin-right: 20%;
    }

    .row-t .top-lists {
        flex-wrap: wrap;
    }

    .profile .content .content-wrapper {
        width: 100%;
        padding: 0;
    }

    .profile .content .sidebar.active {
        right: 20px;
    }

    .offers > div > div:last-of-type {
        width: calc(100% - 400px);
    }

    .search {
        width: 100%;
        justify-content: center;
    }

    .relative.character {
        display: none;
    }

    .translator .relative.character {
        display: block;
    }

    .translator .relative.character > div {
        height: 235px !important;
        margin-left: -31px !important;
        margin-top: -100px !important;
    }

    .translator {
        width: 500px;
        margin: 80px auto 0;
    }

    .translator .back div {
        margin-right: 24px;
    }

    .translator .back img {
        position: absolute;
        left: 22px;
        bottom: 6px;
        width: 203px;
    }

    .search-top-box {
        width: 60%;
    }

    .archive {
        padding: 1px 5%;
    }

    .page-head {
        margin: 25px 5% 0;
    }

    .amt-article .embla__slide {
        flex: 0 0 49%;
        width: 49%;
    }
}
@media (max-width: 750px) {
    .row-t {
        margin: 50px auto 55px;
    }

    .dashboard-page .change-pass label, .dashboard-page form.change-pass label:last-of-type {
        width: 99%;
    }

    .course-video .video-header {
        padding: 0 25px;
        margin: 0 5px 15px 0;
    }

    .single .extra-info .header {
        width: 111%;
        margin-right: -5%;
    }

    .content-lists {
        margin-bottom: 20px;
    }

    .offers {
        margin: 0 auto 30px;
        padding: 6px 0;
    }

    .offers .nav-btn {
        display: none;
    }

    .offers .mob-arrow {
        display: block !important;
    }

    .offers .mob-arrow .nav-btn {
        position: absolute;
        display: block;
    }

    .offers .mob-arrow .nav-btn i {
        font-size: 60px;
    }

    .offers .mob-arrow .nav-btn.next {
        right: -13px !important;
        top: 66%;
        transform: translateY(-50%);
        z-index: 9;
        font-size: 30px;
    }

    .offers .nav-btn.prev {
        right: auto;
        left: -25px !important;
        top: 66%;
        transform: translateY(-50%);
        z-index: 9;
        font-size: 30px;
    }

    .offers > div {
        flex-direction: column;
    }

    .offers > div > div:first-of-type {
        width: 419px;
        margin-bottom: 130px;
        margin-right: 0;
        text-align: center !important;
    }

    .nav-lists.mobile {
        margin: 10px auto 0;
    }

    .content-lists {
        margin-right: 0;
    }

    .archive .content .search-lists .content-lists li {
        width: 48% !important;
    }

    .archive .content .search-lists .content-lists li:nth-child(3n), .archive .content .search-lists .content-lists li:nth-child(4n) {
        margin-left: 4% !important;
    }

    .archive .content .search-lists .content-lists li:nth-child(2n) {
        margin-left: 0 !important;
    }

    .amt-offer-discount .offer .content-lists li {
        flex: 0 0 48%;
    }

    .cart-count {
        display: none;
    }

    .cart-page .carts > ul > li > div:last-of-type {
        display: none;
    }

    .mb-action-cart {
        display: block;
    }

    .course-video {
        width: 100%;
    }

    .embla__slide {
        position: relative;
        flex: 0 0 48.5%;
    }

    .why-amt-back {
        left: -30px;
    }

    .why-tpay__main {
        flex-wrap: wrap;
        justify-content: center;
    }

    .why-tpay__item {
        width: 46%;
        margin-bottom: 24px;
    }

    .why-tpay__main > div:nth-child(2n) {
        margin-left: 0;
    }

    .why-tpay__title-sec {
        padding: 3px 8px 13px;
    }

    .top-header {
        width: 100%;
        max-width: 100%;
    }

    .search h2 {
        font-size: 25px;
    }

    .home-elements_items_block_component__items {
        width: 100%;
        margin-left: 0;
    }

    .offers > div > div:first-of-type {
        width: 100%;
        text-align: right;
        display: flex;
        align-self: flex-start;
        flex-direction: column;
    }

    .r-b-i {
        right: -3px;
    }

    .offers > div > div:first-of-type svg {
        display: none;
    }

    .offers > div > div:last-of-type {
        width: 90%;
    }

    .offers .embla__slide {
        flex: 0 0 48%;
        margin-left: 4% !important;
    }

    .single .content-wrapper .body .course-curriculum > span {
        margin-top: 0;
        position: inherit;
        top: unset;
    }

    .title-section {
        margin-bottom: 10px;
    }

}
@media (max-width: 650px) {
    footer .footer-bottom {
        width: 96%;
    }

    .offer-back img {
        right: 133px;
        width: 197%;
    }

    .archive.teachers ul li {
        width: 23%;
        margin-left: 2%;
    }

    .single .course-menu.he {
        display: none !important;
    }

    .single .course-menu.fixed li {
        margin-left: 1%;
    }

    .single .course-menu.fixed {
        right: 2%;
        width: 96%;
    }

    .single .course-menu li a {
        font-size: 12px;
    }

    .single .course-menu li i {
        font-size: 16px;
        vertical-align: middle;
        margin-left: 5px;
    }

    .trailer .video-js.vjs-fluid {
        height: 340px !important;
    }

    .single .extra-info .header {
        width: 113%;
        margin-right: -6%;
    }

    .amt-article_textArea {
        display: flex;
        align-items: flex-start;
    }

    .trailer .popup-unlock {
        width: 95%;
    }

    .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3, .video-js.vjs-9-16, .video-js.vjs-1-1 {
        height: 420px !important;
    }

    .amt-article_textArea .arrows a {
        position: absolute;
        top: -5px;
    }

    .amt-article_textArea .arrows i {
        margin-top: 0;
    }

    .teachers .lists ul li {
        width: 100%;
    }

    .teachers .lists ul li:nth-child(1), .teachers .lists ul li:nth-child(3) {
        margin-left: 0;
    }

    .contact .content {
        width: 100%;
    }

    .contact .content ul li {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .contact .content ul li span:first-of-type {
        width: 130px;
        margin-bottom: 13px;
    }

    .contact .content ul {
        padding: 16px 10px 1px;
    }

    .contact .content ul li span:last-of-type {
        max-width: unset;
    }


    .translator {
        margin: 70px auto 0;
    }

    .translator .back p {
        max-width: 100%;
    }

    .translator {
        width: 100%;
    }

    .translator .back img {
        width: 173px;
        left: 100px;
    }

    .translator .back div:not(.character) {
        margin-right: 30px;
        width: 245px;
    }

    .translator .back div.character {
        position: absolute;
        left: 19px;
    }

    .translator .back {
        width: 86%;
        border-radius: 40px 0 0 40px;
        text-align: center;
        height: 200px;
    }

    .translator h2, .translator h3 {
        padding-right: 40px
    }

    .search {
        width: 100%;
    }

    footer .footer-bottom div ul {
        width: 130px;
        text-align: left;
    }

    .profile .content .top-boxes li, .profile .content .top-boxes li:nth-child(2) {
        width: 100%;
    }

    footer .footer-top {
        flex-wrap: wrap;
    }

    footer .footer-top .r-2 {
        display: none;
    }

    footer .footer-top > div {
        width: 100%;
        margin: 10px 0;
    }

    footer .footer-top .r-1 {
        width: 100%;
    }

    .nav-btn.prev {
        left: 11px;
    }

    .nav-btn.next {
        left: 45px;
    }

    .nav-btn {
        top: 10px;
    }

    .ticket-wrapper .ticket-head img {
        width: 70px;
        height: 70px;
    }

    .ticket-wrapper .ticket-head ul {
        padding-right: 13px;
    }

    .return-ticket {
        display: none;
    }

    .return-ticket.mob {
        display: block;
    }

    .profile .content {
        flex-wrap: wrap;
    }

    .profile .content .top-boxes li:nth-child(2) > div, .profile .content .top-boxes li:nth-child(3) > div {
        clip-path: url(#card-info-path-center);
    }

    .profile .content .content-wrapper {
        width: 100%;
        padding-right: 0;
    }

    .discount-box .cover {
        width: 90%;
    }

    .amt-offer-discount .discount .list-discounts > div {
        padding-top: 13px;
    }

    .single .head-info {
        flex-direction: column;
    }

    .single .head-info > div:last-of-type {
        width: 100%;
    }

    .single .head-info .trailer {
        width: 70%;
        height: auto;
        margin-left: 0;
        display: flex;
    }

    .single .head-info .trailer img {
        position: inherit;
    }

    .single .content-wrapper .head h2 {
        text-align: center;
    }
}
@media (max-width: 610px) {
    .offers .embla__slide {
        flex: 0 0 100%;
        margin-left: 0 !important;
    }

    .offers > div > div:last-of-type .embla {
        margin: 60px auto 50px;
    }

    .offer-back {
        height: 96%;
    }

    .offers > div > div:first-of-type {
        margin-bottom: 70px;
    }

    .offers > div > div:first-of-type h2 {
        font-size: 20px;
    }

    .offers > div > div:first-of-type p {
        font-size: 11px;
        margin-top: 14px;
    }

    .embla {
        padding: 0;
        width: 77%;
        position: relative;
        margin: 20px auto 50px;
    }

    .amt-article .embla {
        width: 100%;
        margin: 20px auto 50px;
    }

    .offers .nav-btn.prev {
        left: 3px !important;
        top: 60%;
    }

    .offers .mob-arrow .nav-btn.next {
        right: 12px !important;
        top: 60%;
    }

    .offers .content-lists li h3 {
        margin: 13px 0 5px;
    }
}
@media (max-width: 540px) {
    .login-wrapper {
        width: 90%;
    }

    .top-header .logo {
        width: 100px;
    }

    .top-header.o {
        padding: 15px 20px 10px;
        box-shadow: 1px 10px 30px #e0e0e0;
        background: #fff;
        margin-bottom: 35px;
        margin-top: -8px;
    }

    .back-article-mob {
        position: absolute;
        width: 800px;
        height: 800px;
        background: #f4f4f4;
        border-radius: 100%;
        top: -379px;
        z-index: -1;
        left: -300px;
        right: 264px;
        margin: 0 auto;
        display: block !important;
    }

    .single .course-menu.fixed.active {
        display: none !important;
    }

    .search.search-box {
        width: 90%;
        margin-top: 0;
        margin-bottom: 60px;
    }

    .search-ex {
        text-align: center;
        margin: 8px auto 0;
    }

    #root_raychat #animation-root {
        bottom: 81px !important;
        right: auto !important;
        left: 23px !important;
    }

    .single .extra-info .header {
        display: flex;
        justify-content: space-between;
        background: #f4f4f47a;
        width: 124%;
        margin-right: -9%;
        padding: 0 18px;
        margin-top: 10px;
    }

    .single .head-info .trailer {
        width: 80%;
    }

    .trailer .popup-unlock {
        top: 43%;
    }

    .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3, .video-js.vjs-9-16, .video-js.vjs-1-1 {
        height: 330px !important;
    }

    .amt-article .embla__slide {
        flex: 0 0 100%;
        width: 100%;
        margin-left: 0 !important;
    }

    .amt-article .home-elements_items_block_component__items {
        width: 80%;
    }

    .content-lists li .cover a {
        display: flex;
    }

    .back-teacher {
        width: 454px;
        right: -90%;
        top: -85px;
    }

    .row-t {
        margin: 0 auto 55px;
    }

    .row-t .top-lists > div {
        width: 100% !important;
        flex: 0 0 100%;
    }

    .row-t .top-lists > div:last-of-type {
        margin-right: 0;
    }

    .single .content-wrapper .body .inner > div:nth-child(3) {
        width: 100%;
    }

    .rate-wrapper {
        top: auto;
        padding-left: 0;
        bottom: -15px;
        left: 0;
    }

    .single .extra-info .extra .other-info ul li {
        width: 50%;
    }

    .single-section.description .teacher {
        flex-direction: column;
    }

    body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
        top: 0 !important;
        bottom: auto !important;
        height: auto;
    }

    .assets-box-4 {
        width: 120%;
        right: -10%;
        border-radius: 0;
        top: 35px;
    }

    .single .content-wrapper .head h3 {
        width: 100%;
    }

    .assets-box-5 {
        width: 104%;
        height: 105%;
        top: 19px;
    }

    .translator {
        margin: 25px auto 50px;
    }

    .footer-line {
        width: 120%;
    }

    .login-wrapper .tabs {
        justify-content: space-between;
        padding: 0 24px;
    }

    .login-wrapper .tabs li {
        width: 49%;
    }

    .top-header .back {
        border-radius: 87px;
        width: 100%;
    }

    .login-wrapper .fields .submit {
        width: 100% !important;
        margin-bottom: 0;
        margin: 10px 0;
    }

    .login-wrapper .auth-logins a {
        width: 85% !important;
    }

    .login-wrapper .fields label, .bottom-btn-login {
        width: 90%;
    }

    .register-block-wrapper .auth-logins a:first-of-type {
        margin-bottom: 20px;
    }

    .login-wrapper .auth-logins {
        flex-direction: column;
    }

    .login-wrapper .auth-logins a {
        width: 100%;
    }

    .bottom-btn-login {
        flex-direction: column;
    }

    .search h2 {
        font-size: 20px;
        font-weight: 900;
    }

    .translator .relative.character {
        display: none;
    }

    .translator .back div:not(.character) {
        margin-right: 30px;
        width: 85%;
    }

    .translator .back p {
        margin-bottom: 20px;
    }

    .translator .back img:not(.r):not(.l) {
        display: none;
    }

    .article-wrapper .inner > div:first-of-type .cover {
        width: 90%;
    }

    .cart-page .carts > ul {
        padding: 20px 0 20px 10px;
    }

    .cart-page .carts > ul > li .cover {
        width: 91px;
        height: 62px;
    }

    .cart-page .carts > ul > li > div > div {
        margin-right: 22px;
    }

    .mb-action-cart {
        width: 100% !important;
    }

    .single .author > div ul li i {
        font-size: 18px;
    }

    .advertise .routes li img {
        width: 135px;
    }

    .advertise .routes li p {
        width: calc(100% - 123px);
    }

    .offers {
        margin: 35px auto 0;
    }

    .advertise {
        margin: 0 auto;
    }

    .advertise > div:first-of-type {
        margin-bottom: 40px;
    }

    .advertise > div:last-of-type {
        margin-bottom: 60px;
    }

    .amt-offer-discount .discount .list-discounts > div {
        width: 100%;
    }

    .amt-offer-discount .discount .list-discounts {
        flex-wrap: wrap;
    }

    .amt-offer-discount .offer .content-lists li {
        flex: 0 0 98%;
    }

    .content-lists li {
        margin-left: 0 !important;
    }

    .back-offer {
        left: 0;
        right: 0;
        width: 102%;
    }

    .home-elements_items_block_component__items > div {
        width: 98%;
    }

    .single .content-wrapper .body .inner > div:nth-child(2) {
        display: none;
    }

    .bottom-sidebar {
        display: flex;
    }

    .single .content-wrapper .head .left-head {
        display: none;
    }

    .single .content-wrapper .body .inner > div:nth-child(2) {
        width: 100%;
    }

    .top-header {
        max-width: 100%;
        width: 100%;
    }

    .embla__slide {
        flex: 0 0 99.5%;
    }

    .why-tpay__item {
        width: 44%;
    }


    .nav-lists.mobile, .search {
        width: 100%;
    }

    .home-back {
        right: -460px;
    }

    .course-counter, .single .content-wrapper .body .course-curriculum-lists > li ul li > div:not(.free):not(.buy) > span, .send-comment-box .avatar-wrap {
        display: none !important;
    }

    .single .content-wrapper .body .course-curriculum-lists > li ul li {
        margin: 0 5px;
    }

    .comment-section .write-box .input-outer {
        margin-right: 0;
    }

    .assets-box-5 {
        width: 99%;
        height: 104%;
        top: 28px;
    }

    .archive .content .search-lists .content-lists li {
        width: 100% !important;
    }

    .archive .content .search-lists .content-lists li:nth-child(3n), .archive .content .search-lists .content-lists li:nth-child(4n) {
        margin-left: 0 !important;
    }

    .section-title-wrap {
        margin-bottom: 25px !important;
        margin-right: 0;
    }

    .r-head {
        float: none;
    }

    .offers .back {
        width: 100%;
    }

    .content-lists {
        margin-bottom: 0;
    }

    .top-header .login {
        margin: 0;
    }

    .dashboard-page form label {
        width: 100%;
    }

    .popup-unlock {
        width: 95%;
    }

    .popup-unlock .unlock__body {
        padding: 5px 15px;
    }

    .price-wrapper .unlock__body ul li {
        padding: 0 6px;
    }

    .wallet-wrapper {
        width: 330px;
    }

    .single .content-wrapper .head {
        padding: 0;
        margin-top: 5px
    }

    .single .content-wrapper > .head {
        box-shadow: none;
    }

    .single .content-wrapper .body {
        margin-top: 0px;
    }

    .single-section .title-section {
        background: #f4f4f47a;
        padding: 14px;
        width: 114%;
        margin-right: -6%;
    }

    .offer-back img {
        right: 100px;
        width: 274%;
    }

}
@media (max-width: 450px) {
    .back-article-mob {
        right: 225px;
    }

    .offers .embla {
        width: 85%;
    }

    .offers .content-lists li h3 {
        margin: 15px 0 5px;
    }

    .offers .mob-arrow .nav-btn.next {
        right: -5px !important;
        top: 60%;
    }

    .offers .nav-btn.prev {
        left: -12px !important;
        top: 60%;
    }

    .archive.teachers ul li {
        width: 31%;
        margin-left: 2%;
    }

    .title-section h3 .double, .title-section h3 .subtitle {
        margin: 5px 0;
    }

    .head-course-info li {
        width: 50%;
    }

    .search form label {
        width: 53%;
    }

    .single .head-info .trailer {
        width: 95%;
    }

    .search form label input {
        margin-right: 5px;
    }

    .archive .content .head .search-top-box {
        width: 65%;
    }

    .show-filters {
        padding: 6px 9px;
    }

    .show-filters span {
        font-size: 11px;
    }

    .search form {
        width: 100%;
    }

    .search h3 {
        font-size: 12px;
    }

    .top-header .back {
        border-radius: 0;
        background: linear-gradient(0deg, #f3f3f3 0%, #e3e3e300 12%);
    }

    .search h2.mobile {
        background: #c1c1c1;
        color: #fff;
        width: 112%;
        right: -6%;
        padding: 8px 10px;
    }

    .amt-article .home-elements_items_block_component__items {
        width: 80%;
    }

    .head-course-info li span {
        font-size: 12px;
    }

    .head-course-info li img {
        width: 33px;
    }

    .head-course-info li {
        margin-bottom: 8px;
    }

    .translator .back p {
        padding: 0 10px;
    }

    .head-course-info {
        margin-bottom: 15px;
    }

    .top-header {
        padding: 5px 15px 0;
    }

    .single .post-thumbnail {
        margin: 0;
    }

    .article-wrapper .inner .article-box > div > div:last-of-type .bt {
        justify-content: space-between;
    }

    .article-wrapper .inner .article-box > div > div:last-of-type {
        margin-bottom: 20px;
    }

    .article-wrapper .inner .article-box .single-section.description div {
        margin-bottom: 0;
    }

    .why-tpay__item {
        width: 100%;
        margin-left: 0;
    }

    .amt-article_textArea header > div:first-of-type {
        display: none;
    }

    .amt-article_textArea > div:first-of-type {
        margin-right: 2px;
    }

    .search h2 {
        font-size: 20px;
    }

    .top-header .back {
        height: 605px;
    }

    .home-elements_style_item_card_component__details {
        width: calc(100% - 170px);
    }

    .home-elements_style_item_card_component__preview {
        width: 160px;
    }

    .amt-article-wrapper .home-elements_style_item_card_component__root {
        display: flex;
        flex-direction: column;
    }

    .amt-article-wrapper .home-elements_style_item_card_component__details {
        width: 95%;
        margin: 10px auto;
    }

    .archive .content .head {
        flex-wrap: wrap;
    }

    .archive .content .head .search-top-box input {
        width: 93%;
    }

}
