.dark {
    background: #232428;
}
.dark .offers .back {
    background: linear-gradient(266deg, #333232 0%, #e3e3e300 55%);
}
.dark .content-lists li {
    box-shadow: 1px 1px 40px #00000012;
    background: #383838;
    color: #fff;
}
.dark .content-lists li h3 a, .dark .swiper-slide h3 a {
    color: #fff;
}
.dark .lasts .content-lists li .ft {
    border-top: 1px solid #eeeeee21;
    padding-top: 10px;
}
.dark .content-lists li .ft > div span.price {
    color: #e6e6e6;
}
.dark .search h2 {
    color: #ebebeb;
}
.dark .bottom-header-menu {
    box-shadow: 0 54px 27px #2d2d2d0f;
    background: #393939;
    margin: -2px auto 0;
}
.dark .nav-lists li {
    color: #acacac;
}
.dark .bottom-header-menu li a {
    color: #a1a1a1;
    font-size: 13px;
}
.dark .search-header {
    background: transparent;
    border: 1px solid #444444;
}
.dark .buy-icon i {
    color: #b3b3b3;
}
.dark .r-b-i {
    z-index: 1;
    filter: grayscale(1);
    opacity: .3;
}
.dark .content-lists li .ft .time span {
    color: #cecece;
}
.dark .stepsWrapper .title-tour .title-block {
    color: #dfdfdf;
}
.dark .search-header input {
    border: none;
    background: transparent;
}
.dark .content-lists li > a {
    color: #cbcbcb;
}
.dark .stepsWrapper .footer-tour .all-tour {
    color: #d2d2d2;
}
.dark .search form select {
    color: #adadad;
}
.dark .search form label input {
    background: transparent;
}
.dark .search-ex a,.dark .search-ex {
    color: #fff;
}
.dark .search form label i {
    color: #999;
}
.dark .search form {
    background: #232428;
    box-shadow: inset 0 0.25em 0 0 #292929;
    border: 1px solid #464646;
}
.dark .stepsWrapper {
    border: 1px solid #3e3e3e;
}
.dark .stepsWrapper .content-tour .text-tour {
    color: #7c7c7c;
}
.dark .offers > div > div:first-of-type h2 {
    color: #a4a4a4;
}
.dark .content-lists li p, .dark .swiper-slide p {
    color: #cbcbcb;
}
.dark .lasts svg path {
    color: #fff;
}
.dark .translator .back {
    box-shadow: 1px 1px 70px #262626;
    background: #2f2f2f;
}
.dark .translator h3, .dark .translator h2 {
    color: #aaa;
}
.dark .section-title-wrap .section-title {
    color: #d2d2d2;
}
.dark .top-header .login {
    background: #2a79ff;
    box-shadow: 2px 3px 17px #232323;
}
.dark span.effect::before {
    background: #0668e1;
}
.dark .top-header .login div {
    color: #e4e4e4;
}
.dark .amt-article .home-elements_items_block_component__title {
    color: #eee;
}
.dark .amt-article .home-elements_items_block_component__subtitle {
    color: #e9e9e9;
}
.dark .advertise .routes li:nth-child(1), .dark .advertise .routes li:nth-child(2), .dark .advertise .routes li:nth-child(3) {
    box-shadow: none;
}
.dark .shared-cta_button_component__root.shared-cta_button_component__large {
    border: 1px solid #686868;
    color: #b9b9b9;
}
.dark .translator .back p {
    color: #bcbcbc;
}
.dark .translator .back a {
    box-shadow: 1px 2px 3px #2e2e2e;
}
.dark .home-elements_style_item_card_component__name {
    color: #fff;
}
.dark .free .home-elements_style_item_card_component__details p {
    color: #bbb;
}
.dark .amt-article_textArea header > div > span:first-of-type {
    background: #626262;
}
.dark .shared-item_preview_component__image {
    filter: grayscale(0);
    opacity: 1;
    height: 100%;
    border-radius: 0 14px 14px 0;
}
.dark .amt-article .embla__slide {
    background: #323232;
    box-shadow: unset;
}
.dark .home-elements_style_item_card_component__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 11px;
}
.dark .content-lists li .ft .price span {
    color: #cecece;
}
.dark .content-lists li .ft > div span.price del {
    color: #727272;
}
.dark .assets-box-4 {
    background-color: #323232;
    box-shadow: 1px 1px 73px #19191970;
    z-index: 2;
}
.dark .assets-box-5 {
    background-color: #4c4c4c;
    z-index: 1;
}
.dark .single .content-wrapper .head h3 {
    z-index: 3;
    position: relative;
    color: #cbcbcb;
}
.dark .single .content-wrapper .head h2 {
    z-index: 3;
    position: relative;
    color: #e3e3e3;
}
.dark .breadcrumb {
    z-index: 3;
    position: relative;
}
.dark .single .content-wrapper .breadcrumb a {
    color: #b1b1b1;
}
.dark .single .content-wrapper .head .rate .star span {
    color: #cccccc;
}
.dark .single .content-wrapper .body .course-curriculum-lists {
    background: #303030;
    box-shadow: 1px 1px 30px #282828;
    color: #fff;
}
.dark .single .content-wrapper .body .course-curriculum-lists > li ul li > div:not(.free):not(.buy) {
    background: #454545;
}
.dark .single .content-wrapper .body .course-curriculum-lists > li .head div h4 {
    color: #c4c4c4;
}
.dark .title-section h3 {
    color: #eee;
}
.dark .single .content-wrapper .body .description p {
    color: #a7a7a7;
}
.dark .single .content-wrapper .body .course-curriculum-lists > li ul li > .buy {
    background: #505050;
    box-shadow: 1px 2px 3px #313131;
}
.dark .title-section i {
    color: #eee;
}
.dark .single .content-wrapper .body .course-curriculum-lists > li ul li div:not(.buy) span:not(.free) {
    font-size: 12px;
    display: inline-block;
    color: #c3c3c3;
}
.dark .single .sidebar {
    background: #2f2f2f;
    box-shadow: 1px 1px 30px #1d1d1d2b;
    color: #fff;
}
.dark .single .sidebar .other-info ul li div span {
    color: #b5b5b5;
}
.dark .single .buy-btn a:last-of-type {
    color: #cccccc;
    border: 1px solid #646464;
}
.dark .single .sidebar .other-info ul li a {
    color: rgb(219 219 219) !important;
}
.dark .comment-form form div {
    border: 1px solid #474747;
}
.dark .comment-section .comment-streams .body-wrap .body-header .commenter {
    color: rgb(213 213 213);
}
.dark .comment-section .comment-streams .body-wrap .comment-txt p {
    border-bottom: 1px solid #393939;
}
.dark .single .content-wrapper .body .inner > div:nth-child(2) ul li {
    color: #aaa;
}
